import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestForWeChatAccountSetup } from '../../../modules/wechat/api-we-chat'
import { RootState } from '../../store'
import { WeChatAuthData, WeChatSetupState } from './weChat-setup.types'

const initialState: WeChatSetupState = {
  weChatSetupDetails: {},
  status: 'idle',
}

export const requestWeChatSetup = createAsyncThunk(
  'weChatAuth/setupAccount',
  async (authData: WeChatAuthData, { rejectWithValue }) => {
    const response = await requestForWeChatAccountSetup(authData)

    if (response.data === undefined && response.message !== null) {
      return rejectWithValue('Error')
    }
    return response.data
  },
)

export const weChatSetupSlice = createSlice({
  name: 'weChatAuth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestWeChatSetup.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(requestWeChatSetup.fulfilled, (state, action) => {
        state.status = 'success'
        state.weChatSetupDetails = action.payload
      })
      .addCase(requestWeChatSetup.rejected, (state) => {
        state.status = 'failed'
        state.weChatSetupDetails = {}
      })
  },
})

export const weChatSetupDetails = (state: RootState): object => state.weChatSetup.weChatSetupDetails

export const weChatSetupStatus = (state: RootState): string => state.weChatSetup.status

export default weChatSetupSlice.reducer
