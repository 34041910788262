import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestDeleteContacts } from '../../../modules/contacts/contacts-api'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ContactDeleteState, ResponseError } from './contacts.types'

const initialState: ContactDeleteState = {
  status: 'idle',
  error: null,
}

export const deleteContact = createAsyncThunk(
  'contact/delete',
  async (data: any, { rejectWithValue }) => {
    const response: Response = await requestDeleteContacts(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const deleteContactSlice = createSlice({
  name: 'deleteContacts',
  initialState,
  reducers: {
    resetDeleteContacts: (state) => {
      state.status = 'idle'
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteContact.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteContact.fulfilled, (state) => {
        state.status = 'success'
      })
      .addCase(deleteContact.rejected, (state, action: any) => {
        const errorMessage: ErrorMessageState = {
          message: 'Unable to delete selected contacts',
          errorType: 'Request Failed',
        }

        state.status = 'failed'
        state.error = errorMessage
      })
  },
})
export const { resetDeleteContacts } = deleteContactSlice.actions
export const deleteContactApiStatus = (state: RootState): string =>
  state.deleteContact.status as string
export const deleteContactErrors = (state: RootState): any => state.deleteContact.error

export default deleteContactSlice.reducer
