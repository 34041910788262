import { MenuData } from "../../redux/slice/theme-settings/theme-settings.types";

export const MenuList: MenuData = [
  {
    key: '1',
    label: 'Publish',
    hidden: false,
    icon: 'publish',
    children: [
      {
        key: '11',
        label: 'Web or Mobile Page',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '12',
        label: 'We Chat',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '13',
        label: 'Templates',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '14',
        label: 'Assets',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
    ],
  },
  {
    key: '2',
    label: 'Engage',
    hidden: false,
    icon: 'social',
    children: [
      {
        key: '21',
        label: 'Web or Mobile Page',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '22',
        label: 'We Chat',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '23',
        label: 'Templates',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '24',
        label: 'Assets',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
    ],
  },
  {
    key: '3',
    label: 'Reports',
    hidden: false,
    icon: 'report',
    children: [
      {
        key: '31',
        label: 'Web or Mobile Page',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '32',
        label: 'We Chat',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '33',
        label: 'Templates',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
      {
        key: '34',
        label: 'Assets',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum',
      },
    ],
  },
]
