import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestCountriesList } from '../../../api/common'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { CountryListState, CountryObjectState } from './countries.type'

const initialState: CountryListState = {
  status: 'idle',
  countryList: [],
  error: null,
}

export const getCountryList = createAsyncThunk(
  'countries/getAll',
  async (data: any, { rejectWithValue }) => {
    const response: Response = await requestCountriesList(data, false)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getCountryListSlice = createSlice({
  name: 'countryList',
  initialState,
  reducers: {
    clearCountryList: (state) => {
      state.status = 'idle'
      state.error = null
      state.countryList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.status = 'success'
        state.countryList = action.payload as CountryObjectState[]
      })
      .addCase(getCountryList.rejected, (state, action: any) => {
        const errorMessage: ErrorMessageState = {
          message: 'Locale  List Error',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        state.status = 'failed'
        state.error = errorMessage
        state.countryList = initialState.countryList
      })
  },
})
export const { clearCountryList } = getCountryListSlice.actions
export const getCountryListStatus = (state: RootState): string => state.country.status
export const getCountryListCollection = (state: RootState): CountryObjectState[] =>
  state.country.countryList as CountryObjectState[]
export const getCountryListError = (state: RootState): ErrorMessageState =>
  state.country.error as ErrorMessageState

export default getCountryListSlice.reducer
