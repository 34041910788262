import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { updateContactDetails } from '../../../modules/contacts/contacts-api'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError, UpdateContactState } from './contacts.types'

const initialState: UpdateContactState = {
  status: 'idle',
  updatedContact: {},
  error: null,
}

export const updateExistingContact = createAsyncThunk(
  'contact/update',
  async (data: any, { rejectWithValue }) => {
    const response: Response = await updateContactDetails(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const updateContactSlice = createSlice({
  name: 'updateContact',
  initialState,
  reducers: {
    clearUpdateContactState: (state) => {
      state.status = 'idle'
      state.error = null
      state.updatedContact = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateExistingContact.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateExistingContact.fulfilled, (state, action) => {
        state.status = 'success'
        state.updatedContact = action.payload
      })
      .addCase(updateExistingContact.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage

        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        if (errorFromPayload.email !== '') {
          errorMessage.message = errorFromPayload.email
        }
        if (
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== undefined &&
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== ''
        ) {
          errorMessage.message = errorFromPayload['phoneNumbers[0].phoneNumber']
        }

        state.status = 'failed'
        state.error = errorMessage
        state.updatedContact = initialState.updatedContact
      })
  },
})
export const { clearUpdateContactState } = updateContactSlice.actions
export const updateContactApiStatus = (state: RootState): string => state.updateContact.status
export const updateContactApiErrors = (state: RootState): ErrorMessageState =>
  state.updateContact.error as ErrorMessageState

export default updateContactSlice.reducer
