import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { SendTemplateMessageProps } from '../../../api/conversation'

const initialState: {
  conversationSettings: SendTemplateMessageProps | null
  type: 'new' | 'edit' | 'start'
} = {
  conversationSettings: null,
  type: 'new',
}
export const conversationSendSettingsSlice = createSlice({
  name: 'conversations/settings',
  initialState,
  reducers: {
    saveConversationSettings: (state, action) => {
      state.conversationSettings = action.payload
    },
    clearConversationSettings: (state) => {
      state.conversationSettings = null
      state.type = 'new'
    },
    startEditConversation: (state, action) => {
      state.type = 'edit'
      state.conversationSettings = action.payload
    },
    startBroadcastMessage: (state, action) => {
      state.type = 'start'
      state.conversationSettings = action.payload
    },
  },
})
export const {
  clearConversationSettings,
  saveConversationSettings,
  startEditConversation,
  startBroadcastMessage,
} = conversationSendSettingsSlice.actions
export const conversationConfigs = (state: RootState): SendTemplateMessageProps | null =>
  state.conversationSettings.conversationSettings
export const conversationConfigurationStatus = (
  state: RootState,
): 'new' | 'edit' | 'start' | null => state.conversationSettings.type

export default conversationSendSettingsSlice.reducer
