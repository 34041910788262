import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CustomSortDataMessageList, requestMessageList } from '../../../api/messages'
import { ErrorMessageState, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { MessageListStateProps } from './message.types'

const initialState: MessageListStateProps = {
  status: 'idle',
  messageList: {},
}

export const getMessageList = createAsyncThunk(
  'message/all',
  async (data: CustomSortDataMessageList, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestMessageList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getMessageListSlice = createSlice({
  name: 'messageList',
  initialState,
  reducers: {
    clearMessageList: (state) => {
      state.status = 'idle'
      state.messageList = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessageList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getMessageList.fulfilled, (state, action) => {
        state.status = 'success'
        state.messageList = action.payload as object
      })
      .addCase(getMessageList.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        if (errorFromPayload.contacts !== undefined) {
          errorMessage.errorType = errorFromPayload.contacts
        }

        state.status = 'failed'
        state.messageList = {}
      })
  },
})
export const { clearMessageList } = getMessageListSlice.actions
export const messageListApi = (state: RootState): string => state.messageList.status
export const messageListResult = (state: RootState): any => state.messageList.messageList

export default getMessageListSlice.reducer
