import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { SavedEmailRelaySettings, requestEmailRelaySettings } from '../../../api/common'
import { ApiCallStatus } from '../../../types/api-call-status.types'

interface InitialState extends ApiCallStatus {
  settings: SavedEmailRelaySettings
}
const initialState: InitialState = {
  status: 'idle',
  settings: {
    emailRelaySettings: [],
  },
}

export const getEmailRelaySettings = createAsyncThunk(
  'emailRelay/getSettings',
  async (clientAccountId: string, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestEmailRelaySettings(clientAccountId)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getEmailRelaySlice = createSlice({
  name: 'replyViaEmail',
  initialState,
  reducers: {
    clearEmailRelaySettingsSliceState: (state) => {
      state.status = 'idle'
      state.settings = {
        emailRelaySettings: [],
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmailRelaySettings.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getEmailRelaySettings.fulfilled, (state, action) => {
        state.status = 'success'
        state.settings = action.payload as SavedEmailRelaySettings
      })
      .addCase(getEmailRelaySettings.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})

export const { clearEmailRelaySettingsSliceState } = getEmailRelaySlice.actions
export const getEmailRelaySettingsApiStatus = (state: RootState): string =>
  state.emailRelaySettings.status
export const emailRelaySettingsList = (state: RootState): SavedEmailRelaySettings =>
  state.emailRelaySettings.settings as unknown as SavedEmailRelaySettings

export default getEmailRelaySlice.reducer
