import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestUnreadMessageCount } from '../../../api/messages'
import { Response, ResponseError, SortState } from '../../../types/common.types'
import { RootState } from '../../store'
import { UnreadMessageCountObject, UnreadMessagesStateTypes } from './message.types'

const initialState: UnreadMessagesStateTypes = {
  status: 'idle',
  count: {
    channelMessageCount: [],
    unreadMessageCountTotal: 0,
  },
}

export const getUnreadMessageCount = createAsyncThunk(
  'message/unread',
  async (data: SortState, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestUnreadMessageCount(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getUnreadMessageCountSlice = createSlice({
  name: 'unreadMessageCount',
  initialState,
  reducers: {
    clearUnreadMessageCount: (state) => {
      state = { status: 'idle', count: 0 }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnreadMessageCount.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getUnreadMessageCount.fulfilled, (state, action) => {
        state.status = 'success'
        state.count = action.payload as UnreadMessageCountObject
      })
      .addCase(getUnreadMessageCount.rejected, (state, action: any) => {
        state.status = 'failed'
        state.count = 0
      })
  },
})

export const { clearUnreadMessageCount } = getUnreadMessageCountSlice.actions
export const unreadMessageStatus = (state: RootState): string => state.unreadMessage.status
export const unreadMessageCount = (state: RootState): UnreadMessageCountObject =>
  state.unreadMessage.count as UnreadMessageCountObject

export default getUnreadMessageCountSlice.reducer
