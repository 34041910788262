import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestSourceList } from '../../../api/common'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { SourceListState, SourceObjectState } from './sources.type'

const initialState: SourceListState = {
  status: 'idle',
  sourceList: [],
  error: null,
}

export const getSourceList = createAsyncThunk(
  'source/getAll',
  async (data: any, { rejectWithValue }) => {
    const response: Response = await requestSourceList(data, false)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getSourceListSlice = createSlice({
  name: 'sourceList',
  initialState,
  reducers: {
    clearSourceList: (state) => {
      state.status = 'idle'
      state.error = null
      state.sourceList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSourceList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getSourceList.fulfilled, (state, action) => {
        state.status = 'success'
        state.sourceList = action.payload as SourceObjectState[]
      })
      .addCase(getSourceList.rejected, (state, action: any) => {
        const errorMessage: ErrorMessageState = {
          message: 'Source List Error',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        state.status = 'failed'
        state.error = errorMessage
        state.sourceList = initialState.sourceList
      })
  },
})
export const { clearSourceList } = getSourceListSlice.actions
export const getSourceListStatus = (state: RootState): string => state.source.status
export const getSourceListCollection = (state: RootState): SourceObjectState[] =>
  state.source.sourceList as SourceObjectState[]
export const getSourceListError = (state: RootState): ErrorMessageState =>
  state.source.error as ErrorMessageState

export default getSourceListSlice.reducer
