import { GeographicalInfo } from '../modules/contact-details-page/contact-details.types'

export const generateAvatarFromName = (name: string): string => {
  if (name !== undefined && name !== null && name !== '') {
    const nameParts = name.split(' ')
    let selectedCharactersToString = ''
    selectedCharactersToString += Array.from(nameParts[0].normalize())[0]

    /**
     * If name has more than 1 parts,
     * append the first character of the very last part of the name
     **/
    if (nameParts.length > 1) {
      selectedCharactersToString += Array.from(nameParts[nameParts.length - 1].normalize())[0]
    }

    return selectedCharactersToString ?? ''
  }

  return ''
}

export const generateFullAddress = (data: GeographicalInfo): string => {
  const address = []

  if (data === null || data === undefined) {
    return ''
  }

  if (data.houseNo !== null) {
    address.push(data.houseNo)
  }

  if (data.street !== null) {
    address.push(data.houseNo)
  }

  if (data.city !== null) {
    address.push(data.city)
  }

  if (data.region !== null) {
    address.push(data.region)
  }

  if (data.state !== null) {
    address.push(data.state)
  }

  if (data.postalCode !== null) {
    address.push(data.postalCode)
  }

  if (data.country?.countryName !== null) {
    address.push(data.country.countryName)
  }

  if (address.length > 0) {
    return address.join(',')
  }

  return ''
}

export const getCurrentAccountName = (): string => {
  const currentAccountName = localStorage.getItem('accountName')

  return currentAccountName !== null ? ' - ' + currentAccountName : ''
}

export const updatePageTitle = (module: string, page: string | null): void => {
  document.title = `${module}${
    page !== null ? ' - ' + page : ''
  }${getCurrentAccountName()} | Sinorbis`
}

/**
 * Check if the current user has access against the policies
 *
 * @param policy: Policy to check
 * @returns boolean: Whether the current user has access or not
 */
export const hasPolicyAccess = (policy: string[]): boolean => {
  // Load the policies of the current user which received from the login token
  const authorities = localStorage.getItem('authorities') ?? '[]'

  return policy.some((item) => authorities.includes(item))
}
