import { Breadcrumb } from 'antd'
import React from 'react'

import styles from './LayoutBreadcrumb.module.scss'

interface LayoutBreadcrumbProps {
  breadcrumb: string[]
}
const LayoutBreadcrumb: React.FC<LayoutBreadcrumbProps> = ({ breadcrumb }) => {
  return (
    <Breadcrumb className={styles.breadcrumbWrapper}>
      {breadcrumb.length > 0 ? (
        breadcrumb.map((pathName, index) => (
          <Breadcrumb.Item key={index}>{pathName}</Breadcrumb.Item>
        ))
      ) : (
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      )}
    </Breadcrumb>
  )
}

export default LayoutBreadcrumb
