import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MergeContactsApiRequestTypes } from '../../../modules/contacts/contact.types'
import { requestContactsToMerge } from '../../../modules/contacts/contacts-api'
import { ApiStatus, Response, ResponseError } from '../../../types/common.types'
import { messageEmitter } from '../../../utils/toastify-message-emmiter'
import { RootState } from '../../store'

const initialState: ApiStatus = {
  status: 'idle',
}

export const mergeContact = createAsyncThunk(
  'contacts/merge',
  async (contactDetails: MergeContactsApiRequestTypes, { rejectWithValue }) => {
    const { data, message, response }: Response = await requestContactsToMerge(contactDetails)

    if (data === undefined && message !== null) {
      const errors: ResponseError = {
        errorMessage: response?.data?.errors ?? '',
        apiStatusMessage: response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return data
  },
)

export const mergeContactsSlice = createSlice({
  name: 'mergeContacts',
  initialState,
  reducers: {
    clearMergeContactsState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(mergeContact.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(mergeContact.fulfilled, (state, action) => {
        messageEmitter('Selected contacts merged', 'success')
        state.status = 'success'
      })
      .addCase(mergeContact.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearMergeContactsState } = mergeContactsSlice.actions
export const mergeContactApiStatus = (state: RootState): string => state.mergeContacts.status

export default mergeContactsSlice.reducer
