import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { requestClientsAccountsList } from '../../../api/common'
import { ClientAccountsType, SinoClientAccountStateTypes } from './sino-clients-accounts.types'

const initialState: SinoClientAccountStateTypes = {
  status: 'idle',
  clientAccounts: [],
}

export const getClientAccountsList = createAsyncThunk(
  'sinoClientAccounts/all',
  async (clientAccountId: string, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestClientsAccountsList(clientAccountId)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const sinoClientsAccountsSlice = createSlice({
  name: 'clientAccountList',
  initialState,
  reducers: {
    clearSinoClientAccountList: (state) => {
      state = { status: 'idle', clientAccounts: [] }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientAccountsList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getClientAccountsList.fulfilled, (state, action) => {
        state.status = 'success'
        state.clientAccounts = action.payload as ClientAccountsType[]
      })
      .addCase(getClientAccountsList.rejected, (state, action: any) => {
        state.status = 'failed'
        state.clientAccounts = []
      })
  },
})

export const { clearSinoClientAccountList } = sinoClientsAccountsSlice.actions
export const sinoClientsAccountApiStatus = (state: RootState): string => state.sinoClients.status
export const sinoClientsAccountsList = (state: RootState): ClientAccountsType[] | [] =>
  state.sinoClients.clientAccounts as ClientAccountsType[]

export default sinoClientsAccountsSlice.reducer
