import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../store'

import { TemplateListPropsType } from '../../../components/new-conversation-wizard/CustomizeTemplate.types'

const initialState: { template: TemplateListPropsType | null } = {
  template: null,
}
export const whatsappTemplateSelectorSlice = createSlice({
  name: 'whatsappTemplateSelect',
  initialState,
  reducers: {
    saveSelectedWhatsappTemplate: (state, action) => {
      state.template = action.payload
    },
    clearSelectedWhatsappTemplate: (state) => {
      state.template = null
    },
  },
})
export const { clearSelectedWhatsappTemplate, saveSelectedWhatsappTemplate } =
  whatsappTemplateSelectorSlice.actions
export const selectedWhatsappTemplateDetails = (state: RootState): TemplateListPropsType | null =>
  state.whatsappSelectedTemplate.template

export default whatsappTemplateSelectorSlice.reducer
