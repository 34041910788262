import React from 'react'
import { ClassNames } from '../../types/common.types'

const DownIcon: React.FC<ClassNames> = ({ className }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='1em' viewBox='0 0 150 90' className={className}>
      <path
        id='down'
        d='M130.379,131.334a5.206,5.206,0,0,0,7.21,0l64.9-63.075a4.858,4.858,0,0,0,0-7.007l-7.2-7.017a5.205,5.205,0,0,0-7.21,0L133.989,106.8,79.9,54.235a5.205,5.205,0,0,0-7.21,0l-7.21,7.007a4.858,4.858,0,0,0,0,7.007Z'
        transform='translate(-58.984 -47.784)'
        fill='currentColor'
        stroke='#61c6bf'
      />
    </svg>
  )
}

export default DownIcon
