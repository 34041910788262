import React, { Suspense, useEffect } from 'react'
import { lazy } from '@loadable/component'
import { Outlet, Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { appRouteList } from './app-route-list'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { setUserDetails, userCheck, userId } from '../redux/slice/user/user.slice'
import Layout from '../components/layout/Layout'
import Loader from '../components/loader/Loader'
import RequestCode from '../modules/login/RequestAuthorization'
import Login from '../modules/login/Login'
import PubNubWrapper from '../components/pubnub-wrapper/PubNubWrapper'
import PageNotFound from '../components/page-not-found/PageNotFound'
import { hasPolicyAccess } from '../utils/common-function'
import * as Authorities from '../utils/authority-constants'
import { profileApiUrl } from '../api/api-variables'
import { userAccountsResult } from '../redux/slice/user/user-accounts.slice'
import RedirectUrl from './RedirectURL'

const Dashboard = lazy(async () => await import('./../modules/dashboard/Dashboard'))

const AppRoutes: React.FC = () => {
  const dispatch = useAppDispatch()

  const accountId = useAppSelector(userId)
  const userCheckAttempts = useAppSelector(userCheck)
  const userAccounts = useAppSelector(userAccountsResult)
  const urlParams = new URLSearchParams(window.location.search)
  const directedAccountId = urlParams.get('accountId')

  useEffect(() => {
    if (accountId === '' || (accountId === null && userCheckAttempts !== null)) {
      // There is a slightly delay  when store User id. so we have to add set timeout function to overcome not retrieving user id issue
      setTimeout(() => {
        dispatch(setUserDetails())
      }, 3000)
    }

    if (userCheckAttempts === 5 && !window.location.href.includes('/app/messages?accountId')) {
      window.location.replace(`/`)
    }
  }, [userCheckAttempts, accountId])

  if (window.location.href.includes('/app/messages?accountId')) {
    if (
      userCheckAttempts === 5 ||
      (accountId !== '' &&
        !(
          hasPolicyAccess(Authorities.SBF_CONVERSATION_ACCESS_ALL) &&
          hasPolicyAccess(Authorities.SCP_CONVERSATION_ACCESS_ALL)
        ))
    ) {
      // Clear local storage
      localStorage.clear()
      // Navigate to logout link

      window.location.href = `${
        profileApiUrl === '' ? 'http://localhost:8084' : profileApiUrl
      }/logout`

      return <Loader />
    } else {
      if (
        userAccounts !== undefined &&
        userAccounts !== null &&
        userAccounts.length > 0 &&
        accountId !== '' &&
        accountId !== null &&
        directedAccountId !== undefined &&
        directedAccountId !== null &&
        directedAccountId !== ''
      ) {
        const filteredAccount = userAccounts.filter((account) => {
          return account.id === directedAccountId
        })

        if (filteredAccount.length === 0) {
          localStorage.clear()
          return (
            <Route
              path='/redirect'
              element={
                <RedirectUrl
                  url={`${profileApiUrl === '' ? 'http://localhost:8084' : profileApiUrl}/logout`}
                />
              }
            />
          )
        }
      }
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Basic Routes */}
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<RequestCode />} />

        {/* App Routes */}

        <Route
          element={
            accountId !== '' ? (
              <PubNubWrapper>
                <Layout>
                  <Outlet />
                </Layout>
              </PubNubWrapper>
            ) : (
              <Loader />
            )
          }
        >
          <Route path='app'>
            <Route
              index={true}
              element={
                <Suspense fallback={<Loader />}>
                  <Dashboard />
                </Suspense>
              }
            />

            {appRouteList.map(({ path, Component }, index: number) => (
              <Route
                key={index}
                path={path}
                element={<Suspense fallback={<Loader />}>{Component}</Suspense>}
              />
            ))}
          </Route>

          <Route
            index={true}
            key={'not_found'}
            path='*'
            element={
              <Suspense fallback={<Loader />}>
                <PageNotFound />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
