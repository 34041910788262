import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiStatus, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import {
  CancelBroadCastMessageTypes,
  requestToCancelBroadcastMessage,
} from '../../../api/conversation'

const initialState: ApiStatus = {
  status: 'idle',
}

export const cancelBroadcastMessage = createAsyncThunk(
  'message/all',
  async (data: CancelBroadCastMessageTypes, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestToCancelBroadcastMessage(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const CancelBroadcastMessageSlice = createSlice({
  name: 'ConversationCancel',
  initialState,
  reducers: {
    clearCancelConversationState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelBroadcastMessage.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(cancelBroadcastMessage.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(cancelBroadcastMessage.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearCancelConversationState } = CancelBroadcastMessageSlice.actions
export const cancelBroadcastApi = (state: RootState): string => state.broadcastCancel.status

export default CancelBroadcastMessageSlice.reducer
