const colorOptions: string[] = [
  '#7921B9',
  '#f36f32',
  '#54D781',
  '#b454d7',
  '#898989',
  '#FF9300',
  '#B92167',
  '#21B95B',
  '#B96721',
  '#1CB7D3',
]

export const pickRandomColor = (id: null | number): string => {
  if (id !== null && id !== undefined && id < 11) {
    return colorOptions[id]
  } else if (id !== null && id !== undefined && id >= 11) {
    const idToArray = id.toString().split('')
    const lastNumber = idToArray[idToArray.length - 1]

    return colorOptions[parseInt(lastNumber)]
  }

  return colorOptions[Math.floor(Math.random() * colorOptions.length)]
}
