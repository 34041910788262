import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { FetchPropertyListType, fetchAutomationPropertyList } from '../../../api/automation'
import { AutomationPropertyList, AutomationPropertyListObjectProps } from './automation.types'

const initialState: AutomationPropertyList = {
  status: 'idle',
  propertyList: [],
}

export const getAutomationPropertyList = createAsyncThunk(
  'automation/propertyList',
  async (data: FetchPropertyListType, { rejectWithValue }) => {
    let response: Response = {}

    response = await fetchAutomationPropertyList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const automationPropertyListSlice = createSlice({
  name: 'automationProperty',
  initialState,
  reducers: {
    clearAutomationState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAutomationPropertyList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAutomationPropertyList.fulfilled, (state, action) => {
        state.status = 'success'
        state.propertyList = action?.payload as AutomationPropertyListObjectProps[]
      })
      .addCase(getAutomationPropertyList.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearAutomationState } = automationPropertyListSlice.actions
export const automationPropertyListApi = (state: RootState): string =>
  state.automationProperties.status
export const automationPropertyList = (state: RootState): AutomationPropertyListObjectProps[] =>
  state.automationProperties.propertyList

export default automationPropertyListSlice.reducer
