import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestUserAccounts } from '../../../api/common'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError, UserAccount, UserAccountsState } from './accounts.types'

const initialState: UserAccountsState = {
  status: 'idle',
  userAccounts: [],
  error: {},
}

export const getUserAccounts = createAsyncThunk(
  'userAccounts',
  async (userId: string, { rejectWithValue }) => {
    const response: Response = await requestUserAccounts(userId)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const userAccountsSlice = createSlice({
  name: 'userAccounts',
  initialState,
  reducers: {
    clearUserAccounts: (state) => {
      state = {
        status: 'idle',
        userAccounts: [],
        error: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAccounts.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getUserAccounts.fulfilled, (state, action) => {
        state.status = 'success'
        state.userAccounts = action.payload as UserAccount[]
      })
      .addCase(getUserAccounts.rejected, (state, action: any) => {
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        state.status = 'failed'
        state.error = errorMessage
        state.userAccounts = initialState.userAccounts
      })
  },
})
export const { clearUserAccounts } = userAccountsSlice.actions
export const userAccountsApiStatus = (state: RootState): string => state.userAccounts.status
export const userAccountsResult = (state: RootState): UserAccount[] =>
  state.userAccounts.userAccounts as unknown as UserAccount[]
export const userAccountsError = (state: RootState): any => state.userAccounts.error

export default userAccountsSlice.reducer
