import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from 'axios'
import { messageEmitter } from '../../utils/toastify-message-emmiter'

// Env Variables
const refreshUsername = process.env.REACT_APP_REFRESH_PASSWORD ?? ''
const refreshPassword = process.env.REACT_APP_REFRESH_USERNAME ?? ''
const accessTokenUri = process.env.REACT_APP_ACCESS_TOKEN_URI ?? ''
const baseUrlLogin = process.env.REACT_APP_REDIRECT_UI ?? ''

export const AxiosInterceptor = (token: string): void => {
  axios.interceptors.request.clear()
  axios.interceptors.response.clear()

  const handleUnauthorized = async (error?: AxiosError<any>): Promise<void> => {
    const originalRequest = error !== null ? error?.config : null

    if (originalRequest?.url === `/auth/oauth/token`) {
      window.location.href = baseUrlLogin
    } else {
      window.location.replace('/')

      console.error(
        'remove after refresh token handler added',
        originalRequest?.url,
        refreshUsername,
        refreshPassword,
        accessTokenUri,
      )
    }

    return await Promise.reject(error)
  }

  // Set the Interceptor Configurations
  axios.interceptors.request.use(
    (config: AxiosRequestConfig | any) => {
      const userId = localStorage.getItem('userId')

      if (userId === undefined || userId === null || userId === '') {
        void handleUnauthorized()
      }

      config.headers = (config.headers as AxiosHeaders) ?? {}
      //  For third-party api request please send a custom header as 'Third-Party': true,
      const isThirdPartyApiRequest = config.headers.get('Third-Party') ?? false

      if (isThirdPartyApiRequest === false) {
        config.headers.set('Authorization', `Bearer ${token}`)
        config.headers.set('Accept', 'application/json')
      }

      return config
    },
    async (err) => await Promise.reject(err),
  )

  axios.interceptors.response.use(
    (response) => response,
    async function (error: AxiosError<any>) {
      const status = error?.response?.status

      if (error?.code === 'ERR_NETWORK') {
        console.error('Please check your connection and retry')
        return await Promise.reject(error)
      }

      switch (status) {
        case 400: {
          const firstMessage = Object.values(error.response?.data?.errors)[0] as any
          const errorMessage = firstMessage?.message ?? firstMessage
          void messageEmitter(errorMessage as string, 'error')
          break
        }

        case 401: {
          return await handleUnauthorized(error)
        }

        case 404: {
          void messageEmitter('404 Not Found Error', 'error')

          break
        }

        case 405: {
          void messageEmitter('405 Method Not Allowed', 'error')

          break
        }

        case 409: {
          const firstMessage = Object.values(error.response?.data?.customErrors)[0]
          void messageEmitter(firstMessage as string, 'error')
          break
        }

        case 422: {
          const firstMessage = Object.values(error.response?.data?.errors)[0] as any
          const errorMessage = firstMessage?.message ?? firstMessage
          void messageEmitter(errorMessage as string, 'error')
          break
        }

        case 500: {
          void messageEmitter('500 Internal Server Error', 'error')
          break
        }
      }
      return await Promise.reject(error)
    },
  )
}

export default AxiosInterceptor
