import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { ActionListPropsType, fetchAutomationActionList } from '../../../api/automation'
import { ActionListObjectType, AutomationActionList } from './automation.types'

const initialState: AutomationActionList = {
  status: 'idle',
  actionList: [],
}

export const getAutomationActionList = createAsyncThunk(
  'automation/actionList',
  async (data: ActionListPropsType, { rejectWithValue }) => {
    let response: Response = {}

    response = await fetchAutomationActionList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const automationActionListSlice = createSlice({
  name: 'automationAction',
  initialState,
  reducers: {
    clearAutomationActionState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAutomationActionList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAutomationActionList.fulfilled, (state, action) => {
        state.status = 'success'
        state.actionList = action?.payload as ActionListObjectType[]
      })
      .addCase(getAutomationActionList.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearAutomationActionState } = automationActionListSlice.actions
export const automationActionApi = (state: RootState): string => state.automationActions.status
export const automationActionList = (state: RootState): ActionListObjectType[] =>
  state.automationActions.actionList

export default automationActionListSlice.reducer
