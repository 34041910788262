import React from 'react'
import { ClassNames } from '../../types/common.types'

const BetaIcon: React.FC<ClassNames> = ({ className }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='1em' viewBox='0 0 930 410' className={className}>
      <g id='组_1522' data-name='组 1522' transform='translate(-12063 -10223)'>
        <path
          id='路径_1235'
          data-name='路径 1235'
          d='M205,0H725C838.218,0,930,91.782,930,205S838.218,410,725,410H205C91.782,410,0,318.218,0,205S91.782,0,205,0Z'
          transform='translate(12063 10223)'
          fill='#b9a077'
        />
        <path
          id='联合_649'
          data-name='联合 649'
          d='M0,210V0H93.143a50.091,50.091,0,0,1,46.142,30.393,49.183,49.183,0,0,1,3.935,19.37v4.976a59.188,59.188,0,0,1-10.263,33.388,60.3,60.3,0,0,1-7.337,8.838q-1.767,1.756-3.683,3.367a60.151,60.151,0,0,1,14.025,14.574,59.188,59.188,0,0,1,10.263,33.388v11.943a49.457,49.457,0,0,1-14.667,35.188A50.08,50.08,0,0,1,96.148,210Zm32.721-28.508H82.61A32.947,32.947,0,0,0,112.96,161.5a32.567,32.567,0,0,0-7.059-35.884,32.932,32.932,0,0,0-23.291-9.587H32.721ZM32.05,88.578H82.126a31.055,31.055,0,0,0,28.608-18.844,30.7,30.7,0,0,0-6.654-33.826,31.055,31.055,0,0,0-21.954-9.036H32.05ZM613.945,210l-17.031-45.782H509.789L492.758,210H456.7L534.823,0H571.88L650,210ZM520.156,136.35h66.391l-33.2-89.235ZM364.56,210V28.862H307.473V0H455.7V28.862H397.612V210Zm-175.269,0H177.273V0H295.455V28.862H210.324V89.573h77.118v28.862H210.324v62.7h87.133V210Z'
          transform='translate(12203 10323)'
          fill='#ffffff'
        />
      </g>
    </svg>
  )
}

export default BetaIcon
