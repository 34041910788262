import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NewContactDataSet } from '../../../components/add-new-contact/new-contacts.types'
import { saveNewContactDetails } from '../../../modules/contacts/contacts-api'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { AddContactState, ResponseError } from './contacts.types'

const initialState: AddContactState = {
  status: 'idle',
  addedContactDetails: {},
  error: null,
}

export const createNewContact = createAsyncThunk(
  'newContact/create',
  async (contactDetails: NewContactDataSet, { rejectWithValue }) => {
    const { data, message, response }: Response = await saveNewContactDetails(contactDetails)

    if (data === undefined && message !== null) {
      const errors: ResponseError = {
        errorMessage: response?.data?.errors ?? '',
        apiStatusMessage: response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return data
  },
)

export const addContactSlice = createSlice({
  name: 'createContact',
  initialState,
  reducers: {
    clearCreateNewContactDetails: (state) => {
      state.status = 'idle'
      state.error = null
      state.addedContactDetails = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewContact.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createNewContact.fulfilled, (state, action) => {
        state.status = 'success'
        state.addedContactDetails = action.payload
      })
      .addCase(createNewContact.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage

        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        // Decode the Error Message returning from backend
        errorMessage.errorType = action?.payload?.apiStatusMessage

        if (errorFromPayload.email !== '') {
          errorMessage.message = errorFromPayload.email
        }
        if (
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== undefined &&
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== ''
        ) {
          errorMessage.message = errorFromPayload['phoneNumbers[0].phoneNumber']
        }

        state.status = 'failed'
        state.error = errorMessage
        state.addedContactDetails = initialState.addedContactDetails
      })
  },
})
export const { clearCreateNewContactDetails } = addContactSlice.actions
export const createNewContactApiStatus = (state: RootState): string => state.newContact.status
export const newContactResult = (state: RootState): any => state.newContact.addedContactDetails
export const createContactError = (state: RootState): any => state.newContact.error

export default addContactSlice.reducer
