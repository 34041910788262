import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export interface UserState {
  originalUserId: string | null
  userId: string | null
  userChecker: number | null
}

const initialState: UserState = {
  originalUserId: '',
  userId: '',
  userChecker: 0,
}

export const userSlice = createSlice({
  name: 'userHandler',
  initialState,
  reducers: {
    setUserDetails: (state) => {
      const userId = localStorage.getItem('accountId')
      const originalUserId = localStorage.getItem('userId')

      if (userId === null || userId === undefined || userId === '') {
        state.userId = ''
        state.userChecker = (state.userChecker as number) + 1
      } else {
        state.userId = userId
        state.userChecker = null
      }

      if (originalUserId !== undefined && originalUserId !== null && originalUserId !== '') {
        state.originalUserId = originalUserId
      }
    },
    clearUserDetails: (state) => {
      localStorage.removeItem('accountId')
      localStorage.removeItem('userId')
      state.userId = ''
      state.originalUserId = ''
      state.userChecker = 0
    },
  },
})

export const { setUserDetails, clearUserDetails } = userSlice.actions

export const userId = (state: RootState): string => state.user.userId as string
export const originalUserId = (state: RootState): string => state.user.originalUserId as string
export const userCheck = (state: RootState): number | null => state.user.userChecker

export default userSlice.reducer
