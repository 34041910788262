import React, { useEffect, useState } from 'react'
import { Button, Divider, Drawer, Grid, Space } from 'antd'
// import { Link } from 'react-router-dom'
import { MenuOutlined, LeftOutlined, CloseOutlined } from '@ant-design/icons'
import { profileApiUrl } from '../../api/api-variables'
import logo from './../../assets/images/sinorbis_logo_white.png'
import { originalUserId, userId } from '../../redux/slice/user/user.slice'
import { getUserDetails, userDetailsResult } from '../../redux/slice/user/user-details.slice'

import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import UserAvatar from '../user-avatar/UserAvatar'
import AccountsSelector from './AccountsSelector'
import * as Authorities from '../../utils/authority-constants'
import { hasPolicyAccess } from '../../utils/common-function'

const LayoutHeader: React.FC = () => {
  const { useBreakpoint } = Grid
  const { lg } = useBreakpoint()
  const [showDrawer, setShowDrawer] = useState(false)
  const [showBgGreyOverlay, setShowBgGreyOverlay] = useState(false)
  const [showMobileHeaderUserMenu, setShowMobileHeaderUserMenu] = useState(false)
  const dispatch = useAppDispatch()
  const originalUsersId = useAppSelector(originalUserId)
  const accountId = useAppSelector(userId)
  const userDetails = useAppSelector(userDetailsResult)
  const authBaseUrl = process.env.REACT_APP_API_SCP_AUTH ?? ''
  const seoBaseUrl = process.env.REACT_APP_API_SCP_SEO ?? ''

  const projectId = localStorage.getItem('projectId') ?? ''
  const websiteId = localStorage.getItem('websiteId') ?? ''
  const isChinaLocale = localStorage.getItem('isChinaLocalAvailable') ?? ''
  const isWebsiteSetup = localStorage.getItem('isWebsiteSetup') ?? ''

  useEffect(() => {
    fetchUserDetails()
  }, [])

  const fetchUserDetails = (): void => {
    void dispatch(getUserDetails(originalUsersId))
  }

  const logout = (): void => {
    // Commented this line because alfred integration no longer in use
    // void clearChatbotHistory({ accountId })

    setTimeout(() => {
      // Clear local storage
      localStorage.clear()
      // Navigate to logout link
      window.location.href = `${
        profileApiUrl === '' ? 'http://localhost:8084' : profileApiUrl
      }/logout`
    }, 1000)
  }

  const getFullName = (): string => {
    let fullName = userDetails.firstName

    if (
      userDetails.lastName !== undefined &&
      userDetails.lastName !== null &&
      userDetails.lastName !== ''
    ) {
      fullName += ' ' + userDetails.lastName
    }

    return fullName
  }

  const isWebsiteSetupComplete = (): boolean => {
    return isWebsiteSetup === 'true'
  }

  const hasDashboardAccess = (): boolean => {
    return isChinaLocale === 'true' && hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL)
  }

  const handleClick = (): void => {
    setShowDrawer((current) => !current)
  }

  const onMouseHover = (): void => {
    setShowBgGreyOverlay(true)
  }

  const onMouseExit = (): void => {
    setShowBgGreyOverlay(false)
  }

  const showUserMenu = (): void => {
    setShowMobileHeaderUserMenu(true)
  }

  const hideUserMenu = (): void => {
    setShowMobileHeaderUserMenu(false)
  }

  return (
    <>
      <div className='bg-grey-overlay' style={{ display: showBgGreyOverlay ? 'block' : 'none' }} />
      <div style={{ display: 'none' }}>
        <AccountsSelector borderless={true} />
      </div>
      <nav className='header'>
        <div className='navbar-brand'>
          {isWebsiteSetupComplete() && hasDashboardAccess() && (
            <a
              href={`${seoBaseUrl}dashboard/?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              <img className='brand-logo' src={logo} alt='Sinorbis' />
            </a>
          )}
          {!isWebsiteSetupComplete() && hasDashboardAccess() && (
            <a href={`${seoBaseUrl}self-start?accountId=${accountId}`}>
              <img className='brand-logo' src={logo} alt='Sinorbis' />
            </a>
          )}
          {!hasDashboardAccess() && <img className='brand-logo' src={logo} alt='Sinorbis' />}
        </div>
        <div className='menus'>
          <div className='leftMenu'>
            <div style={{ display: 'inline-block', minWidth: '420px', position: 'fixed' }}>
              <LeftMenu onMouseHover={onMouseHover} onMouseExit={onMouseExit} />
            </div>
          </div>
          <div className='rightMenu'>
            <RightMenu onMouseHover={onMouseHover} onMouseExit={onMouseExit} />
          </div>
          <Button className='barsMenu' type='primary' onClick={handleClick}>
            <MenuOutlined />
          </Button>
          <Drawer
            className='nav-drawer'
            title='Basic Drawer'
            placement='right'
            closable={showDrawer}
            open={showDrawer && lg !== true}
            width={360}
          >
            {!showMobileHeaderUserMenu && (
              <>
                <LeftMenu onMenuItemClicked={handleClick} />
                <div className='drawer-user-info' onClick={showUserMenu}>
                  <Space>
                    <span className='avtar' style={{ backgroundColor: 'transparent' }}>
                      <UserAvatar
                        name={getFullName()}
                        profileImage={userDetails.profileImageUrl}
                        isLoggedInUser={true}
                      />
                    </span>
                    <span className='logged-in-user-name text-ellipsis'>
                      {getFullName()}
                      <div className='header-user-email'>{userDetails.email}</div>
                    </span>
                  </Space>
                  <div className='down-icon' />
                </div>
                <div>
                  <a
                    className=''
                    href='https://help.sinorbis.com/hc/en-us'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button type='primary' style={{ borderRadius: '0' }} block>
                      <span className='mx-1'>Support</span>
                    </Button>
                  </a>
                </div>
              </>
            )}
            {showMobileHeaderUserMenu && (
              <>
                <div className='drawer-user-menu'>
                  <div className='left-menu-close' onClick={hideUserMenu}>
                    <LeftOutlined />
                    MENU
                  </div>
                  <div className='right-menu-close' onClick={hideUserMenu}>
                    <CloseOutlined />
                  </div>
                </div>
                <div className='header-user-menu-dropdown'>
                  <div className='header-user-details' onClick={(e) => e.preventDefault()}>
                    <Space style={{ cursor: 'pointer' }}>
                      <span className='avtar' style={{ backgroundColor: 'transparent' }}>
                        <UserAvatar
                          name={getFullName()}
                          profileImage={userDetails.profileImageUrl}
                          isLoggedInUser={true}
                        />
                      </span>
                      <span  className='logged-in-user-name text-ellipsis'>
                        {getFullName()}
                        <div className='header-user-email'>{userDetails.email}</div>
                      </span>
                    </Space>
                  </div>

                  {hasPolicyAccess(Authorities.SCP_SETTINGS_PROFILE_ALL) && (
                    <div className='header-profile-link'>
                      <a
                        href={`${authBaseUrl}auth/userprofile?selected=profile&accountId=${accountId}`}
                      >
                        Profile & Preferences
                      </a>
                    </div>
                  )}

                  <div className='header-account-select'>
                    <Space>
                      <AccountsSelector
                        popupClassName='nav-drawer-account-selection'
                        borderless={true}
                      />
                    </Space>
                  </div>

                  <div className='header-nav-links'>
                    {hasPolicyAccess(Authorities.SCP_SETTINGS_BUSINESS_ALL) && (
                      <div className='global-link'>
                        <a
                          href={`${authBaseUrl}auth/userprofile?selected=business&accountId=${accountId}`}
                        >
                          Business Information
                        </a>
                      </div>
                    )}
                    {hasPolicyAccess(Authorities.SCP_SETTINGS_BILLING_ALL) && (
                      <div className='global-link'>
                        <a
                          href={`${authBaseUrl}auth/userprofile?selected=billing&accountId=${accountId}`}
                        >
                          Billing
                        </a>
                      </div>
                    )}
                    {hasPolicyAccess(Authorities.SCP_SETTINGS_USERS_ALL) && (
                      <div className='global-link'>
                        <a
                          href={`${authBaseUrl}auth/userprofile?selected=users&accountId=${accountId}`}
                        >
                          Users
                        </a>
                      </div>
                    )}
                    {hasPolicyAccess(Authorities.SCP_SETTINGS_PRIVACY_ALL) && (
                      <div className='global-link'>
                        <a
                          href={`${authBaseUrl}auth/userprofile?selected=privacy&accountId=${accountId}`}
                        >
                          Data & Privacy
                        </a>
                      </div>
                    )}
                    <div className='global-link'>
                      <a href={`${authBaseUrl}auth/whatsnew`}>
                        What<>&#39;</>s New?
                      </a>
                    </div>
                  </div>

                  <div className='logout-link-area'>
                    <Divider style={{ margin: 0 }} />

                    <div className='header-logout-link'>
                      <a onClick={logout}>Sign out</a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Drawer>
        </div>
      </nav>
    </>
  )
}

export default LayoutHeader
