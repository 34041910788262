import React, { useEffect } from 'react'
import Layout from '../../components/layout/Layout'
import { updatePageTitle } from '../../utils/common-function'

const PageNotFound: React.FC = () => {
  useEffect(() => {
    updatePageTitle('Page not found', null)
  }, [])

  return (
    <Layout>
      <div className='page-not-found-bg'>
        <div className='page-404-contents'>
          <div className='page-not-found-eng'>The page you are looking for does not exist.</div>
          <div className='page-not-found-other-lang'>您正在查找的页面不存在</div>
          <div className='page-not-found-other-lang'>您正在尋找的頁面不存在</div>
          <div className='page-not-found-other-lang'>Halaman yang Anda cari tidak ada</div>
          <div className='page-not-found-other-lang'>お探しのページは存在しません</div>
          <div className='page-not-found-other-lang'>찾으시는 페이지가 존재하지 않습니다</div>
          <div className='page-not-found-other-lang'>หน้าที่คุณกำลังค้นหาไม่มีอยู่</div>
          <div className='page-not-found-other-lang'>Trang bạn đang tìm kiếm không tồn tại</div>
        </div>
      </div>
    </Layout>
  )
}

export default PageNotFound
