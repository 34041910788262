import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { saveSegment } from '../../../api/common'
import { ErrorMessageState, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { NewSegmentPayloadTypes, NewSegmentStateType } from './segments.types'

const initialState: NewSegmentStateType = {
  status: 'idle',
  newSegment: {},
}

export const createNewSegment = createAsyncThunk(
  'segment/create',
  async (contactDetails: NewSegmentPayloadTypes, { rejectWithValue }) => {
    const { data, message, response }: Response = await saveSegment(contactDetails)

    if (data === undefined && message !== null) {
      const errors: ResponseError = {
        errorMessage: response?.data?.errors ?? '',
        apiStatusMessage: response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return data
  },
)

export const newSegmentSlice = createSlice({
  name: 'createNewSegment',
  initialState,
  reducers: {
    clearNewSegmentState: (state) => {
      state.status = 'idle'
      state.newSegment = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewSegment.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createNewSegment.fulfilled, (state, action) => {
        state.status = 'success'
        state.newSegment = action.payload as Object
      })
      .addCase(createNewSegment.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage

        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        if (errorFromPayload.email !== '') {
          errorMessage.message = errorFromPayload.email
        }

        if (
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== undefined &&
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== ''
        ) {
          errorMessage.message = errorFromPayload['phoneNumbers[0].phoneNumber']
        }

        state.status = 'failed'
        state.newSegment = initialState.newSegment
      })
  },
})
export const { clearNewSegmentState } = newSegmentSlice.actions
export const newSegmentApiStatus = (state: RootState): string => state.createSegment.status
export const newSegment = (state: RootState): object => state.createSegment.newSegment

export default newSegmentSlice.reducer
