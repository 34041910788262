import React from 'react'
import ClientOAuth2 from 'client-oauth2'
import { AuthorizationCodeCallback } from 'react-oauth2-auth-code-flow'
import { useNavigate } from 'react-router-dom'
import { auth } from './auth.config'
import Loader from '../../components/loader/Loader'

interface AccessToken {
  data: any
  accessToken: string
  refreshToken: string
}

const oauthClient = new ClientOAuth2(auth)

const queryParameters = new URLSearchParams(window.location.search)
const redirectUri = queryParameters.get('redirect_uri')

// If there is redirect uri set in the query params, set it in local storage until we fetch the new token
if (redirectUri !== undefined && redirectUri !== null && redirectUri !== '') {
  localStorage.setItem('redirect_uri', redirectUri)
}

// Todo: Enable this when policies add

// const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL ?? ''

const Login: React.FC = () => {
  const history = useNavigate()
  const handleSuccess = async (
    accessToken: AccessToken,
    { response, state }: any,
  ): Promise<any> => {
    // Todo: Enable this when policies add

    // Get all permissions
    // const permissions = jwtDecode(accessToken.accessToken)?.authorities

    // Filter soc policy
    // const socPermissions = permissions?.filter((permission) => permission.startsWith('soc'))

    // Check if user havent sos policy

    // if (!Array.isArray(socPermissions) || socPermissions.length === 0) {
    //   // If user did not have soc policy then redirect to login page
    //   window.location.href = `${authBaseUrl} + ?error7=login`
    //   return
    // }

    // Save Token Details On Local Storage

    localStorage.setItem('access_token', accessToken.accessToken)
    localStorage.setItem('refresh_token', accessToken.refreshToken)
    localStorage.setItem('accountId', accessToken.data.accountId)
    localStorage.setItem('userId', accessToken.data.userId)
    localStorage.setItem('authorities', accessToken.data.authorities)
    localStorage.setItem('websiteId', accessToken.data.websiteId)
    localStorage.setItem('projectId', accessToken.data.projectId)
    localStorage.setItem('isBaiduSetup', accessToken.data.isBaiduSetup)
    localStorage.setItem('isWebsiteSetup', accessToken.data.isWebsiteSetup)
    localStorage.setItem('isWeChatSetup', accessToken.data.isWeChatSetup)
    localStorage.setItem('isChinaLocalAvailable', accessToken.data.isChinaLocalAvailable)

    const redirectUri = localStorage.getItem('redirect_uri')

    // Redirect to redirect_uri in local storage if set, else, redirect to contacts list
    if (redirectUri !== undefined && redirectUri !== null && redirectUri !== '') {
      localStorage.removeItem('redirect_uri')
      history(redirectUri)
    } else {
      history('/app/engage')
    }
  }

  const handleError = (error: any): void => {
    console.error(error.message)
  }

  return (
    <AuthorizationCodeCallback
      oauthClient={oauthClient}
      onAuthSuccess={handleSuccess}
      onAuthError={handleError}
      render={({ processing, error }: any) => (
        <div>
          {Boolean(processing) && <Loader />}
          {Boolean(error) && <p className='error'>An error occurred: {error.message}</p>}
        </div>
      )}
    />
  )
}

export default Login
