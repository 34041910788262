import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestDeleteSegments } from '../../../api/common'
import { ApiStatus, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'

const initialState: ApiStatus = {
  status: 'idle',
}

export const deleteSegments = createAsyncThunk(
  'segments/delete',
  async (data: any, { rejectWithValue }) => {
    const response: Response = await requestDeleteSegments(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const deleteSegmentsSlice = createSlice({
  name: 'deleteContacts',
  initialState,
  reducers: {
    resetDeleteSegmentState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteSegments.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteSegments.fulfilled, (state) => {
        state.status = 'success'
      })
      .addCase(deleteSegments.rejected, (state) => {
        state.status = 'failed'
      })
  },
})
export const { resetDeleteSegmentState } = deleteSegmentsSlice.actions
export const deleteSegmentsApiStatus = (state: RootState): string =>
  state.deleteSegment.status as string

export default deleteSegmentsSlice.reducer
