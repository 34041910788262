import moment from 'moment'

export const convertUtcTimeToLocalTime = (
  utcTimeStamp: string | null | undefined,
  format: string,
): string => {
  if (utcTimeStamp !== null && utcTimeStamp !== undefined) {
    return moment.utc(utcTimeStamp).local().format(format)
  }

  return ''
}

export const getCurrentLocalTime = (format: string): string => {
  return moment().local().format(format)
}

export const getCurrentUtcTime = (): any => {
  return moment().utc().format('YYYY-MM-DDTHH:mm:ss')
}

export const getCurrentTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}
