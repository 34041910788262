import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ContactDetailsSingleContactState } from '../../../modules/contact-details-page/contact-details.types'
import { getContactApiRequest } from '../../../modules/contacts/contacts-api'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { GetContactDetailsState, ResponseError } from './contacts.types'

const initialState: GetContactDetailsState = {
  status: 'idle',
  contact: {},
  error: null,
}

export const getContactById = createAsyncThunk(
  'contact/byId',
  async (data: any, { rejectWithValue }) => {
    const response: Response = await getContactApiRequest(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getContactByIdSlice = createSlice({
  name: 'getContactById',
  initialState,
  reducers: {
    clearGetContactState: (state) => {
      state.status = 'idle'
      state.error = null
      state.contact = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactById.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getContactById.fulfilled, (state, action) => {
        state.status = 'success'
        state.contact = action.payload
      })
      .addCase(getContactById.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage

        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        if (errorFromPayload.email !== '') {
          errorMessage.message = errorFromPayload.email
        }

        if (
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== undefined &&
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== ''
        ) {
          errorMessage.message = errorFromPayload['phoneNumbers[0].phoneNumber']
        }

        state.status = 'failed'
        state.error = errorMessage
        state.contact = initialState.contact
      })
  },
})

export const { clearGetContactState } = getContactByIdSlice.actions
export const getContactApiStatus = (state: RootState): string => state.contactById.status
export const getContactDetails = (state: RootState): ContactDetailsSingleContactState =>
  state.contactById.contact as ContactDetailsSingleContactState
export const getContactApiErrors = (state: RootState): ErrorMessageState =>
  state.contactById.error as ErrorMessageState

export default getContactByIdSlice.reducer
