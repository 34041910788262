import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MessageSeenStateTypes, requestToSetMessagesAsSeen } from '../../../api/messages'
import {
  ApiStatus,
  ErrorMessageState,
  Response,
  ResponseError,
} from '../../../types/common.types'
import { RootState } from '../../store'

const initialState: ApiStatus = {
  status: 'idle',
}

export const setMessageAsSeen = createAsyncThunk(
  'message/setSeen',
  async (data: MessageSeenStateTypes, { rejectWithValue }) => {
    let response: Response = {}
    response = await requestToSetMessagesAsSeen(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const setMessageAsSeenSlice = createSlice({
  name: 'messageList',
  initialState,
  reducers: {
    clearSetSeenMessageState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setMessageAsSeen.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(setMessageAsSeen.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(setMessageAsSeen.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        if (errorFromPayload.contacts !== undefined) {
          errorMessage.errorType = errorFromPayload.contacts
        }

        state.status = 'failed'
      })
  },
})
export const { clearSetSeenMessageState } = setMessageAsSeenSlice.actions
export const setSeenMessageApi = (state: RootState): string => state.setSeenMessage.status

export default setMessageAsSeenSlice.reducer
