import { FC } from 'react'

interface RedirectUrlProps {
  url: string
}

const RedirectUrl: FC<RedirectUrlProps> = ({ url }) => {
  window.location.href = url
  return null
}

export default RedirectUrl
