import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestSourceList } from '../../../api/common'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { SourceListState, SourceObjectState } from './sources.type'

const initialState: SourceListState = {
  status: 'idle',
  sourceList: [],
  error: null,
}

export const getContactSourceList = createAsyncThunk(
  'source/getAllContactSources',
  async (data: any, { rejectWithValue }) => {
    const response: Response = await requestSourceList(data, true)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getContactSourceListSlice = createSlice({
  name: 'sourceList',
  initialState,
  reducers: {
    clearSourceList: (state) => {
      state.status = 'idle'
      state.error = null
      state.sourceList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactSourceList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getContactSourceList.fulfilled, (state, action) => {
        state.status = 'success'
        state.sourceList = action.payload as SourceObjectState[]
      })
      .addCase(getContactSourceList.rejected, (state, action: any) => {
        const errorMessage: ErrorMessageState = {
          message: 'Source List Error',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        state.status = 'failed'
        state.error = errorMessage
        state.sourceList = initialState.sourceList
      })
  },
})
export const { clearSourceList } = getContactSourceListSlice.actions
export const getContactSourceListStatus = (state: RootState): string =>
  state.contactSourceList.status
export const getContactSourceListCollection = (state: RootState): SourceObjectState[] =>
  state.contactSourceList.sourceList as SourceObjectState[]
export const getContactSourceListError = (state: RootState): ErrorMessageState =>
  state.contactSourceList.error as ErrorMessageState

export default getContactSourceListSlice.reducer
