import React from 'react'
import { Layout } from 'antd'
import { useAppSelector } from '../../redux/hooks'
import { userId } from '../../redux/slice/user/user.slice'

import styles from './LayoutFooter.module.scss'

const { Footer } = Layout

const LayoutFooter: React.FC = () => {
  // Get the Current year
  const year = new Date().getFullYear().toString()

  const accountId = useAppSelector(userId)
  const authBaseUrl = process.env.REACT_APP_API_SCP_AUTH ?? ''

  return (
    <Footer className={styles.footerWrapper}>
      <div>
        © {year} – Sinorbis Holdings Pty Limited – ACN: 605 346 996{' – '}
        <a target='_blank' href='https://www.sinorbis.com/privacy' rel='noreferrer'>
          Privacy policy
        </a>
        {' – '}
        <a target='_blank' href={`${authBaseUrl}auth/terms?accountId=${accountId}`} rel='noreferrer'>
          Terms of use
        </a>
      </div>
    </Footer>
  )
}

export default LayoutFooter
