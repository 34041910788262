import axios from 'axios'
import { Response, SortState } from '../types/common.types'
import { getCurrentTimezone } from '../utils/date-time'
import { messageApiUrl } from './api-variables'
import { ToggleMessageFavoriteRequestBody } from '../components/extended-message-box/extended-message-box.types'

interface MessageListParamsType {
  page?: number
  filterKey?: string
  segmentId?: number | string
  source?: string
  unread?: boolean
  timezone?: string
  autoResponse?: boolean
  onlyFavorites?: boolean
  channelId?: string
}

export interface NewMessagePostType {
  clientAccountId: string
  data: {
    contactId: string
    content: string
    channelId: string
  }
}

export interface CustomSortDataMessageList extends SortState {
  channelId: string
  autoResponse?: boolean
  onlyFavorites?: boolean
  conversationId: string
}

export interface ConversationCreateContactObject {
  id: number
  type: string
}

export interface ConversationCreateValidationObject {
  data: ConversationCreateContactObject[]
  clientAccountId: string
}

export interface MessageSeenStateTypes {
  clientAccountId: string
  conversationId: string
  channelId: string
}

export const requestMessageList = async (data: CustomSortDataMessageList): Promise<any> => {
  const params: MessageListParamsType = {
    timezone: getCurrentTimezone(),
    autoResponse: data.autoResponse,
    onlyFavorites: data.onlyFavorites,
  }

  if (data.current !== null && !Number.isNaN(data.current)) {
    params.page = data.current
  }

  return await axios
    .get(
      `${messageApiUrl}/api/v1/${data.clientAccountId as string}/messages/conversations/${
        data.conversationId
      }/channels/${data.channelId}`,
      { params },
    )
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const requestValidateConversationContacts = async (
  data: ConversationCreateValidationObject,
): Promise<any> => {
  return await axios
    .post(`${messageApiUrl}/api/v1/${data.clientAccountId}/custom-validation`, data.data)
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const requestToSetMessagesAsSeen = async (data: MessageSeenStateTypes): Promise<any> => {
  return await axios
    .patch(
      `${messageApiUrl}/api/v1/${data.clientAccountId}/messages/conversations/${data.conversationId}`,
    )
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestContactMessageList = async (data: SortState): Promise<any> => {
  const params: MessageListParamsType = {}

  if (data.current !== null) {
    params.page = data.current
  }

  if (data.searchKeyword !== null && data.searchKeyword !== '') {
    params.filterKey = data.searchKeyword
  }

  if (data.segmentId !== null && data.segmentId !== '') {
    params.segmentId = data.segmentId
  }

  if (data.channel !== null && data.channel !== '') {
    params.channelId = data.channel
  }

  if (data.unread !== null && data.unread !== undefined) {
    params.unread = data.unread
  }

  return await axios
    .get(`${messageApiUrl}/api/v1/${data.clientAccountId as string}/conversations`, { params })
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const requestToSendMessage = async (data: NewMessagePostType): Promise<any> => {
  return await axios
    .post(`${messageApiUrl}/api/v1/${data.clientAccountId}/messages/text`, data.data)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestUnreadMessageCount = async (data: SortState): Promise<any> => {
  return await axios
    .get(
      `${messageApiUrl}/api/v1/${data.clientAccountId as string}/messages/contacts/${
        data.contactId as string
      }/unreadMessageCount`,
    )
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const requestChatbotHistory = async (data: { accountId: string | number }): Promise<any> => {
  return await axios
    .get(`${messageApiUrl}/api/v1/alfred/${data.accountId as string}/history`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const makeChatbotQuery = async (data: {
  query: string
  accountId: string | number
}): Promise<any> => {
  return await axios
    .post(`${messageApiUrl}/api/v1/${data.accountId as string}/alfred/query`, { query: data.query })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const clearChatbotHistory = async (data: { accountId: string | number }): Promise<any> => {
  return await axios
    .patch(`${messageApiUrl}/api/v1/alfred/${data.accountId as string}/disable-history`)
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const toggleMessageFavourite = async (
  toggleData: ToggleMessageFavoriteRequestBody,
): Promise<any> => {
  const data = {
    messageId: toggleData.messageId,
    favourite: toggleData.favorite,
  }

  return await axios
    .patch(
      `${messageApiUrl}/api/v1/${toggleData.clientAccountId as string}/messages/toggle/favorite`,
      { ...data },
    )
    .then((response: Response) => response)
    .catch((error: Error) => error)
}
