import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import notificationReducer from './slice/notification/notification.slice'
import themeSettingsReducer from './slice/theme-settings/theme-settings.slice'
import weChatAuthReducer from './slice/weChat-auth/wechat-auth.slice'
import weChatSetupReducer from './slice/weChat-setup/weChat-setup.slice'
import popMessageReducer from './slice/pop-up-message/pop-message.slice'
import userReducer from './slice/user/user.slice'
import addContactReducer from './slice/contacts/add-contact.slice'
import contactListReducer from './slice/contacts/contact-list.slice'
import deleteContactReducer from './slice/contacts/delete-contact.slice'
import updateContactReducer from './slice/contacts/update-contact.slice'
import getContactByIdReducer from './slice/contacts/get-contact-by-id.slice'
import getSourceListReducer from './slice/sources/sources.slice'
import getContactSourceListReducer from './slice/sources/contact-sources.slice'
import getCountryListReducer from './slice/countries/countries.slice'
import getContactCountryListReducer from './slice/countries/contact-countries.slice'
import segmentsReducer from './slice/segments/segments.slice'
import messageListReducer from './slice/message/get-message-list.slice'
import activitiesListReducer from './slice/activities/activities.slice'
import newSegmentReducer from './slice/segments/add-segment.slice'
import deleteSegmentReducer from './slice/segments/delete-segment.slice'
import applySegmentReducer from './slice/segments/apply-segment.slice'
import toggleFavoriteReducer from './slice/contacts/toggle-favorite.slice'
import deleteSegmentFromContactReducer from './slice/segments/delete-segment-from-contact.slice'
import setSeenMessagesReducer from './slice/message/set-seen-messages.slice'
import getMessagesSeparatedByContactsListReducer from './slice/message/messagesByContacts.slice'
import sendMessageReducer from './slice/message/send-messages.slice'
import unreadMessageCountReducer from './slice/message/unread-message-count.slice'
import mergeContactsReducer from './slice/contacts/merge-contacts.slice'
import getSecondaryMessageListReducer from './slice/message/get-secondary-message-list.slice'
import chatbotQueryReducer from './slice/chatbot/chatbot.slice'
import chatbotHistoryReducer from './slice/chatbot/chatbot-history.slice'
import userDetailsReducer from './slice/user/user-details.slice'
import userAccountsReducer from './slice/user/user-accounts.slice'
import languageListReducer from './slice/translate/language-list.slice'
import autoTranslateReducer from './slice/translate/auto-translate.slice'
import emailRelayReducer from './slice/message-reply-via-email/message-reply-via-email.slice'
import sinoClientsAccountsReducer from './slice/sino-client-accounts/sino-client-accounts.slice'
import primaryChannelsReducer from './slice/channels/primaryChannels.slice'
import contactChannelsReducer from './slice/channels/contact-channels.slice'
import getEmailRelayReducer from './slice/message-reply-via-email/email-relay-settings.slice'
import toggleMessageFavoriteReducer from './slice/message/toggle-message-favorite.slice'
import whatsappTemplateSelectorReducer from './slice/conversation/whatsapp-template.slice'
import ConversationSettingsReducers from './slice/conversation/conversations-send-settings'
import cancelConversationReducer from './slice/conversation/cancel-conversation'
import channelListReducer from './slice/channels/channelList.slice'
import channelListByTypeReducer from './slice/channels/channelListByType.slice'
import automationReducer from './slice/automation/automation-types.slices'
import automationCriteriaReducer from './slice/automation/automation-criteria.slices'
import automationTriggerReducer from './slice/automation/automation-trigger-list.slices'
import automationActionReducer from './slice/automation/automation-action-list.slice'
import automationRuleListReducer from './slice/automation/automation-rule-list'
import createRuleReducer from './slice/automation/automation-create-rule.slices'
import ChangeRuleExecutionOrderReducer from './slice/automation/automation-rules-execution-order'
import updateAutomationReducer from './slice/automation/automation-update.slice'
import automationPopupReducer from './slice/automation/automation-popup-status'
import updateRuleStatusReducer from './slice/automation/automation-rule-status-change'
import automationStatusTrackerReducer from './slice/automation/automation-status-tracker'
import automationDeletePopupReducer from './slice/automation/automation-delete-popup-status.slice'
import automationTemplateReducer from './slice/automation/automation-template.slice'

export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    themeSettings: themeSettingsReducer,
    weChatAuth: weChatAuthReducer,
    weChatSetup: weChatSetupReducer,
    popupMessage: popMessageReducer,
    user: userReducer,
    newContact: addContactReducer,
    contactList: contactListReducer,
    deleteContact: deleteContactReducer,
    updateContact: updateContactReducer,
    contactById: getContactByIdReducer,
    source: getSourceListReducer,
    contactSourceList: getContactSourceListReducer,
    country: getCountryListReducer,
    contactCountryList: getContactCountryListReducer,
    segmentList: segmentsReducer,
    messageList: messageListReducer,
    activitiesList: activitiesListReducer,
    createSegment: newSegmentReducer,
    deleteSegment: deleteSegmentReducer,
    applySegment: applySegmentReducer,
    toggleContactFavorite: toggleFavoriteReducer,
    deleteSegmentFromContact: deleteSegmentFromContactReducer,
    setSeenMessage: setSeenMessagesReducer,
    contactsMessage: getMessagesSeparatedByContactsListReducer,
    newMessage: sendMessageReducer,
    unreadMessage: unreadMessageCountReducer,
    mergeContacts: mergeContactsReducer,
    secondaryMessageList: getSecondaryMessageListReducer,
    chatbotQueryResult: chatbotQueryReducer,
    chatbotHistoryResult: chatbotHistoryReducer,
    userDetails: userDetailsReducer,
    userAccounts: userAccountsReducer,
    translateLanguage: languageListReducer,
    autoTranslate: autoTranslateReducer,
    emailRelay: emailRelayReducer,
    sinoClients: sinoClientsAccountsReducer,
    primaryChannels: primaryChannelsReducer,
    contactChannels: contactChannelsReducer,
    emailRelaySettings: getEmailRelayReducer,
    toggleMessageFavorite: toggleMessageFavoriteReducer,
    whatsappSelectedTemplate: whatsappTemplateSelectorReducer,
    conversationSettings: ConversationSettingsReducers,
    broadcastCancel: cancelConversationReducer,
    channelList: channelListReducer,
    channelListByType: channelListByTypeReducer,
    automationProperties: automationReducer,
    automationCriteria: automationCriteriaReducer,
    automationTriggers: automationTriggerReducer,
    automationActions: automationActionReducer,
    automationRules: automationRuleListReducer,
    automationCreateRule: createRuleReducer,
    changeRulesOrder: ChangeRuleExecutionOrderReducer,
    updateSelectedRule: updateAutomationReducer,
    automationPopup: automationPopupReducer,
    automationRuleStatus: updateRuleStatusReducer,
    statusTracker: automationStatusTrackerReducer,
    automationDeletePopup: automationDeletePopupReducer,
    automationSelectedTemplate: automationTemplateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types. for more details https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
        ignoredActions: ['notification/setNotification'],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
