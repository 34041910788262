import React from 'react'

import styles from './Loader.module.scss'

const Loader: React.FC = () => {
  return (
    <div className={styles.sggLoadingOverlayContainer}>
      <div className={styles.sggLoadingOverlay}>
        <div className={styles.sggLoadingOverlayWrapper}>
          <div className={styles.sggLoader}></div>
        </div>
      </div>
    </div>
  )
}

export default Loader
