import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestContactChannelList } from '../../../api/common'
import { Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { ChannelObject } from './primaryChannels.types'

interface ContactChannelsListState {
  status: 'idle' | 'loading' | 'success' | 'failed'
  contactChannelList: ChannelObject[]
}

const initialState: ContactChannelsListState = {
  status: 'idle',
  contactChannelList: [],
}

export const getContactChannelList = createAsyncThunk(
  'contactChannel/getAll',
  async (data: any, { rejectWithValue }) => {
    const response: Response = await requestContactChannelList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getContactChannelListSlice = createSlice({
  name: 'contactChannelList',
  initialState,
  reducers: {
    clearContactChannelList: (state) => {
      state.status = 'idle'
      state.contactChannelList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactChannelList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getContactChannelList.fulfilled, (state, action) => {
        state.status = 'success'
        state.contactChannelList = action.payload as ChannelObject[]
      })
      .addCase(getContactChannelList.rejected, (state) => {
        state.status = 'failed'
        state.contactChannelList = initialState.contactChannelList
      })
  },
})
export const { clearContactChannelList } = getContactChannelListSlice.actions
export const contactChannelListApiStatus = (state: RootState): string =>
  state.contactChannels.status
export const contactChannelListContent = (state: RootState): ChannelObject[] =>
  state.contactChannels.contactChannelList

export default getContactChannelListSlice.reducer
