import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ChannelListByTypeRequestProps, requestChannelListByType } from '../../../api/common'
import { Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { ChannelListState, ChannelObject } from './primaryChannels.types'

const initialState: ChannelListState = {
  status: 'idle',
  channelList: [],
}

export const getChannelListByType = createAsyncThunk(
  'channel/getByType',
  async (data: ChannelListByTypeRequestProps, { rejectWithValue }) => {
    const response: Response = await requestChannelListByType(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getChannelListByTypeSlice = createSlice({
  name: 'channelListByType',
  initialState,
  reducers: {
    clearChannelList: (state) => {
      state.status = 'idle'
      state.channelList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChannelListByType.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getChannelListByType.fulfilled, (state, action) => {
        state.status = 'success'
        state.channelList = action.payload as ChannelObject[]
      })
      .addCase(getChannelListByType.rejected, (state) => {
        state.status = 'failed'
        state.channelList = initialState.channelList
      })
  },
})
export const { clearChannelList } = getChannelListByTypeSlice.actions
export const channelListByTypeApiStatus = (state: RootState): string =>
  state.channelListByType.status
export const channelListByTypeContent = (state: RootState): ChannelObject[] =>
  state.channelListByType.channelList

export default getChannelListByTypeSlice.reducer
