import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from 'antd'

import LayoutFooter from './LayoutFooter'
import LayoutHeader from './LayoutHeader'
import LayoutBreadcrumb from './LayoutBreadcrumb'

import styles from './Layout.module.scss'
import { getPageConfig, Config } from '../../utils/pages.config'
import AxiosInterceptor from '../../modules/login/axios-interceptor'

interface LayoutProps {
  children?: JSX.Element[] | JSX.Element
  showBreadcrumb?: boolean
}

const LayoutWrapper: React.FC<LayoutProps> = ({ children, showBreadcrumb }) => {
  const location = useLocation()

  const [breadcrumb, setBreadcrumb] = useState<string[]>([])
  const [pageSetting, setPageSetting] = useState<Config>({})

  const path = location.pathname

  const token = localStorage.getItem('access_token') ?? ''

  // Set Token key to Axios
  AxiosInterceptor(token)

  useEffect(() => {
    // set breadcrumb pathnames using browser path
    const pathToArray = path.split('/')

    const pathNamesList: string[] = []

    pathToArray.forEach((element) => {
      if (element !== '') {
        pathNamesList.push(element)
      }
    })

    setBreadcrumb(pathNamesList)

    const pageKey = pathNamesList[pathNamesList.length - 1]
    const configs = getPageConfig(pageKey)

    setPageSetting(configs === undefined ? {} : configs)
  }, [path])

  return (
    <>
      <LayoutHeader />
      <Layout className={styles.layoutMinHeight}>
        {Object.keys(pageSetting).length > 0 && pageSetting.breadcrumb === true && (
          <LayoutBreadcrumb breadcrumb={breadcrumb} />
        )}
        <div
          className={`${path === '/app/channels' ? styles.contentHeight2 : styles.contentHeight}`}
        >
          {children}
        </div>

        <LayoutFooter />
      </Layout>
    </>
  )
}

export default LayoutWrapper
