import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export interface AutomationStatusChangeTrackerType {
  type: 'remove' | 'active' | 'pause' | null
}
const initialState: AutomationStatusChangeTrackerType = {
  type: null,
}

export const automationStatusTrackerSlice = createSlice({
  name: 'automationStatusTracker',
  initialState,
  reducers: {
    setStatusAutomationStatusChange: (state, action) => {
      state.type = action.payload
    },

    clearStatusAutomationStatusChange: (state) => {
      state.type = null
    },
  },
})
export const { setStatusAutomationStatusChange, clearStatusAutomationStatusChange } =
  automationStatusTrackerSlice.actions
export const statusTrackerStatus = (state: RootState): AutomationStatusChangeTrackerType =>
  state.statusTracker.type as unknown as AutomationStatusChangeTrackerType

export default automationStatusTrackerSlice.reducer
