import React, { useEffect } from 'react'
import { message } from 'antd'

import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  clearPopMessage,
  popMessage,
  popMessageType,
} from '../../redux/slice/pop-up-message/pop-message.slice'

const PopupMessage: React.FC = () => {
  const dispatch = useAppDispatch()
  const popupMessage = useAppSelector(popMessage)
  const type = useAppSelector(popMessageType)

  const [messageApi, contextHolder] = message.useMessage()

  const showMessage = (): void => {
    void messageApi.open({
      type,
      content: popupMessage,
    })

    dispatch(clearPopMessage())
  }

  useEffect(() => {
    if (popupMessage !== '') {
      showMessage()
    }
  }, [popupMessage])

  return <>{contextHolder}</>
}

export default PopupMessage
