import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { AutoTranslateRequestProps, saveMessageAutoTranslateLanguage } from '../../../api/common'
import { ApiCallStatus } from '../../../types/api-call-status.types'

const initialState: ApiCallStatus = {
  status: 'idle',
}

export const setAutoTranslate = createAsyncThunk(
  'language/setAutoTranslate',
  async (data: AutoTranslateRequestProps, { rejectWithValue }) => {
    let response: Response = {}

    response = await saveMessageAutoTranslateLanguage(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const autoTranslateSlice = createSlice({
  name: 'autoTranslate',
  initialState,
  reducers: {
    clearAutoTranslateState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAutoTranslate.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(setAutoTranslate.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(setAutoTranslate.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})

export const { clearAutoTranslateState } = autoTranslateSlice.actions
export const autoTranslateApiStatus = (state: RootState): string => state.autoTranslate.status

export default autoTranslateSlice.reducer
