import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Menu, MenuProps, Tooltip } from 'antd'
import SvgIconRenderer from '../svg-components/SvgIconRenderer'
import * as Authorities from '../../utils/authority-constants'
import BetaIcon from '../svg-components/BetaIcon'
import { hasPolicyAccess } from '../../utils/common-function'

const { useBreakpoint } = Grid

interface LeftMenuProps {
  onMouseHover?: () => void
  onMouseExit?: () => void
  onMenuItemClicked?: () => void
}

const LeftMenu: React.FC<LeftMenuProps> = ({ onMouseHover, onMouseExit, onMenuItemClicked }) => {
  const [current, setCurrent] = useState('engage')
  const seoBaseUrl = process.env.REACT_APP_API_SCP_SEO ?? ''
  const cmsBaseUrl = process.env.REACT_APP_API_SCP_CMS ?? ''
  const projectId = localStorage.getItem('projectId') ?? ''
  const websiteId = localStorage.getItem('websiteId') ?? ''
  const accountId = localStorage.getItem('accountId') ?? ''
  const isBaiduSetup = localStorage.getItem('isBaiduSetup') ?? ''
  const isWebsiteSetup = localStorage.getItem('isWebsiteSetup') ?? ''
  const isWeChatSetup = localStorage.getItem('isWeChatSetup') ?? ''
  const isChinaLocale = localStorage.getItem('isChinaLocalAvailable') ?? ''
  const setupIncompleteText = 'Please complete the setup to access the feature.'
  const featureNotAvailableText =
    'You do not have access to this feature as part of your subscription. Please contact us if you are interested in subscribing to this feature.'
  const comingSoon = 'Coming soon'

  const isWebsiteSetupCompleted = (): boolean => {
    return isWebsiteSetup === 'true'
  }

  const isWeChatSetupCompleted = (): boolean => {
    return isWeChatSetup === 'true'
  }

  const isBaiduSetupCompleted = (): boolean => {
    return isBaiduSetup === 'true'
  }

  const isChinaLocaleAvailable = (): boolean => {
    return isChinaLocale === 'true'
  }

  const generateTooltip = (text: string): ReactElement => {
    return (
      <Tooltip
        placement='right'
        title={text}
        showArrow={true}
        color={'#313541'}
        overlayStyle={{ fontSize: '12px', maxWidth: '175px' }}
      >
        <span className='menu-icon menu-lock-icon'></span>
      </Tooltip>
    )
  }

  const generateSetupIncompleteTooltip = (): ReactElement => {
    return (
      <Tooltip
        placement='right'
        title={setupIncompleteText}
        showArrow={true}
        color={'#313541'}
        overlayStyle={{ fontSize: '12px', maxWidth: '175px' }}
      >
        <span className='menu-icon menu-info-icon'></span>
      </Tooltip>
    )
  }

  const websiteAnalyticsCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL) ? (
        <>
          <span>Website Analytics</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        'Website Analytics'
      ),
      key: 'websiteAnalytics',
      popupClassName: isBaiduSetupCompleted()
        ? 'measure-submenu-level2B'
        : 'measure-submenu-level2',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure/website-analytics.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL),
      children: [
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL) ? (
            <a
              href={`${seoBaseUrl}analytics/audience?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Audience
            </a>
          ) : (
            <>
              <span>Audience</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'audience',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL),
        },
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL) ? (
            <a
              href={`${seoBaseUrl}analytics/acquisition?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Acquisition
            </a>
          ) : (
            <>
              <span>Acquisition</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'acquisition',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL),
        },
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL) ? (
            <a
              href={`${seoBaseUrl}analytics/behaviour?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Behaviour
            </a>
          ) : (
            <>
              <span>Behaviour</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'behaviour',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL),
        },
      ],
    },
  ]

  const websiteAnalyticsNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL) ? (
        <>
          <span>Website Analytics</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Website Analytics</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'websiteAnalytics',
      popupClassName: isBaiduSetupCompleted()
        ? 'measure-submenu-level2B'
        : 'measure-submenu-level2',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure/website-analytics.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const seoCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_DMR_ALL) ? (
        <>
          <span>SEO</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        'SEO'
      ),
      key: 'seo',
      popupClassName: isBaiduSetupCompleted()
        ? 'measure-submenu-level2B'
        : 'measure-submenu-level2',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure/seo.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WEB_DMR_ALL),
      children: [
        // {
        //   label: hasPolicyAccess(Authorities.SCP_WEB_DMR_ALL) ? (
        //     <a
        //       href={`${seoBaseUrl}measure/ranking?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
        //     >
        //       Search Ranking
        //     </a>
        //   ) : (
        //     <>
        //       <span>Search Ranking</span>
        //       {generateTooltip(featureNotAvailableText)}
        //     </>
        //   ),
        //   key: 'searchRanking',
        //   disabled: !hasPolicyAccess(Authorities.SCP_WEB_DMR_ALL),
        // },
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_DMR_ALL) ? (
            <a
              href={`${cmsBaseUrl}measure/keywords?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Keywords
            </a>
          ) : (
            <>
              <span>Keywords</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'keywords',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_DMR_ALL),
        },
        {
          label: (
            <a
              href={`${seoBaseUrl}measure/competitors?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Competitors
            </a>
          ),
          key: 'competitors',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_SEO_ALL),
        },
        hasPolicyAccess(Authorities.SCP_WEB_SEO_DMR_ALL) && {
          label: hasPolicyAccess(Authorities.SCP_WEB_SEO_DMR_ALL) ? (
            <a
              href={`${cmsBaseUrl}filemanager/seoReports?sortBy=name&sortOrder=1&searchText=&page=1&pagination=0&projectId=${projectId}&accountId=${accountId}&cWebsiteId=${websiteId}`}
            >
              Reports
            </a>
          ) : (
            <>
              <span>Reports</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'reports',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_SEO_DMR_ALL),
        },
      ],
    },
  ]

  const seoNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL) ? (
        <>
          <span>SEO</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>SEO</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'seo',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure/seo.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const websiteContentCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL) ? (
        <>
          <span>Website Content</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        'Website Content'
      ),
      key: 'websiteContent',
      popupClassName: 'publish-submenu-level2',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/website-content.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL),
      children: [
        {
          label: hasPolicyAccess([
            Authorities.SCP_WEB_CONTENT_FULL_ACCESS,
            Authorities.SCP_WEB_CONTENT_CHANGE,
          ]) ? (
            <a
              href={`${cmsBaseUrl}create/pages?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Create
            </a>
          ) : (
            <>
              <span>Create</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'createWebsiteContent',
          disabled: !hasPolicyAccess([
            Authorities.SCP_WEB_CONTENT_FULL_ACCESS,
            Authorities.SCP_WEB_CONTENT_CHANGE,
          ]),
        },
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL) ? (
            <a
              href={`${cmsBaseUrl}manage/pages?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Manage
            </a>
          ) : (
            <>
              <span>Manage</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'manageWebsiteContent',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL),
        },
        {
          label: !hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL) ? (
            <>
              <span>Settings</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ) : (
            'Settings'
          ),
          key: 'settingsWebsiteContent',
          popupClassName: 'publish-submenu-level3',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL),
          children: [
            {
              label: hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL) ? (
                <a
                  href={`${cmsBaseUrl}create/content-settings?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
                >
                  General
                </a>
              ) : (
                <>
                  <span>General</span>
                  {generateTooltip(featureNotAvailableText)}
                </>
              ),
              key: 'general',
              disabled: !hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL),
            },
            {
              label: hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL) ? (
                <a
                  href={`${cmsBaseUrl}create/content-domain-settings?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
                >
                  Domain & Hosting
                </a>
              ) : (
                <>
                  <span>Domain & Hosting</span>
                  {generateTooltip(featureNotAvailableText)}
                </>
              ),
              key: 'domainHosting',
              disabled: !hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL),
            },
            {
              label: hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL) ? (
                <a
                  href={`${cmsBaseUrl}create/url-mapping?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
                >
                  URL Mapping
                </a>
              ) : (
                <>
                  <span>URL Mapping</span>
                  {generateTooltip(featureNotAvailableText)}
                </>
              ),
              key: 'urlMapping',
              disabled: !hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL),
            },
            {
              label: hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL) ? (
                <a
                  href={`${cmsBaseUrl}create/style-editor?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
                >
                  Style Editor
                </a>
              ) : (
                <>
                  <span>Style Editor</span>
                  {generateTooltip(featureNotAvailableText)}
                </>
              ),
              key: 'styleEditor',
              disabled: !hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL),
            },
            {
              label: hasPolicyAccess(Authorities.SCP_WEB_SETTINGS_ALL) ? (
                <a
                  href={`${cmsBaseUrl}cookie-consent-settings?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
                >
                  Cookie Consent
                </a>
              ) : (
                <>
                  <span>Cookie Consent</span>
                  {generateTooltip(featureNotAvailableText)}
                </>
              ),
              key: 'cookieConsent',
              disabled: !hasPolicyAccess(Authorities.SCP_COOKIE_CONSENT_ACCESS_ALL),
            },
          ],
        },
      ],
    },
  ]

  const websiteContentNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_CONTENT_ALL) ? (
        <>
          <span>Website Content</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <a href={`${seoBaseUrl}self-start?accountId=${accountId}`}>Website Content</a>{' '}
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'websiteContent',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/website-content.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const weChatContentCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WECHAT_CONTENT_ALL) ? (
        <>
          <span>WeChat Content</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        'WeChat Content'
      ),
      key: 'weChatContent',
      popupClassName: 'publish-submenu-level2',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/wechat-content.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_CONTENT_ALL),
      children: [
        {
          label: hasPolicyAccess([
            Authorities.SCP_WECHAT_CONTENT_FULL_ACCESS,
            Authorities.SCP_WECHAT_CONTENT_CHANGE,
          ]) ? (
            <a
              href={`${cmsBaseUrl}wechat/content?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Create
            </a>
          ) : (
            <>
              <span>Create</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'createWeChatContent',
          disabled: !hasPolicyAccess([
            Authorities.SCP_WECHAT_CONTENT_FULL_ACCESS,
            Authorities.SCP_WECHAT_CONTENT_CHANGE,
          ]),
        },
        {
          label: hasPolicyAccess(Authorities.SCP_WECHAT_CONTENT_ALL) ? (
            <a
              href={`${cmsBaseUrl}wechat/content?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Manage
            </a>
          ) : (
            <>
              <span>Manage</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'manageWeChatContent',
          disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_CONTENT_ALL),
        },
        {
          label: !hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL) ? (
            <>
              <span>Settings</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ) : (
            'Settings'
          ),
          key: 'settingsWeChatContent',
          popupClassName: 'publish-submenu-level3',
          disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL),
          children: [
            {
              label: hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL) ? (
                <a
                  href={`${cmsBaseUrl}wechat/account-info?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
                >
                  Account
                </a>
              ) : (
                <>
                  <span>Account</span>
                  {generateTooltip(featureNotAvailableText)}
                </>
              ),
              key: 'accountWeChatSettings',
              disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL),
            },
            {
              label: hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL) ? (
                <a
                  href={`${cmsBaseUrl}wechat/user-menu?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
                >
                  Menu
                </a>
              ) : (
                <>
                  <span>Menu</span>
                  {generateTooltip(featureNotAvailableText)}
                </>
              ),
              key: 'menuWeChatSettings',
              disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL),
            },
          ],
        },
      ],
    },
  ]

  const weChatContentNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WECHAT_CONTENT_ALL) ? (
        <>
          <span>WeChat Content</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <a href={`${seoBaseUrl}self-start?accountId=${accountId}`}>WeChat Content</a>{' '}
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'weChatContent',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/wechat-content.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const dataNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_DATA_ALL) ? (
        <>
          <span>Data</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Data</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'data',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/data.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const dataCompletedMenu = [
    {
      label: hasPolicyAccess(Authorities.SCP_WEB_DATA_ALL) ? (
        <a
          href={`${cmsBaseUrl}data?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
        >
          Data
        </a>
      ) : (
        <>
          <span>Data</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ),
      key: 'data',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/data.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WEB_DATA_ALL),
    },
  ]

  const eventNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_EVENT_ALL) ? (
        <>
          <span>Events</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Event</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'eventsPublish',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/events.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const eventCompletedMenu = [
    {
      label: hasPolicyAccess(Authorities.SCP_EVENT_ALL) ? (
        <a
          href={`${cmsBaseUrl}events?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
        >
          Events
        </a>
      ) : (
        <>
          <span>Events</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ),
      key: 'eventsPublish',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/events.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_EVENT_ALL),
    },
  ]

  const webFilesNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_FILES_ALL) ? (
        <>
          <span>Website Files</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Website Files</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'websiteFiles',
      disabled: true,
    },
  ]

  const webFilesCompletedMenu = [
    {
      label: hasPolicyAccess(Authorities.SCP_WEB_FILES_ALL) ? (
        <a
          href={`${cmsBaseUrl}filemanager?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
        >
          Website Files
        </a>
      ) : (
        <>
          <span>Website Files</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ),
      key: 'websiteFiles',
      disabled: !hasPolicyAccess(Authorities.SCP_WEB_FILES_ALL),
    },
  ]

  const weChatFilesNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WECHAT_FILES_ALL) ? (
        <>
          <span>WeChat Files</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>WeChat Files</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'weChatFiles',
      disabled: true,
    },
  ]

  const weChatFilesCompletedMenu = [
    {
      label: hasPolicyAccess(Authorities.SCP_WECHAT_FILES_ALL) ? (
        <a
          href={`${cmsBaseUrl}wechat/assets-library?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
        >
          WeChat Files
        </a>
      ) : (
        <>
          <span>WeChat Files</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ),
      key: 'weChatFiles',
      disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_FILES_ALL),
    },
  ]

  const autoResponseNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL) ? (
        <>
          <span>WeChat Auto Response</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>WeChat Auto Response</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'autoResponseWeChat',
      disabled: true,
    },
  ]

  const autoResponseCompletedMenu = [
    {
      label: hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL) ? (
        <a
          href={`${cmsBaseUrl}wechat/auto-response?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
        >
          WeChat Auto Response
        </a>
      ) : (
        <>
          <span>WeChat Auto Response</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ),
      key: 'autoResponseWeChat',
      disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_SETTINGS_ALL),
    },
  ]

  const formsNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_FORMS_ALL) ? (
        <>
          <span>Forms</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Forms</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'forms',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/forms.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const formsCompletedMenu = [
    {
      label: hasPolicyAccess(Authorities.SCP_WEB_FORMS_ALL) ? (
        <a
          href={`${cmsBaseUrl}forms?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
        >
          Forms
        </a>
      ) : (
        <>
          <span>Forms</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ),
      key: 'forms',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/forms.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WEB_FORMS_ALL),
    },
  ]

  const liveChatNotCompletedMenu = [
    {
      label: !hasPolicyAccess(
        Authorities.SCP_WEB_CHAT_ALL.concat(Authorities.SCP_WEB_CHAT_SETTINGS_ALL),
      ) ? (
        <>
          <span>Live Chat</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Live Chat</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'liveChat',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/live-chat.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const liveChatCompletedMenu = [
    {
      label: !hasPolicyAccess(
        Authorities.SCP_WEB_CHAT_ALL.concat(Authorities.SCP_WEB_CHAT_SETTINGS_ALL),
      ) ? (
        <>
          <span>Live Chat</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        'Live Chat'
      ),
      key: 'liveChat',
      popupClassName: 'engage-submenu-level2',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/live-chat.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(
        Authorities.SCP_WEB_CHAT_ALL.concat(Authorities.SCP_WEB_CHAT_SETTINGS_ALL),
      ),
      children: [
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_CHAT_ALL) ? (
            <a
              href={`${cmsBaseUrl}livechat/messages?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Messages
            </a>
          ) : (
            <>
              <span>Messages</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'messagesLiveChat',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_CHAT_ALL),
        },
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_CHAT_ALL) ? (
            <a
              href={`${cmsBaseUrl}livechat/contacts?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Contacts
            </a>
          ) : (
            <>
              <span>Contacts</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'contactsLiveChat',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_CHAT_ALL),
        },
        {
          label: hasPolicyAccess(
            Authorities.SCP_WEB_CHAT_ALL.concat(Authorities.SCP_WEB_CHAT_SETTINGS_ALL),
          ) ? (
            <a
              href={`${cmsBaseUrl}livechat/settings?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Settings
            </a>
          ) : (
            <>
              <span>Settings</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'settingsLiveChat',
          disabled: !hasPolicyAccess(
            Authorities.SCP_WEB_CHAT_ALL.concat(Authorities.SCP_WEB_CHAT_SETTINGS_ALL),
          ),
        },
      ],
    },
  ]

  const optimiseNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL) ? (
        <>
          <span>Optimise</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Optimise</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'optimise',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure/optimise.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const optimiseCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL) ? (
        <>
          <span>Optimise</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        'Optimise'
      ),
      key: 'optimise',
      popupClassName: isBaiduSetupCompleted()
        ? 'measure-submenu-level2B'
        : 'measure-submenu-level2',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure/optimise.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL),
      children: [
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL) ? (
            <a
              href={`${seoBaseUrl}optimisation/audit?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Site Audit
            </a>
          ) : (
            <>
              <span>Site Audit</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'siteAudit',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL),
        },
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL) ? (
            <a
              href={`${seoBaseUrl}optimisation/audit/issues?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Issues
            </a>
          ) : (
            <>
              <span>Issues</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'issues',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL),
        },
        {
          label: hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL) ? (
            <a
              href={`${seoBaseUrl}optimisation/page-audit?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Page Audit
            </a>
          ) : (
            <>
              <span>Page Audit</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'pageAudit',
          disabled: !hasPolicyAccess(Authorities.SCP_WEB_OPTIMISE_ALL),
        },
      ],
    },
  ]

  const socialAnalyticsNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WECHAT_ANALYTICS_ALL) ? (
        <>
          <span>Social Analytics</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Social Analytics</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'socialAnalytics',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure/socal-analytics.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const socialAnalyticsCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WECHAT_ANALYTICS_ALL) ? (
        <>
          <span>Social Analytics</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        'Social Analytics'
      ),
      key: 'socialAnalytics',
      popupClassName: isBaiduSetupCompleted()
        ? 'measure-submenu-level2B'
        : 'measure-submenu-level2',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure/socal-analytics.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_ANALYTICS_ALL),
      children: [
        {
          label: hasPolicyAccess(Authorities.SCP_WECHAT_ANALYTICS_ALL) ? (
            <a
              href={`${seoBaseUrl}measure/follower-analytics?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Followers
            </a>
          ) : (
            <>
              <span>Followers</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'followers',
          disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_ANALYTICS_ALL),
        },
        {
          label: hasPolicyAccess(Authorities.SCP_WECHAT_ANALYTICS_ALL) ? (
            <a
              href={`${seoBaseUrl}measure/analytics?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
            >
              Content
            </a>
          ) : (
            <>
              <span>Content</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ),
          key: 'content',
          disabled: !hasPolicyAccess(Authorities.SCP_WECHAT_ANALYTICS_ALL),
        },
      ],
    },
  ]

  const dashboardNotCompletedMenu = [
    {
      label: !hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL) ? (
        <>
          <span>Dashboard</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ) : (
        <>
          <span>Dashboard</span>
          {generateSetupIncompleteTooltip()}
        </>
      ),
      key: 'dashboard',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/dashboard.svg'
          width='20px'
        />
      ),
      disabled: true,
    },
  ]

  const dashboardCompletedMenu = [
    {
      label: hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL) ? (
        <a
          href={`${seoBaseUrl}dashboard?projectId=${projectId}&cWebsiteId=${websiteId}&accountId=${accountId}`}
        >
          Dashboard
        </a>
      ) : (
        <>
          <span>Dashboard</span>
          {generateTooltip(featureNotAvailableText)}
        </>
      ),
      key: 'dashboard',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/dashboard.svg'
          width='20px'
        />
      ),
      disabled: !hasPolicyAccess(Authorities.SCP_WEB_ANALYTICS_ALL),
    },
  ]

  const onClick: MenuProps['onClick'] = (e): void => {
    setCurrent(e.key)

    if (e.key === 'engage' || e.key === 'messages') {
      if (onMenuItemClicked !== undefined && onMenuItemClicked !== null) {
        onMenuItemClicked()
      }
    }
  }

  const hoveringMenu = (): void => {
    if (onMouseHover !== undefined && onMouseHover !== null) {
      onMouseHover()
      document.body.style.overflow = 'hidden'
    }
  }

  const notHoveringMenu = (): void => {
    if (onMouseExit !== undefined && onMouseExit !== null) {
      onMouseExit()
      document.body.style.overflow = ''
    }
  }

  const { lg } = useBreakpoint()
  const items = [
    {
      label: 'Publish',
      key: 'publish',
      popupClassName: 'publish-submenu-level1',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish.svg'
          height='24px'
        />
      ),
      children: [
        {
          type: 'group',
          label: (
            <>
              <SvgIconRenderer
                url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/assets.svg'
                width='24px'
              />
              <span>Assets</span>
            </>
          ),
          key: 'assets',
          children: [
            ...(isWebsiteSetupCompleted()
              ? websiteContentCompletedMenu
              : websiteContentNotCompletedMenu),
            ...(isWeChatSetupCompleted()
              ? weChatContentCompletedMenu
              : weChatContentNotCompletedMenu),
          ],
        },
        {
          label: !hasPolicyAccess(
            Authorities.SCP_WEB_FILES_ALL.concat(Authorities.SCP_WECHAT_FILES_ALL),
          ) ? (
            <>
              <span>Files</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ) : (
            'Files'
          ),
          key: 'files',
          popupClassName: 'publish-submenu-level2',
          icon: (
            <SvgIconRenderer
              url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/publish/files.svg'
              width='20px'
            />
          ),
          disabled: !hasPolicyAccess(
            Authorities.SCP_WEB_FILES_ALL.concat(Authorities.SCP_WECHAT_FILES_ALL),
          ),
          children: [
            ...(isWebsiteSetupCompleted() ? webFilesCompletedMenu : webFilesNotCompletedMenu),
            ...(isWeChatSetupCompleted() ? weChatFilesCompletedMenu : weChatFilesNotCompletedMenu),
          ],
        },
        ...(isWebsiteSetupCompleted() ? dataCompletedMenu : dataNotCompletedMenu),
        ...(isWebsiteSetupCompleted() ? eventCompletedMenu : eventNotCompletedMenu),
      ],
    },
    {
      label: 'Engage',
      key: 'engageSection',
      popupClassName: 'engage-submenu-level1',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage.svg'
          height='24px'
        />
      ),
      children: [
        {
          label: !hasPolicyAccess(Authorities.SBF_CONTACTS_ACCESS_ALL) ? (
            <>
              <span>Contacts</span>
              <span className='beta-icon'>
                <BetaIcon />
              </span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ) : (
            <Link to='/app/engage'>
              Contacts
              <span className='beta-icon'>
                <BetaIcon />
              </span>
            </Link>
          ),
          key: 'engage',
          icon: (
            <SvgIconRenderer
              url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/contacts.svg'
              width='20px'
            />
          ),
          disabled: !hasPolicyAccess(Authorities.SBF_CONTACTS_ACCESS_ALL),
        },
        {
          label: !hasPolicyAccess(Authorities.SBF_CONVERSATION_ACCESS_ALL) ? (
            <>
              <span>Conversations</span>
              <span className='beta-icon'>
                <BetaIcon />
              </span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ) : (
            <Link to='/app/messages'>
              Conversations
              <span className='beta-icon'>
                <BetaIcon />
              </span>
            </Link>
          ),
          key: 'messages',
          icon: (
            <SvgIconRenderer
              url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/messages.svg'
              width='20px'
            />
          ),
          disabled: !hasPolicyAccess(Authorities.SBF_CONVERSATION_ACCESS_ALL),
        },
        {
          label: (
            <>
              <span>Campaigns</span>
              {generateTooltip(comingSoon)}
            </>
          ),
          key: 'campaigns',
          icon: (
            <SvgIconRenderer
              url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/campaigns.svg'
              width='20px'
            />
          ),
          disabled: true,
        },
        {
          label: !hasPolicyAccess([
            ...Authorities.SCP_WECHAT_SETTINGS_ALL,
            ...Authorities.SBF_AUTOMATIONS_ACCESS_ALL,
          ]) ? (
            <>
              <span>Automations</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ) : (
            'Automations'
          ),
          key: 'automations',
          popupClassName: 'engage-submenu-level2',
          icon: (
            <SvgIconRenderer
              url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/automations.svg'
              width='20px'
            />
          ),
          disabled: !hasPolicyAccess([
            ...Authorities.SCP_WECHAT_SETTINGS_ALL,
            ...Authorities.SBF_AUTOMATIONS_ACCESS_ALL,
          ]),
          children: [
            ...(isWeChatSetupCompleted()
              ? autoResponseCompletedMenu
              : autoResponseNotCompletedMenu),
            {
              label: !hasPolicyAccess(Authorities.SBF_AUTOMATIONS_ACCESS_ALL) ? (
                <>
                  <span>Automation Rule List</span>
                  <span className='beta-icon'>
                    <BetaIcon />
                  </span>
                  {generateTooltip(featureNotAvailableText)}
                </>
              ) : (
                <Link to='/app/automations'>
                  Automation Rule List
                  <span className='beta-icon'>
                    <BetaIcon />
                  </span>
                </Link>
              ),
              key: 'automationRuleList',
              disabled: !hasPolicyAccess(Authorities.SBF_AUTOMATIONS_ACCESS_ALL),
            },
          ],
        },
        {
          label: !hasPolicyAccess(Authorities.SBF_CHANNELS_ACCESS_ALL) ? (
            <>
              <span>Channels</span>
              {generateTooltip(featureNotAvailableText)}
            </>
          ) : (
            <Link to='/app/channels'>
              Channels
              <span className='beta-icon'>
                <BetaIcon />
              </span>
            </Link>
          ),
          key: 'channels',
          icon: (
            <SvgIconRenderer
              url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/engage/channels.svg'
              width='20px'
            />
          ),
          disabled: !hasPolicyAccess(Authorities.SBF_CHANNELS_ACCESS_ALL),
        },
        ...(isWebsiteSetupCompleted() ? formsCompletedMenu : formsNotCompletedMenu),
        ...(isWebsiteSetupCompleted() ? liveChatCompletedMenu : liveChatNotCompletedMenu),
      ],
    },
    {
      label: 'Measure',
      key: 'measure',
      popupClassName: 'measure-submenu-level1 min-height-menu',
      icon: (
        <SvgIconRenderer
          url='https://sinorbis-production-qa.oss-ap-southeast-1.aliyuncs.com/nav-menu-icons/measure.svg'
          height='24px'
        />
      ),
      children: [
        ...(isChinaLocaleAvailable()
          ? isWebsiteSetupCompleted()
            ? dashboardCompletedMenu
            : dashboardNotCompletedMenu
          : []),

        ...(isBaiduSetupCompleted()
          ? isWebsiteSetupCompleted()
            ? websiteAnalyticsCompletedMenu
            : websiteAnalyticsNotCompletedMenu
          : []),

        ...(isChinaLocaleAvailable()
          ? isWebsiteSetupCompleted()
            ? seoCompletedMenu
            : seoNotCompletedMenu
          : []),

        ...(isWebsiteSetupCompleted() ? optimiseCompletedMenu : optimiseNotCompletedMenu),
        ...(isWeChatSetupCompleted()
          ? socialAnalyticsCompletedMenu
          : socialAnalyticsNotCompletedMenu),
      ],
    },
  ]

  return (
    <Menu
      aria-hidden={false}
      tabIndex={-1}
      onMouseOver={hoveringMenu}
      onMouseLeave={notHoveringMenu}
      theme='light'
      className='leftMenuItem'
      onClick={onClick}
      selectedKeys={[current]}
      mode={lg === true ? 'horizontal' : 'inline'}
      items={items}
    />
  )
}

export default LeftMenu
