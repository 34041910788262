import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { EmailRelaySettingsPropsType, emailRelayConfigurationSetup } from '../../../api/common'
import { ApiCallStatus } from '../../../types/api-call-status.types'

const initialState: ApiCallStatus = {
  status: 'idle',
}

export const messageReplyViaEmail = createAsyncThunk(
  'emailRelay/replyViaEmail',
  async (data: EmailRelaySettingsPropsType, { rejectWithValue }) => {
    let response: Response = {}

    response = await emailRelayConfigurationSetup(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const emailRelaySlice = createSlice({
  name: 'replyViaEmail',
  initialState,
  reducers: {
    clearEmailRelaySliceState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(messageReplyViaEmail.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(messageReplyViaEmail.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(messageReplyViaEmail.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})

export const { clearEmailRelaySliceState } = emailRelaySlice.actions
export const saveEmailRelaySettingsApiStatus = (state: RootState): string => state.emailRelay.status

export default emailRelaySlice.reducer
