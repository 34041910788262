import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sortContactList } from '../../../modules/contacts/contacts-api'
import { ErrorMessageState, Response, SortState } from '../../../types/common.types'
import { RootState } from '../../store'
import { ContactListResultTypes, ContactsListState, ResponseError } from './contacts.types'

const initialState: ContactsListState = {
  status: 'idle',
  contactList: {},
  error: {},
}

export const getContactList = createAsyncThunk(
  'contactList/all',
  async (data: SortState, { rejectWithValue }) => {
    let response: Response = {}
    response = await sortContactList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const contactListSlice = createSlice({
  name: 'contactsList',
  initialState,
  reducers: {
    clearContactList: (state) => {
      state = { status: 'idle', contactList: {}, error: null }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getContactList.fulfilled, (state, action) => {
        state.status = 'success'
        state.contactList = action.payload as object
      })
      .addCase(getContactList.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        if (errorFromPayload.contacts !== undefined) {
          errorMessage.errorType = errorFromPayload.contacts
        }

        state.status = 'failed'
        state.error = errorMessage
        state.contactList = initialState.contactList
      })
  },
})
export const { clearContactList } = contactListSlice.actions
export const contactsListApiStatus = (state: RootState): string => state.contactList.status
export const contactListResult = (state: RootState): ContactListResultTypes =>
  state.contactList.contactList as ContactListResultTypes
export const contactsListError = (state: RootState): any => state.contactList.error

export default contactListSlice.reducer
