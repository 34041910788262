import React, { useEffect } from 'react'
import { Button, notification, Space } from 'antd'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  clearNotification,
  notificationCustomAction,
  notificationDescription,
  notificationMessage,
  notificationType,
} from '../../redux/slice/notification/notification.slice'

interface NotificationProps {
  label?: string
}

interface Config {
  key?: string
  btn?: React.ReactElement
  description?: string
  message?: string
}

const Notification: React.FC<{}> = (props: NotificationProps) => {
  const dispatch = useAppDispatch()
  const message = useAppSelector(notificationMessage)
  const type = useAppSelector(notificationType)
  const actionButtons = useAppSelector(notificationCustomAction)
  const description = useAppSelector(notificationDescription)

  const [api, contextHolder]: any = notification.useNotification()

  const openNotification = (): void => {
    const config: Config = {
      key: `open${Date.now()}`,
      description,
      message,
    }

    if (Object.keys(actionButtons).length > 0) {
      config.btn = (
        <Space>
          <Button
            type={actionButtons.cancelButton?.type}
            size={actionButtons.cancelButton?.size}
            onClick={() => {
              api.destroy()
              actionButtons?.cancelButton?.onClick?.()
            }}
          >
            {actionButtons.cancelButton?.label}
          </Button>
          <Button
            type={actionButtons.confirmButton?.type}
            size={actionButtons.confirmButton?.size}
            onClick={() => {
              api.destroy()
              actionButtons.confirmButton?.onClick?.()
            }}
          >
            {actionButtons.confirmButton?.label}
          </Button>
        </Space>
      )
    }

    api[type]({ ...config })
  }

  useEffect(() => {
    // Check stored message validity
    if (message !== '') {
      openNotification()
    }

    // Clear the Message when after displaying it
    dispatch(clearNotification())
  }, [message])

  return <>{contextHolder}</>
}

export default Notification
