import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { TemplateListPropsType } from '../../../components/new-conversation-wizard/CustomizeTemplate.types'

export interface DroppedItemsType {
  name: string
  value: string
}

interface AutomationTemplateSettingsType {
  selectedTemplate: TemplateListPropsType | null
  variablesValue: DroppedItemsType[] | []
}

const initialState: AutomationTemplateSettingsType = {
  selectedTemplate: null,
  variablesValue: [],
}

export const automationTemplateSlice = createSlice({
  name: 'automationTemplateSettings',
  initialState,
  reducers: {
    setAutomationTemplateSettings: (state, action) => {
      state.selectedTemplate = action.payload
    },
    setAutomationTemplateVariables: (state, action) => {
      state.variablesValue = action.payload
    },

    clearAutomationTemplateSettings: (state) => {
      state.selectedTemplate = null
      state.variablesValue = []
    },
  },
})
export const {
  clearAutomationTemplateSettings,
  setAutomationTemplateSettings,
  setAutomationTemplateVariables,
} = automationTemplateSlice.actions
export const selectedAutomationTemplate = (state: RootState): TemplateListPropsType | null =>
  state.automationSelectedTemplate.selectedTemplate
export const selectedAutomationTemplateValues = (state: RootState): DroppedItemsType[] | [] =>
  state.automationSelectedTemplate.variablesValue

export default automationTemplateSlice.reducer
