import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestLoggedInUserInfo } from '../../../api/common'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { UserInfoState, ResponseError, UserDetails } from './user.types'

const initialState: UserInfoState = {
  status: 'idle',
  userDetails: {
    id: '',
    firstName: '',
    lastName: '',
    profileImageUrl: null,
    email: '',
  },
  error: {},
}

export const getUserDetails = createAsyncThunk(
  'userDetails',
  async (userId: string, { rejectWithValue }) => {
    const response: Response = await requestLoggedInUserInfo(userId)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    clearUserDetails: (state) => {
      state = {
        status: 'idle',
        userDetails: {
          id: '',
          firstName: '',
          lastName: '',
          profileImageUrl: null,
          email: '',
        },
        error: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = 'success'
        state.userDetails = action.payload as UserDetails
      })
      .addCase(getUserDetails.rejected, (state, action: any) => {
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        state.status = 'failed'
        state.error = errorMessage
        state.userDetails = initialState.userDetails
      })
  },
})
export const { clearUserDetails } = userDetailsSlice.actions
export const userDetailsApiStatus = (state: RootState): string => state.userDetails.status
export const userDetailsResult = (state: RootState): UserDetails =>
  state.userDetails.userDetails as unknown as UserDetails
export const userDetailsError = (state: RootState): any => state.userDetails.error

export default userDetailsSlice.reducer
