// This file has all the constants related to policies

// Web Content Policies
export const SCP_WEB_CONTENT_FULL_ACCESS = 'scp-web-con-fa'
export const SCP_WEB_CONTENT_READ_ONLY = 'scp-web-con-ro'
export const SCP_WEB_CONTENT_DELETE = 'scp-web-con-dl'
export const SCP_WEB_CONTENT_CHANGE = 'scp-web-con-ch'
export const SCP_WEB_CONTENT_ALL = [
  SCP_WEB_CONTENT_FULL_ACCESS,
  SCP_WEB_CONTENT_READ_ONLY,
  SCP_WEB_CONTENT_DELETE,
  SCP_WEB_CONTENT_CHANGE,
]

// Web Files Policies
export const SCP_WEB_FILES_FULL_ACCESS = 'scp-web-fil-fa'
export const SCP_WEB_FILES_READ_ONLY = 'scp-web-fil-ro'
export const SCP_WEB_FILES_DELETE = 'scp-web-fil-dl'
export const SCP_WEB_FILES_CHANGE = 'scp-web-fil-ch'
export const SCP_WEB_FILES_ALL = [
  SCP_WEB_FILES_FULL_ACCESS,
  SCP_WEB_FILES_READ_ONLY,
  SCP_WEB_FILES_DELETE,
  SCP_WEB_FILES_CHANGE,
]

// Web Forms Policies
export const SCP_WEB_FORMS_FULL_ACCESS = 'scp-web-for-fa'
export const SCP_WEB_FORMS_READ_ONLY = 'scp-web-for-ro'
export const SCP_WEB_FORMS_DELETE = 'scp-web-for-dl'
export const SCP_WEB_FORMS_CHANGE = 'scp-web-for-ch'
export const SCP_WEB_FORMS_ALL = [
  SCP_WEB_FORMS_FULL_ACCESS,
  SCP_WEB_FORMS_READ_ONLY,
  SCP_WEB_FORMS_DELETE,
  SCP_WEB_FORMS_CHANGE,
]

// Web Data Policies
export const SCP_WEB_DATA_FULL_ACCESS = 'scp-web-dat-fa'
export const SCP_WEB_DATA_READ_ONLY = 'scp-web-dat-ro'
export const SCP_WEB_DATA_DELETE = 'scp-web-dat-dl'
export const SCP_WEB_DATA_CHANGE = 'scp-web-dat-ch'
export const SCP_WEB_DATA_ALL = [
  SCP_WEB_DATA_FULL_ACCESS,
  SCP_WEB_DATA_READ_ONLY,
  SCP_WEB_DATA_DELETE,
  SCP_WEB_DATA_CHANGE,
]

// Web Chat Policies (Live Chat)
export const SCP_WEB_CHAT_FULL_ACCESS = 'scp-web-cht-fa'
export const SCP_WEB_CHAT_READ_ONLY = 'scp-web-cht-ro'
export const SCP_WEB_CHAT_DELETE = 'scp-web-cht-dl'
export const SCP_WEB_CHAT_CHANGE = 'scp-web-cht-ch'
export const SCP_WEB_CHAT_ALL = [
  SCP_WEB_CHAT_FULL_ACCESS,
  SCP_WEB_CHAT_READ_ONLY,
  SCP_WEB_CHAT_DELETE,
  SCP_WEB_CHAT_CHANGE,
]

// Web Chat Settings Policies (Live Chat)
export const SCP_WEB_CHAT_SETTINGS_FULL_ACCESS = 'scp-cht-set-fa'
export const SCP_WEB_CHAT_SETTINGS_READ_ONLY = 'scp-cht-set-ro'
export const SCP_WEB_CHAT_SETTINGS_DELETE = 'scp-cht-set-dl'
export const SCP_WEB_CHAT_SETTINGS_CHANGE = 'scp-cht-set-ch'
export const SCP_WEB_CHAT_SETTINGS_ALL = [
  SCP_WEB_CHAT_SETTINGS_FULL_ACCESS,
  SCP_WEB_CHAT_SETTINGS_READ_ONLY,
  SCP_WEB_CHAT_SETTINGS_DELETE,
  SCP_WEB_CHAT_SETTINGS_CHANGE,
]

// Web Analytics Policies
export const SCP_WEB_ANALYTICS_FULL_ACCESS = 'scp-web-anl-fa'
export const SCP_WEB_ANALYTICS_READ_ONLY = 'scp-web-anl-ro'
export const SCP_WEB_ANALYTICS_DELETE = 'scp-web-anl-dl'
export const SCP_WEB_ANALYTICS_CHANGE = 'scp-web-anl-ch'
export const SCP_WEB_ANALYTICS_ALL = [
  SCP_WEB_ANALYTICS_FULL_ACCESS,
  SCP_WEB_ANALYTICS_READ_ONLY,
  SCP_WEB_ANALYTICS_DELETE,
  SCP_WEB_ANALYTICS_CHANGE,
]

// Web Settings Policies
export const SCP_WEB_SETTINGS_FULL_ACCESS = 'scp-web-set-fa'
export const SCP_WEB_SETTINGS_READ_ONLY = 'scp-web-set-ro'
export const SCP_WEB_SETTINGS_DELETE = 'scp-web-set-dl'
export const SCP_WEB_SETTINGS_CHANGE = 'scp-web-set-ch'
export const SCP_WEB_SETTINGS_ALL = [
  SCP_WEB_SETTINGS_FULL_ACCESS,
  SCP_WEB_SETTINGS_READ_ONLY,
  SCP_WEB_SETTINGS_DELETE,
  SCP_WEB_SETTINGS_CHANGE,
]

// Web SEO Policies
export const SCP_WEB_SEO_FULL_ACCESS = 'scp-web-seo-fa'
export const SCP_WEB_SEO_READ_ONLY = 'scp-web-seo-ro'
export const SCP_WEB_SEO_DELETE = 'scp-web-seo-dl'
export const SCP_WEB_SEO_CHANGE = 'scp-web-seo-ch'
export const SCP_WEB_SEO_ALL = [
  SCP_WEB_SEO_FULL_ACCESS,
  SCP_WEB_SEO_READ_ONLY,
  SCP_WEB_SEO_DELETE,
  SCP_WEB_SEO_CHANGE,
]

// DMR
export const SCP_WEB_DMR_FULL_ACCESS = 'scp-web-dmr-fa'
export const SCP_WEB_DMR_READ_ONLY = 'scp-web-dmr-ro'
export const SCP_WEB_DMR_DELETE = 'scp-web-dmr-dl'
export const SCP_WEB_DMR_CHANGE = 'scp-web-dmr-ch'
export const SCP_WEB_DMR_ALL = [
  SCP_WEB_DMR_FULL_ACCESS,
  SCP_WEB_DMR_READ_ONLY,
  SCP_WEB_DMR_DELETE,
  SCP_WEB_DMR_CHANGE,
]


// WEB SEO REPORTS
export const SCP_WEB_DMR_FA = 'scp-web-dmr-fa'
export const SCP_WEB_DMR_RO = 'scp-web-dmr-ro'
export const SCP_WEB_DMR_CH = 'scp-web-dmr-ch'
export const SCP_WEB_DMR_DL = 'scp-web-dmr-dl'
export const SCP_WEB_SEO_DMR_ALL = [SCP_WEB_DMR_FA, SCP_WEB_DMR_RO, SCP_WEB_DMR_CH, SCP_WEB_DMR_DL]

// Web Optimise Policies
export const SCP_WEB_OPTIMISE_FULL_ACCESS = 'scp-web-opt-fa'
export const SCP_WEB_OPTIMISE_READ_ONLY = 'scp-web-opt-ro'
export const SCP_WEB_OPTIMISE_DELETE = 'scp-web-opt-dl'
export const SCP_WEB_OPTIMISE_CHANGE = 'scp-web-opt-ch'
export const SCP_WEB_OPTIMISE_ALL = [
  SCP_WEB_OPTIMISE_FULL_ACCESS,
  SCP_WEB_OPTIMISE_READ_ONLY,
  SCP_WEB_OPTIMISE_DELETE,
  SCP_WEB_OPTIMISE_CHANGE,
]

// Web Webinar Policies
export const SCP_WEB_WEBINAR_FULL_ACCESS = 'scp-web-wbn-fa'
export const SCP_WEB_WEBINAR_READ_ONLY = 'scp-web-wbn-ro'
export const SCP_WEB_WEBINAR_DELETE = 'scp-web-wbn-dl'
export const SCP_WEB_WEBINAR_CHANGE = 'scp-web-wbn-ch'
export const SCP_WEB_WEBINAR_ALL = [
  SCP_WEB_WEBINAR_FULL_ACCESS,
  SCP_WEB_WEBINAR_READ_ONLY,
  SCP_WEB_WEBINAR_DELETE,
  SCP_WEB_WEBINAR_CHANGE,
]

// Web Video Policies
export const SCP_WEB_VIDEO_FULL_ACCESS = 'scp-web-vid-fa'
export const SCP_WEB_VIDEO_READ_ONLY = 'scp-web-vid-ro'
export const SCP_WEB_VIDEO_DELETE = 'scp-web-vid-dl'
export const SCP_WEB_VIDEO_CHANGE = 'scp-web-vid-ch'
export const SCP_WEB_VIDEO_ALL = [
  SCP_WEB_VIDEO_FULL_ACCESS,
  SCP_WEB_VIDEO_READ_ONLY,
  SCP_WEB_VIDEO_DELETE,
  SCP_WEB_VIDEO_CHANGE,
]

// Web Templates Policies
export const SCP_WEB_TEMPLATES_FULL_ACCESS = 'scp-web-tem-fa'
export const SCP_WEB_TEMPLATES_READ_ONLY = 'scp-web-tem-ro'
export const SCP_WEB_TEMPLATES_DELETE = 'scp-web-tem-dl'
export const SCP_WEB_TEMPLATES_CHANGE = 'scp-web-tem-ch'
export const SCP_WEB_TEMPLATES_ALL = [
  SCP_WEB_TEMPLATES_FULL_ACCESS,
  SCP_WEB_TEMPLATES_READ_ONLY,
  SCP_WEB_TEMPLATES_DELETE,
  SCP_WEB_TEMPLATES_CHANGE,
]

// Web Search Policies
export const SCP_WEB_SEARCH_FULL_ACCESS = 'scp-web-sch-fa'
export const SCP_WEB_SEARCH_READ_ONLY = 'scp-web-sch-ro'
export const SCP_WEB_SEARCH_DELETE = 'scp-web-sch-dl'
export const SCP_WEB_SEARCH_CHANGE = 'scp-web-sch-ch'
export const SCP_WEB_SEARCH_ALL = [
  SCP_WEB_SEARCH_FULL_ACCESS,
  SCP_WEB_SEARCH_READ_ONLY,
  SCP_WEB_SEARCH_DELETE,
  SCP_WEB_SEARCH_CHANGE,
]

// Events Policies
export const SCP_EVENT_FULL_ACCESS = 'scp-evt-fa'
export const SCP_EVENT_READ_ONLY = 'scp-evt-ro'
export const SCP_EVENT_DELETE = 'scp-evt-dl'
export const SCP_EVENT_CHANGE = 'scp-evt-ch'
export const SCP_EVENT_ALL = [
  SCP_EVENT_FULL_ACCESS,
  SCP_EVENT_READ_ONLY,
  SCP_EVENT_DELETE,
  SCP_EVENT_CHANGE,
]

// Events Registrations Policies
export const SCP_EVENT_REGISTRATIONS_FULL_ACCESS = 'scp-evt-reg-fa'
export const SCP_EVENT_REGISTRATIONS_READ_ONLY = 'scp-evt-reg-ro'
export const SCP_EVENT_REGISTRATIONS_DELETE = 'scp-evt-reg-dl'
export const SCP_EVENT_REGISTRATIONS_CHANGE = 'scp-evt-reg-ch'
export const SCP_EVENT_REGISTRATIONS_ALL = [
  SCP_EVENT_REGISTRATIONS_FULL_ACCESS,
  SCP_EVENT_REGISTRATIONS_READ_ONLY,
  SCP_EVENT_REGISTRATIONS_DELETE,
  SCP_EVENT_REGISTRATIONS_CHANGE,
]

// WeChat Content Policies
export const SCP_WECHAT_CONTENT_FULL_ACCESS = 'scp-wct-con-fa'
export const SCP_WECHAT_CONTENT_READ_ONLY = 'scp-wct-con-ro'
export const SCP_WECHAT_CONTENT_DELETE = 'scp-wct-con-dl'
export const SCP_WECHAT_CONTENT_CHANGE = 'scp-wct-con-ch'
export const SCP_WECHAT_CONTENT_ALL = [
  SCP_WECHAT_CONTENT_FULL_ACCESS,
  SCP_WECHAT_CONTENT_READ_ONLY,
  SCP_WECHAT_CONTENT_DELETE,
  SCP_WECHAT_CONTENT_CHANGE,
]

// WeChat Followers Policies
export const SCP_WECHAT_FOLLOWERS_FULL_ACCESS = 'scp-wct-fol-fa'
export const SCP_WECHAT_FOLLOWERS_READ_ONLY = 'scp-wct-fol-ro'
export const SCP_WECHAT_FOLLOWERS_DELETE = 'scp-wct-fol-dl'
export const SCP_WECHAT_FOLLOWERS_CHANGE = 'scp-wct-fol-ch'
export const SCP_WECHAT_FOLLOWERS_ALL = [
  SCP_WECHAT_FOLLOWERS_FULL_ACCESS,
  SCP_WECHAT_FOLLOWERS_READ_ONLY,
  SCP_WECHAT_FOLLOWERS_DELETE,
  SCP_WECHAT_FOLLOWERS_CHANGE,
]

// WeChat Analytics Policies
export const SCP_WECHAT_ANALYTICS_FULL_ACCESS = 'scp-wct-anl-fa'
export const SCP_WECHAT_ANALYTICS_READ_ONLY = 'scp-wct-anl-ro'
export const SCP_WECHAT_ANALYTICS_DELETE = 'scp-wct-anl-dl'
export const SCP_WECHAT_ANALYTICS_CHANGE = 'scp-wct-anl-ch'
export const SCP_WECHAT_ANALYTICS_ALL = [
  SCP_WECHAT_ANALYTICS_FULL_ACCESS,
  SCP_WECHAT_ANALYTICS_READ_ONLY,
  SCP_WECHAT_ANALYTICS_DELETE,
  SCP_WECHAT_ANALYTICS_CHANGE,
]

// WeChat Messages Policies
export const SCP_WECHAT_MESSAGES_FULL_ACCESS = 'scp-wct-mes-fa'
export const SCP_WECHAT_MESSAGES_READ_ONLY = 'scp-wct-mes-ro'
export const SCP_WECHAT_MESSAGES_DELETE = 'scp-wct-mes-dl'
export const SCP_WECHAT_MESSAGES_CHANGE = 'scp-wct-mes-ch'
export const SCP_WECHAT_MESSAGES_ALL = [
  SCP_WECHAT_MESSAGES_FULL_ACCESS,
  SCP_WECHAT_MESSAGES_READ_ONLY,
  SCP_WECHAT_MESSAGES_DELETE,
  SCP_WECHAT_MESSAGES_CHANGE,
]

// WeChat Settings Policies
export const SCP_WECHAT_SETTINGS_FULL_ACCESS = 'scp-wct-set-fa'
export const SCP_WECHAT_SETTINGS_READ_ONLY = 'scp-wct-set-ro'
export const SCP_WECHAT_SETTINGS_DELETE = 'scp-wct-set-dl'
export const SCP_WECHAT_SETTINGS_CHANGE = 'scp-wct-set-ch'
export const SCP_WECHAT_SETTINGS_ALL = [
  SCP_WECHAT_SETTINGS_FULL_ACCESS,
  SCP_WECHAT_SETTINGS_READ_ONLY,
  SCP_WECHAT_SETTINGS_DELETE,
  SCP_WECHAT_SETTINGS_CHANGE,
]

// WeChat Files Policies
export const SCP_WECHAT_FILES_FULL_ACCESS = 'scp-wct-fil-fa'
export const SCP_WECHAT_FILES_READ_ONLY = 'scp-wct-fil-ro'
export const SCP_WECHAT_FILES_DELETE = 'scp-wct-fil-dl'
export const SCP_WECHAT_FILES_CHANGE = 'scp-wct-fil-ch'
export const SCP_WECHAT_FILES_ALL = [
  SCP_WECHAT_FILES_FULL_ACCESS,
  SCP_WECHAT_FILES_READ_ONLY,
  SCP_WECHAT_FILES_DELETE,
  SCP_WECHAT_FILES_CHANGE,
]

// WeChat To Web Publish Policies
export const SCP_WECHAT_TO_WEB_PUBLISH_FULL_ACCESS = 'scp-wtw-pub-fa'
export const SCP_WECHAT_TO_WEB_PUBLISH_READ_ONLY = 'scp-wtw-pub-ro'
export const SCP_WECHAT_TO_WEB_PUBLISH_DELETE = 'scp-wtw-pub-dl'
export const SCP_WECHAT_TO_WEB_PUBLISH_CHANGE = 'scp-wtw-pub-ch'
export const SCP_WECHAT_TO_WEB_PUBLISH_ALL = [
  SCP_WECHAT_TO_WEB_PUBLISH_FULL_ACCESS,
  SCP_WECHAT_TO_WEB_PUBLISH_READ_ONLY,
  SCP_WECHAT_TO_WEB_PUBLISH_DELETE,
  SCP_WECHAT_TO_WEB_PUBLISH_CHANGE,
]

// Settings Profile Policies
export const SCP_SETTINGS_PROFILE_FULL_ACCESS = 'scp-set-prf-fa'
export const SCP_SETTINGS_PROFILE_READ_ONLY = 'scp-set-prf-ro'
export const SCP_SETTINGS_PROFILE_DELETE = 'scp-set-prf-dl'
export const SCP_SETTINGS_PROFILE_CHANGE = 'scp-set-prf-ch'
export const SCP_SETTINGS_PROFILE_ALL = [
  SCP_SETTINGS_PROFILE_FULL_ACCESS,
  SCP_SETTINGS_PROFILE_READ_ONLY,
  SCP_SETTINGS_PROFILE_DELETE,
  SCP_SETTINGS_PROFILE_CHANGE,
]

// Settings Business Policies
export const SCP_SETTINGS_BUSINESS_FULL_ACCESS = 'scp-set-bui-fa'
export const SCP_SETTINGS_BUSINESS_READ_ONLY = 'scp-set-bui-ro'
export const SCP_SETTINGS_BUSINESS_DELETE = 'scp-set-bui-dl'
export const SCP_SETTINGS_BUSINESS_CHANGE = 'scp-set-bui-ch'
export const SCP_SETTINGS_BUSINESS_ALL = [
  SCP_SETTINGS_BUSINESS_FULL_ACCESS,
  SCP_SETTINGS_BUSINESS_READ_ONLY,
  SCP_SETTINGS_BUSINESS_DELETE,
  SCP_SETTINGS_BUSINESS_CHANGE,
]

// Settings Billings Policies
export const SCP_SETTINGS_BILLING_FULL_ACCESS = 'scp-set-bil-fa'
export const SCP_SETTINGS_BILLING_READ_ONLY = 'scp-set-bil-ro'
export const SCP_SETTINGS_BILLING_DELETE = 'scp-set-bil-dl'
export const SCP_SETTINGS_BILLING_CHANGE = 'scp-set-bil-ch'
export const SCP_SETTINGS_BILLING_ALL = [
  SCP_SETTINGS_BILLING_FULL_ACCESS,
  SCP_SETTINGS_BILLING_READ_ONLY,
  SCP_SETTINGS_BILLING_DELETE,
  SCP_SETTINGS_BILLING_CHANGE,
]

// Settings Users Policies
export const SCP_SETTINGS_USERS_FULL_ACCESS = 'scp-set-usr-fa'
export const SCP_SETTINGS_USERS_READ_ONLY = 'scp-set-usr-ro'
export const SCP_SETTINGS_USERS_DELETE = 'scp-set-usr-dl'
export const SCP_SETTINGS_USERS_CHANGE = 'scp-set-usr-ch'
export const SCP_SETTINGS_USERS_ALL = [
  SCP_SETTINGS_USERS_FULL_ACCESS,
  SCP_SETTINGS_USERS_READ_ONLY,
  SCP_SETTINGS_USERS_DELETE,
  SCP_SETTINGS_USERS_CHANGE,
]

// Settings Privacy Policies
export const SCP_SETTINGS_PRIVACY_FULL_ACCESS = 'scp-set-prv-fa'
export const SCP_SETTINGS_PRIVACY_READ_ONLY = 'scp-set-prv-ro'
export const SCP_SETTINGS_PRIVACY_DELETE = 'scp-set-prv-dl'
export const SCP_SETTINGS_PRIVACY_CHANGE = 'scp-set-prv-ch'
export const SCP_SETTINGS_PRIVACY_ALL = [
  SCP_SETTINGS_PRIVACY_FULL_ACCESS,
  SCP_SETTINGS_PRIVACY_READ_ONLY,
  SCP_SETTINGS_PRIVACY_DELETE,
  SCP_SETTINGS_PRIVACY_CHANGE,
]

// Settings Integrations Policies
export const SCP_SETTINGS_INTEGRATIONS_FULL_ACCESS = 'scp-set-int-fa'
export const SCP_SETTINGS_INTEGRATIONS_READ_ONLY = 'scp-set-int-ro'
export const SCP_SETTINGS_INTEGRATIONS_DELETE = 'scp-set-int-dl'
export const SCP_SETTINGS_INTEGRATIONS_CHANGE = 'scp-set-int-ch'
export const SCP_SETTINGS_INTEGRATIONS_ALL = [
  SCP_SETTINGS_INTEGRATIONS_FULL_ACCESS,
  SCP_SETTINGS_INTEGRATIONS_READ_ONLY,
  SCP_SETTINGS_INTEGRATIONS_DELETE,
  SCP_SETTINGS_INTEGRATIONS_CHANGE,
]

// Sensitive Policies
export const SCP_SENSITIVE_FULL_ACCESS = 'scp-sen-fa'
export const SCP_SENSITIVE_READ_ONLY = 'scp-sen-ro'
export const SCP_SENSITIVE_DELETE = 'scp-sen-dl'
export const SCP_SENSITIVE_CHANGE = 'scp-sen-ch'
export const SCP_SENSITIVE_ALL = [
  SCP_SENSITIVE_FULL_ACCESS,
  SCP_SENSITIVE_READ_ONLY,
  SCP_SENSITIVE_DELETE,
  SCP_SENSITIVE_CHANGE,
]

// Form Submissions Policies
export const SCP_FORM_SUBMISSIONS_FULL_ACCESS = 'scp-for-sub-fa'
export const SCP_FORM_SUBMISSIONS_READ_ONLY = 'scp-for-sub-ro'
export const SCP_FORM_SUBMISSIONS_DELETE = 'scp-for-sub-dl'
export const SCP_FORM_SUBMISSIONS_CHANGE = 'scp-for-sub-ch'
export const SCP_FORM_SUBMISSIONS_ALL = [
  SCP_FORM_SUBMISSIONS_FULL_ACCESS,
  SCP_FORM_SUBMISSIONS_READ_ONLY,
  SCP_FORM_SUBMISSIONS_DELETE,
  SCP_FORM_SUBMISSIONS_CHANGE,
]

// New Navigation Policies
export const SCP_NEW_NAVIGATION_FULL_ACCESS = 'scp-nav-bar-fa'
export const SCP_NEW_NAVIGATION_READ_ONLY = 'scp-nav-bar-ro'
export const SCP_NEW_NAVIGATION_DELETE = 'scp-nav-bar-dl'
export const SCP_NEW_NAVIGATION_CHANGE = 'scp-nav-bar-ch'
export const SCP_NEW_NAVIGATION_ALL = [
  SCP_NEW_NAVIGATION_FULL_ACCESS,
  SCP_NEW_NAVIGATION_READ_ONLY,
  SCP_NEW_NAVIGATION_DELETE,
  SCP_NEW_NAVIGATION_CHANGE,
]

// Bifrost contacts access policies
export const SBF_CONTACTS_ACCESS_FULL_ACCESS = 'sbf-con-acs-fa'
export const SBF_CONTACTS_ACCESS_CHANGE = 'sbf-con-acs-ch'
export const SBF_CONTACTS_ACCESS_READ_ONLY = 'sbf-con-acs-ro'
export const SBF_CONTACTS_ACCESS_DOWNLOAD = 'sbf-con-acs-dl'
export const SBF_CONTACTS_ACCESS_ALL = [
  SBF_CONTACTS_ACCESS_FULL_ACCESS,
  SBF_CONTACTS_ACCESS_CHANGE,
  SBF_CONTACTS_ACCESS_READ_ONLY,
  SBF_CONTACTS_ACCESS_DOWNLOAD,
]
export const SBF_CONTACTS_ACCESS_UPDATE = [
  SBF_CONTACTS_ACCESS_FULL_ACCESS,
  SBF_CONTACTS_ACCESS_CHANGE,
]

// Bifrost conversation access policies
export const SBF_CONVERSATION_ACCESS_FULL_ACCESS = 'sbf-cvs-acs-fa'
export const SBF_CONVERSATION_ACCESS_CHANGE = 'sbf-cvs-acs-ch'
export const SBF_CONVERSATION_ACCESS_READ_ONLY = 'sbf-cvs-acs-ro'
export const SBF_CONVERSATION_ACCESS_DOWNLOAD = 'sbf-cvs-acs-dl'
export const SBF_CONVERSATION_ACCESS_ALL = [
  SBF_CONVERSATION_ACCESS_FULL_ACCESS,
  SBF_CONVERSATION_ACCESS_CHANGE,
  SBF_CONVERSATION_ACCESS_READ_ONLY,
  SBF_CONVERSATION_ACCESS_DOWNLOAD,
]
export const SBF_CONVERSATION_ACCESS_UPDATE = [
  SBF_CONVERSATION_ACCESS_FULL_ACCESS,
  SBF_CONVERSATION_ACCESS_CHANGE,
]

// SCP Engage Access
export const SCP_ENGAGE_ACCESS_FULL_ACCESS = 'scp-eng-acc-fa'
export const SCP_CONVERSATION_ACCESS_ALL = [SCP_ENGAGE_ACCESS_FULL_ACCESS]

// Bifrost form submissions access policies
export const SBF_FORMS_ACCESS_FULL_ACCESS = 'sbf-web-frm-fa'
export const SBF_FORMS_ACCESS_CHANGE = 'sbf-web-frm-ch'
export const SBF_FORMS_ACCESS_READ_ONLY = 'sbf-web-frm-ro'
export const SBF_FORMS_ACCESS_DOWNLOAD = 'sbf-web-frm-dl'
export const SBF_FORMS_ACCESS_ALL = [
  SBF_FORMS_ACCESS_FULL_ACCESS,
  SBF_FORMS_ACCESS_CHANGE,
  SBF_FORMS_ACCESS_READ_ONLY,
  SBF_FORMS_ACCESS_DOWNLOAD,
]

// Bifrost automations access policies
export const SBF_AUTOMATIONS_ACCESS_FULL_ACCESS = 'sbf-aut-fa'
export const SBF_AUTOMATIONS_ACCESS_CHANGE = 'sbf-aut-ch'
export const SBF_AUTOMATIONS_ACCESS_READ_ONLY = 'sbf-aut-ro'
export const SBF_AUTOMATIONS_ACCESS_DOWNLOAD = 'sbf-aut-dl'
export const SBF_AUTOMATIONS_ACCESS_ALL = [
  SBF_AUTOMATIONS_ACCESS_FULL_ACCESS,
  SBF_AUTOMATIONS_ACCESS_CHANGE,
  SBF_AUTOMATIONS_ACCESS_READ_ONLY,
  SBF_AUTOMATIONS_ACCESS_DOWNLOAD,
]
export const SBF_AUTOMATIONS_ACCESS_UPDATE = [
  SBF_AUTOMATIONS_ACCESS_FULL_ACCESS,
  SBF_AUTOMATIONS_ACCESS_CHANGE,
]

// Bifrost Channels access policies
export const SBF_CHANNELS_ACCESS_FULL_ACCESS = 'sbf-cha-fa'
export const SBF_CHANNELS_ACCESS_CHANGE = 'sbf-cha-ch'
export const SBF_CHANNELS_ACCESS_READ_ONLY = 'sbf-cha-ro'
export const SB_CHANNELS_ACCESS_DOWNLOAD = 'sbf-cha-dl'
export const SBF_CHANNELS_ACCESS_ALL = [
  SBF_CHANNELS_ACCESS_FULL_ACCESS,
  SBF_CHANNELS_ACCESS_CHANGE,
  SBF_CHANNELS_ACCESS_READ_ONLY,
  SB_CHANNELS_ACCESS_DOWNLOAD,
]
export const SBF_CHANNELS_ACCESS_UPDATE = [
  SBF_CHANNELS_ACCESS_FULL_ACCESS,
  SBF_CHANNELS_ACCESS_CHANGE,
]

// Bifrost Channels access policies
export const SCP_COOKIE_CONSENT_ACCESS_FULL_ACCESS = 'scp-web-cok-fa'

export const SCP_COOKIE_CONSENT_ACCESS_ALL = [SCP_COOKIE_CONSENT_ACCESS_FULL_ACCESS]
