import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiStatus, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { saveAutomationRule } from '../../../api/automation'
import { CreateRuleRequestBodyTypes } from './automation.types'

const initialState: ApiStatus = {
  status: 'idle',
}

export const saveNewRule = createAsyncThunk(
  'automation/createRule',
  async (data: CreateRuleRequestBodyTypes, { rejectWithValue }) => {
    let response: Response = {}

    response = await saveAutomationRule(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const createRuleSlice = createSlice({
  name: 'automationCreateRules',
  initialState,
  reducers: {
    clearAutomationRuleCreateState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveNewRule.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(saveNewRule.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(saveNewRule.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearAutomationRuleCreateState } = createRuleSlice.actions
export const createRuleApiStatus = (state: RootState): string => state.automationCreateRule.status

export default createRuleSlice.reducer
