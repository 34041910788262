import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchWeChatAuthDetails } from '../../../modules/wechat/api-we-chat'
import { ApiCallStatus } from '../../../types/api-call-status.types'
import { RootState } from '../../store'

interface WeChatAuthResponse {
  success?: boolean
  responseData: {
    preAuthCode: string
    redirectUri: string
    componentAppId: string
  }
}
export interface WeChatAuthState extends ApiCallStatus {
  weChatAuth: WeChatAuthResponse
}

const initialState: WeChatAuthState = {
  weChatAuth: {
    responseData: {
      preAuthCode: '',
      redirectUri: '',
      componentAppId: '',
    },
  },
  status: 'idle',
}

export const requestWeChatAuthDetails = createAsyncThunk(
  'weChatAuth/getDetails',
  async (userId: string, { rejectWithValue }) => {
    const response = await fetchWeChatAuthDetails(userId)

    if (response.data === undefined && response.message !== null) {
      return rejectWithValue(response.message)
    }

    return response.data
  },
)

export const weChatAuthSlice = createSlice({
  name: 'weChatAuth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestWeChatAuthDetails.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(requestWeChatAuthDetails.fulfilled, (state, action) => {
        state.status = 'success'
        state.weChatAuth = action.payload
      })
      .addCase(requestWeChatAuthDetails.rejected, (state) => {
        state.status = 'failed'
        state.weChatAuth = initialState.weChatAuth
      })
  },
})

export const weChatAuthDetails = (state: RootState): WeChatAuthResponse =>
  state.weChatAuth.weChatAuth

export const weChatAuthStatus = (state: RootState): string => state.weChatAuth.status

export default weChatAuthSlice.reducer
