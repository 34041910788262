import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestSegmentsList } from '../../../api/common'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { SegmentsListState, ResponseError, SegmentListObjectType } from '../segments/segments.types'

const initialState: SegmentsListState = {
  status: 'idle',
  segmentList: {
    customSegments: [],
    smartSegments: [],
  },
  error: {},
}

export const getSegmentsList = createAsyncThunk(
  'segmentsList/all',
  async (clientAccountId: string, { rejectWithValue }) => {
    const response: Response = await requestSegmentsList(clientAccountId)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const segmentListSlice = createSlice({
  name: 'segmentList',
  initialState,
  reducers: {
    clearSegmentList: (state) => {
      state = {
        status: 'idle',
        segmentList: {
          customSegments: [],
          smartSegments: [],
        },
        error: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSegmentsList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getSegmentsList.fulfilled, (state, action) => {
        state.status = 'success'
        state.segmentList = action.payload as SegmentListObjectType
      })
      .addCase(getSegmentsList.rejected, (state, action: any) => {
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        state.status = 'failed'
        state.error = errorMessage
        state.segmentList = initialState.segmentList
      })
  },
})
export const { clearSegmentList } = segmentListSlice.actions
export const segmentListApiStatus = (state: RootState): string => state.segmentList.status
export const segmentListResult = (state: RootState): SegmentListObjectType =>
  state.segmentList.segmentList as unknown as SegmentListObjectType
export const segmentListError = (state: RootState): any => state.segmentList.error

export default segmentListSlice.reducer
