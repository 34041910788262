import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestActivityList } from '../../../api/common'
import { ErrorMessageState, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { ActivitiesStateTypes } from './activities.types'

const initialState: ActivitiesStateTypes = {
  activitiesList: { content: [] },
  status: 'idle',
  error: {},
}

interface ActivitiesRequestTye {
  contactId: string
  clientAccountId: string
  page?: number
  size?: number
}

export const getActivityList = createAsyncThunk(
  'activity/all',
  async (data: ActivitiesRequestTye, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestActivityList(data.contactId, data.clientAccountId, data.page)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const activitiesListSlice = createSlice({
  name: 'activitiesList',
  initialState,
  reducers: {
    clearActivityList: (state) => {
      state = { status: 'idle', activitiesList: {}, error: null }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivityList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getActivityList.fulfilled, (state, action) => {
        state.status = 'success'
        state.activitiesList = action.payload as object
      })
      .addCase(getActivityList.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        if (errorFromPayload.contacts !== undefined) {
          errorMessage.errorType = errorFromPayload.contacts
        }

        state.error = errorMessage
        state.status = 'failed'
        state.activitiesList = initialState.activitiesList
      })
  },
})
export const { clearActivityList } = activitiesListSlice.actions
export const activityListApiStatus = (state: RootState): string => state.activitiesList.status
export const activityListResult = (state: RootState): any => state.activitiesList.activitiesList
export const activityListError = (state: RootState): any => state.activitiesList.error

export default activitiesListSlice.reducer
