import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ResponseError } from '../contacts/contacts.types'
import { requestPrimaryChannelList } from '../../../api/common'
import { PrimaryChannelsStateTypes, PrimaryChannelsTypes } from './primaryChannels.types'

const initialState: PrimaryChannelsStateTypes = {
  status: 'idle',
  primaryChannelsList: [],
}

export const getPrimaryChannelsList = createAsyncThunk(
  'primaryChannels/all',
  async (clientAccountId: string, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestPrimaryChannelList(clientAccountId)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const primaryChannelsSlice = createSlice({
  name: 'primaryChannelsList',
  initialState,
  reducers: {
    clearPrimaryChannelsList: (state) => {
      state = { status: 'idle', primaryChannelsList: [] }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrimaryChannelsList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getPrimaryChannelsList.fulfilled, (state, action) => {
        state.status = 'success'
        state.primaryChannelsList = action.payload as PrimaryChannelsTypes[]
      })
      .addCase(getPrimaryChannelsList.rejected, (state, action: any) => {
        state.status = 'failed'
        state.primaryChannelsList = initialState.primaryChannelsList
      })
  },
})

export const { clearPrimaryChannelsList } = primaryChannelsSlice.actions
export const primaryChannelsApiStatus = (state: RootState): string => state.primaryChannels.status
export const primaryChannelsList = (state: RootState): PrimaryChannelsTypes[] | [] =>
  state.primaryChannels.primaryChannelsList as PrimaryChannelsTypes[]

export default primaryChannelsSlice.reducer
