import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'

interface PopupStatusType {
  type: 'new' | 'update'
  isOpen: boolean
  ruleId?: string | null
}
const initialState: PopupStatusType = {
  type: 'new',
  isOpen: false,
  ruleId: null,
}

export const automationPopupSlice = createSlice({
  name: 'automationShowPopup',
  initialState,
  reducers: {
    setShowAutomationPopup: (state, action) => {
      state.type = action.payload.type
      state.ruleId = action.payload.ruleId ?? null
      state.isOpen = true
    },

    clearAutomationPopupStatus: (state) => {
      state.type = 'new'
      state.isOpen = false
      state.ruleId = null
    },
  },
})
export const { clearAutomationPopupStatus, setShowAutomationPopup } = automationPopupSlice.actions
export const automationPopupValues = (state: RootState): PopupStatusType => state.automationPopup

export default automationPopupSlice.reducer
