import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { originalUserId } from '../../redux/slice/user/user.slice'
import {
  getUserAccounts,
  userAccountsResult,
  userAccountsApiStatus,
} from '../../redux/slice/user/user-accounts.slice'
// import { useLocation } from 'react-router-dom'
import DownIcon from '../svg-components/DownIcon'
import { requestAccountSwitch } from '../../api/common'
import { messageEmitter } from '../../utils/toastify-message-emmiter'

interface AccountsSelectorProps {
  style?: object
  className?: string
  borderless?: boolean
  popupClassName?: string
}

const { Option } = Select

const AccountsSelector: React.FC<AccountsSelectorProps> = ({
  style,
  className = '',
  borderless = false,
  popupClassName = '',
}) => {
  const dispatch = useAppDispatch()
  // const location = useLocation()
  const userId = useAppSelector(originalUserId)
  const userAccounts = useAppSelector(userAccountsResult)
  const apiStatus = useAppSelector(userAccountsApiStatus)
  const savedAccount = localStorage.getItem('accountId')
  const [selectedAccount] = useState(savedAccount)
  const [accountChanged, setAccountChanged] = useState(false)

  useEffect(() => {
    fetchUserAccounts()
  }, [])

  useEffect(() => {
    setAccountName()
  }, [userAccounts])

  interface AccountSwitchResponse {
    success: boolean
  }

  const fetchUserAccounts = (): void => {
    void dispatch(getUserAccounts(userId))
  }

  const setAccountName = (): void => {
    const selectedAccountData = userAccounts.filter((account) => account.id === selectedAccount)

    if (userAccounts.length > 0 && selectedAccountData.length === 0) {
      // If result empty with locally saved account id will replace the selected account id with first element of user list
      const defaultAccount = userAccounts[0].id

      handleAccountChange(defaultAccount, null)
    }

    if (selectedAccountData.length > 0) {
      localStorage.setItem('accountName', selectedAccountData[0].accountName)
    }
  }

  const switchAccount = async (accountId: string): Promise<any> => {
    const response = await requestAccountSwitch(accountId, userId)

    return response.data
  }

  const handleAccountChange = (value: string, option: any): void => {
    setAccountChanged(true)
    const response = switchAccount(value)

    response
      .then((result: AccountSwitchResponse) => {
        if (result.success) {
          window.location.href = '/'
        } else {
          setAccountChanged(false)
          messageEmitter('Failed to switch account.', 'error')
        }
      })
      .catch((error) => {
        console.log(error)
        setAccountChanged(false)
        messageEmitter('Failed to switch account.', 'error')
      })
  }

  return (
    <div style={{ position: 'relative' }}>
      <Select
        suffixIcon={<DownIcon />}
        className={`${className} large-select`}
        defaultValue={selectedAccount}
        style={style}
        disabled={apiStatus === 'loading' || accountChanged}
        onChange={handleAccountChange}
        optionLabelProp='label'
        bordered={!borderless}
        popupClassName={`${popupClassName} account-selection-popup`}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {userAccounts.length > 0 &&
          userAccounts.map((account, index) => (
            <Option key={index} value={account.id} label={account.accountName}>
              {account.accountName}
            </Option>
          ))}
      </Select>
      <span className='header-account-id text-ellipsis' title={savedAccount as string}>
        {savedAccount}
      </span>
    </div>
  )
}

export default AccountsSelector
