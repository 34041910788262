import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { LanguageListProps, LanguageStateProps } from './language.types'
import { ResponseError } from '../contacts/contacts.types'
import { requestLanguageList } from '../../../api/common'

const initialState: LanguageStateProps = {
  status: 'idle',
  languageList: [],
}

export const getLanguageList = createAsyncThunk(
  'language/all',
  async (clientAccountId: string, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestLanguageList(clientAccountId)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const languageSlice = createSlice({
  name: 'langueListSlice',
  initialState,
  reducers: {
    clearLanguageList: (state) => {
      state = { status: 'idle', languageList: [] }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLanguageList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getLanguageList.fulfilled, (state, action) => {
        state.status = 'success'
        state.languageList = action.payload as LanguageListProps[]
      })
      .addCase(getLanguageList.rejected, (state, action: any) => {
        state.status = 'failed'
        state.languageList = initialState.languageList
      })
  },
})

export const { clearLanguageList } = languageSlice.actions
export const messageListApiStatus = (state: RootState): string => state.contactList.status
export const languageList = (state: RootState): LanguageListProps[] | [] =>
  state.translateLanguage.languageList as LanguageListProps[]

export default languageSlice.reducer
