import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestContactMessageList } from '../../../api/messages'
import { Response, ResponseError, SortState } from '../../../types/common.types'
import { RootState } from '../../store'
import { ContactsMessageListTypes, ContactsMessagesPropsType } from './message.types'

const initialState: ContactsMessagesPropsType = {
  status: 'idle',
  list: {},
}

export const getMessagesSeparatedByContacts = createAsyncThunk(
  'message/byContacts',
  async (data: SortState, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestContactMessageList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getMessagesSeparatedByContactsListSlice = createSlice({
  name: 'contactsWithMessages',
  initialState,
  reducers: {
    clearContactList: (state) => {
      state = { status: 'idle', list: {} }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessagesSeparatedByContacts.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getMessagesSeparatedByContacts.fulfilled, (state, action) => {
        state.status = 'success'
        state.list = action.payload as object
      })
      .addCase(getMessagesSeparatedByContacts.rejected, (state, action: any) => {
        state.status = 'failed'
        state.list = {}
      })
  },
})
export const { clearContactList } = getMessagesSeparatedByContactsListSlice.actions
export const contactsMessageListApi = (state: RootState): string => state.contactsMessage.status
export const contactsMessageListResult = (state: RootState): ContactsMessageListTypes =>
  state.contactsMessage.list as ContactsMessageListTypes

export default getMessagesSeparatedByContactsListSlice.reducer
