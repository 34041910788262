import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { CriteriaListApiType, fetchAutomationCriteriaList } from '../../../api/automation'
import { AutomationCriteriaList, AutomationCriteriaListObjectProps } from './automation.types'

const initialState: AutomationCriteriaList = {
  status: 'idle',
  criteriaList: [],
}

export const getAutomationCriteriaList = createAsyncThunk(
  'automation/criteriaList',
  async (data: CriteriaListApiType, { rejectWithValue }) => {
    let response: Response = {}

    response = await fetchAutomationCriteriaList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const automationCriteriaListSlice = createSlice({
  name: 'automationCriteria',
  initialState,
  reducers: {
    clearAutomationCriteriaState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAutomationCriteriaList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAutomationCriteriaList.fulfilled, (state, action) => {
        state.status = 'success'
        state.criteriaList = action?.payload as AutomationCriteriaListObjectProps[]
      })
      .addCase(getAutomationCriteriaList.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearAutomationCriteriaState } = automationCriteriaListSlice.actions
export const automationCriteriaApi = (state: RootState): string => state.automationCriteria.status
export const automationCriteriaList = (state: RootState): AutomationCriteriaListObjectProps[] =>
  state.automationCriteria.criteriaList

export default automationCriteriaListSlice.reducer
