import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CustomSortDataMessageList, requestMessageList } from '../../../api/messages'
import { ErrorMessageState, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { SecondaryMessageListStateProps } from './message.types'

const initialState: SecondaryMessageListStateProps = {
  status: 'idle',
  messageList: {},
}

export const getSecondaryMessageList = createAsyncThunk(
  'secondaryMessage/all',
  async (data: CustomSortDataMessageList, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestMessageList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getSecondaryMessageListSlice = createSlice({
  name: 'messageList',
  initialState,
  reducers: {
    clearSecondaryMessageList: (state) => {
      state.status = 'idle'
      state.messageList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSecondaryMessageList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getSecondaryMessageList.fulfilled, (state, action) => {
        state.status = 'success'

        state.messageList = action.payload
      })
      .addCase(getSecondaryMessageList.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        if (errorFromPayload.contacts !== undefined) {
          errorMessage.errorType = errorFromPayload.contacts
        }

        state.status = 'failed'
        state.messageList = []
      })
  },
})
export const { clearSecondaryMessageList } = getSecondaryMessageListSlice.actions
export const secondaryMessageListApi = (state: RootState): string =>
  state.secondaryMessageList.status
export const secondaryMessageListResult = (state: RootState): any =>
  state.secondaryMessageList.messageList

export default getSecondaryMessageListSlice.reducer
