import axios from 'axios'
import { profileApiUrl, messageApiUrl } from '../../api/api-variables'
import { NewContactDataSet } from '../../components/add-new-contact/new-contacts.types'
import { Response, SortState } from '../../types/common.types'
import {
  ContactApiParams,
  ContactListParamsType,
  DeleteContactRequestBody,
  MergeContactsApiRequestTypes,
  ToggleFavoriteRequestBody,
  formSubmissionTranslationApiParams,
  FormSubmissionsApiParams,
  ContactBulkUploadType,
  ContactTemplateDownloadREquestBody,
  ContactTDownloadRequestBody,
} from './contact.types'

export const saveNewContactDetails = async (contactDetails: NewContactDataSet): Promise<any> => {
  const clientIdRemovedContactDetails = { ...contactDetails }

  delete clientIdRemovedContactDetails.clientAccountId

  return await axios
    .post(`${profileApiUrl}/api/v1/${contactDetails.clientAccountId ?? ''}/contacts`, {
      ...clientIdRemovedContactDetails,
    })
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const toggleContactFavorite = async (data: ToggleFavoriteRequestBody): Promise<any> => {
  const requestData = {
    contactId: data.contactId,
    favorite: data.favorite,
  }

  return await axios
    .post(`${profileApiUrl}/api/v1/${data.accountId as string}/segments/toggle/favorite`, {
      ...requestData,
    })
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const sortContactList = async (data: SortState): Promise<any> => {
  const order = data.sortOrder === 'descend' ? 'DESC' : 'ASC'
  const params: ContactListParamsType = {
    clientAccountId: data.clientAccountId,
  }

  if (data.searchKeyword !== null && data.searchKeyword !== '') {
    params.filterKey = data.searchKeyword
  }

  if (data.segmentId !== null && data.segmentId !== '') {
    params.segmentId = data.segmentId
  }

  if (data.ownerId !== null && data.ownerId !== '') {
    params.ownerId = data.ownerId
  }

  if (data.countryId !== null && data.countryId !== '') {
    params.countryId = data.countryId
  }

  if (data.channel !== null && data.channel !== '') {
    params.channel = data.channel
  }

  if (data.sort === true) {
    params.sort = `${data.sortKey as string},${order}`
  }

  if (data.pageHandle === true) {
    params.size = data.pageSize
    params.page = data.current
  } else {
    params.size = 10
  }

  return await axios
    .get(`${profileApiUrl}/api/v1/${data.clientAccountId}/contacts/new`, { params }) // new
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const exportMultipleContacts = async (data: SortState): Promise<any> => {
  const order = data.sortOrder === 'descend' ? 'DESC' : 'ASC'
  const params: ContactListParamsType = {
    clientAccountId: data.clientAccountId,
  }

  if (data.searchKeyword !== null && data.searchKeyword !== '') {
    params.filterKey = data.searchKeyword
  }

  if (data.segmentId !== null && data.segmentId !== '') {
    params.segmentId = data.segmentId
  }

  if (data.channel !== null && data.channel !== '') {
    params.channel = data.channel
  }

  if (data.sort === true) {
    params.sort = `${data.sortKey as string},${order}`
  }

  if (data.pageHandle === true) {
    params.size = data.pageSize
    params.page = data.current
  } else {
    params.size = 10
  }

  return await axios
    .get(`${profileApiUrl}/api/v1/${data.clientAccountId}/contacts/export/multiple_contact`, {
      params,
    })
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const exportContactsTemplate = async (
  data: ContactTemplateDownloadREquestBody,
): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${data.accountId as string}/contacts/bulk-upload/template`, {
      method: 'GET',
      responseType: 'blob', // important
    })
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const getContactListDownloadStatus = async (
  data: ContactTemplateDownloadREquestBody,
): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${data.accountId as string}/contacts/files/last-requested/status`)
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const downloadProcessedContactList = async (
  data: ContactTDownloadRequestBody,
): Promise<any> => {
  return await axios
    .get(
      `${profileApiUrl}/api/v1/${data.accountId as string}/contacts/files/download/${
        data.csvTrackingId
      }`,
    )
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const exportSingleContact = async (
  profileId: number,
  accountId: string | number,
): Promise<any> => {
  return await axios
    .get(
      `${profileApiUrl}/api/v1/${accountId}/contacts/export/single_contact?profileId=${profileId}`,
    )
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const requestDeleteContacts = async ({
  accountId,
  data,
}: DeleteContactRequestBody): Promise<any> =>
  await axios
    .delete(`${profileApiUrl}/api/v1/${accountId as string}/contacts`, { data })
    .then((response: Response) => response)
    .catch((error: Error) => error)

export const updateContactDetails = async (contactDetails: any): Promise<any> => {
  const data = {
    ...contactDetails.data,
  }
  return await axios
    .patch(
      `${profileApiUrl}/api/v1/${contactDetails.clientAccountId as string}/contacts/${
        contactDetails.id as string
      }`,
      { ...data },
    )
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const getContactApiRequest = async ({
  clientAccountId,
  contactId,
}: ContactApiParams): Promise<any> =>
  await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/contacts/${contactId}`)
    .then((response: Response) => response)
    .catch((error: Error) => error)

export const getContactFormSubmissionsApiRequest = async ({
  clientAccountId,
  contactId,
  page,
}: FormSubmissionsApiParams): Promise<any> => {
  const params = { page }
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/forms/contacts/${contactId}/submissions`, {
      params,
    })
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const getFormSubmissionTranslationApiRequest = async ({
  clientAccountId,
  formSubmissionId,
  lang,
}: formSubmissionTranslationApiParams): Promise<any> => {
  const params = { translate: lang }
  return await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/forms/submissions/${formSubmissionId}`, {
      params,
    })
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const requestContactsToMerge = async (data: MergeContactsApiRequestTypes): Promise<any> => {
  const requestData = {
    primaryContactId: data.primaryContactId,
    primaryVersion: data.primaryVersion,
    secondaryContactId: data.secondaryContactId,
    secondaryVersion: data.secondaryVersion,
  }

  return await axios
    .post(`${profileApiUrl}/api/v1/${data.accountId as string}/contacts/merge`, {
      ...requestData,
    })
    .then((response: Response) => response)
    .catch((error: Error) => error)
}

export const requestContactBulkUpload = async (data: ContactBulkUploadType): Promise<any> => {
  return await axios
    .post(
      `${profileApiUrl}/api/v1/${data.accountId}/contacts/bulk-upload/${data.referenceId}/${data.type}`,
    )
    .then((response: Response) => response)
    .catch((error: Error) => error)
}
