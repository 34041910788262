import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NewMessagePostType, requestToSendMessage } from '../../../api/messages'
import { ApiStatus, ErrorMessageState, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'

const initialState: ApiStatus = {
  status: 'idle',
}

export const sendMessage = createAsyncThunk(
  'message/sendMessage',
  async (data: NewMessagePostType, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestToSendMessage(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const sendMessageSlice = createSlice({
  name: 'sendMessage',
  initialState,
  reducers: {
    clearSetSendMessageState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(sendMessage.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        if (errorFromPayload.contacts !== undefined) {
          errorMessage.errorType = errorFromPayload.contacts
        }

        state.status = 'failed'
      })
  },
})

export const { clearSetSendMessageState } = sendMessageSlice.actions
export const sendMessageStatus = (state: RootState): string => state.newMessage.status

export default sendMessageSlice.reducer
