import axios from 'axios'
import { profileApiUrl } from './api-variables'
import {
  AutomationRuleListParams,
  AutomationRuleListParamsTypes,
  AutomationRulesExecutionOrderBodyTypes,
  ChangeAutomationRuleStatusRequestBodyType,
  CreateRuleRequestBodyTypes,
  UpdateRuleRequestBodyTypes,
} from '../redux/slice/automation/automation.types'

export interface CriteriaListApiType {
  clientAccountId: string
  propertyId: string
}

export interface TriggersSourceListApiType {
  clientAccountId: string
  triggerId: string
}

export interface ConditionValueRequestPayloadType {
  clientAccountId: string
  propertyId: string
  criteriaId: string
}

export interface RulesByTriggerPayloadType {
  clientAccountId: string
  triggerId: string
}

export interface RulesByRulePayloadType {
  clientAccountId: string
  ruleId: string
}

export interface FetchPropertyListType {
  clientAccountId: string
  triggerId: string
}

export interface ActionListPropsType {
  clientAccountId: string
  triggerId: string
}

export const fetchAutomationPropertyList = async ({
  clientAccountId,
  triggerId,
}: FetchPropertyListType): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/automation/${triggerId}/property-list`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const fetchAutomationCriteriaList = async ({
  clientAccountId,
  propertyId,
}: CriteriaListApiType): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/automation/property-criteria/${propertyId}`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const fetchAutomationTriggerList = async (clientAccountId: string): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/automation/trigger-list`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const fetchAutomationRuleTriggerList = async (clientAccountId: string): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/automation/rule-trigger-list`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}


export const fetchAutomationTriggerSourceList = async ({
  clientAccountId,
  triggerId,
}: TriggersSourceListApiType): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/automation/trigger-sources/${triggerId}`)
    .then((response: any) => response.data)
    .catch((error: Error) => Error(error.message))
}

export const fetchAutomationActionList = async ({
  clientAccountId,
  triggerId,
}: ActionListPropsType): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/automation/action-list/${triggerId}`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const saveAutomationRule = async ({
  clientAccountId,
  ...rest
}: CreateRuleRequestBodyTypes): Promise<any> => {
  const requestParams = { ...rest }
  return await axios
    .post(`${profileApiUrl}/api/v1/${clientAccountId}/automation/create-rule`, { ...requestParams })
    .then((response: any) => response)
    .catch((error: Error) => error.message)
}

export const fetchConditionValue = async ({
  clientAccountId,
  propertyId,
  criteriaId,
}: ConditionValueRequestPayloadType): Promise<any> => {
  return await axios
    .get(
      `${profileApiUrl}/api/v1/${clientAccountId}/automation/condition-value/${propertyId}/${criteriaId}`,
    )
    .then((response: any) => response.data)
    .catch((error: Error) => Error(error.message))
}

export const fetchRulesByTriggerID = async ({
  clientAccountId,
  triggerId,
}: RulesByTriggerPayloadType): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/automation/trigger-rules/${triggerId}`)
    .then((response: any) => response.data)
    .catch((error: Error) => Error(error.message))
}

export const fetchAutomationRuleList = async (data: AutomationRuleListParams): Promise<any> => {
  const params: AutomationRuleListParamsTypes = {}

  if (data.ruleName !== undefined && data.ruleName !== null && data.ruleName !== '') {
    params.ruleName = data.ruleName
  }

  if (data.sourceId !== undefined && data.sourceId !== null && data.sourceId !== '') {
    params.sourceId = data.sourceId
  }

  if (data.triggerId !== undefined && data.triggerId !== null && data.triggerId !== '') {
    params.triggerId = data.triggerId
  }


  if (data.status !== undefined && data.status !== null) {
    params.status = data.status
  }

  if (data.page !== undefined && data.page !== null) {
    params.page = data.page
  }

  if (data.size !== undefined && data.size !== null) {
    params.size = data.size
  }

  if (data.sort !== undefined && data.sort !== null && data.sort.length > 0) {
    params.sort = data.sort
  }

  params.sort = `priority,ASC`

  return await axios
    .get(`${profileApiUrl}/api/v1/${data.clientAccountId}/automation/rules`, { params })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const fetchRuleDetailsByRuleId = async ({
  clientAccountId,
  ruleId,
}: RulesByRulePayloadType): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/automation/rule/${ruleId}`)
    .then((response: any) => response.data)
    .catch((error: Error) => Error(error.message))
}

export const setAutomationRulesExecutionOrder = async ({
  clientAccountId,
  ruleIds,
}: AutomationRulesExecutionOrderBodyTypes): Promise<any> => {
  return await axios
    .post(`${profileApiUrl}/api/v1/${clientAccountId}/automation/rule-execution-order`, [
      ...ruleIds,
    ])
    .then((response: any) => response)
    .catch((error: Error) => error.message)
}

export const updateAutomationRule = async ({
  clientAccountId,
  ruleId,
  ...rest
}: UpdateRuleRequestBodyTypes): Promise<any> => {
  const requestParams = { ...rest }
  return await axios
    .put(`${profileApiUrl}/api/v1/${clientAccountId}/automation/rule/${ruleId}`, {
      ...requestParams,
    })
    .then((response: any) => response)
    .catch((error: Error) => error.message)
}

export const changeAutomationRuleStatus = async ({
  clientAccountId,
  ...rest
}: ChangeAutomationRuleStatusRequestBodyType): Promise<any> => {
  const requestParams = { ...rest }
  return await axios
    .patch(`${profileApiUrl}/api/v1/${clientAccountId}/automation/rules-status`, {
      ...requestParams,
    })
    .then((response: any) => response)
    .catch((error: Error) => error.message)
}
