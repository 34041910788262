import React from 'react'

interface ImageProps {
  url: string
  className?: string
  width?: string
  height?: string
}

const SvgIconRenderer: React.FC<ImageProps> = ({ url, className, width, height }) => {
  return (
    <span className='custom-svg'>
      {/* <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'> */}
      <img width={width} height={height} src={url} alt="logo" />
      {/* </svg> */}
    </span>
  )
}
export default SvgIconRenderer
