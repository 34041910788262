import React, { useEffect } from 'react'
import { Button, Divider, Dropdown, Space } from 'antd'
import { profileApiUrl } from '../../api/api-variables'
import UserAvatar from '../user-avatar/UserAvatar'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { originalUserId, userId } from '../../redux/slice/user/user.slice'
import { getUserDetails, userDetailsResult } from '../../redux/slice/user/user-details.slice'
import AccountsSelector from './AccountsSelector'
import DownIcon from '../svg-components/DownIcon'
import * as Authorities from '../../utils/authority-constants'
import { hasPolicyAccess } from '../../utils/common-function'

interface RightMenuProps {
  onMouseHover?: () => void
  onMouseExit?: () => void
}

const RightMenu: React.FC<RightMenuProps> = ({ onMouseHover, onMouseExit }) => {
  const dispatch = useAppDispatch()
  const originalUsersId = useAppSelector(originalUserId)
  const accountId = useAppSelector(userId)
  const userDetails = useAppSelector(userDetailsResult)
  const authBaseUrl = process.env.REACT_APP_API_SCP_AUTH ?? ''

  useEffect(() => {
    fetchUserDetails()
  }, [])

  const fetchUserDetails = (): void => {
    void dispatch(getUserDetails(originalUsersId))
  }

  const logout = (): void => {
    // void clearChatbotHistory({ accountId })

    setTimeout(() => {
      // Clear local storage
      localStorage.clear()
      // Navigate to logout link
      window.location.href = `${
        profileApiUrl === '' ? 'http://localhost:8084' : profileApiUrl
      }/logout`
    }, 1000)
  }

  const hoveringMenu = (): void => {
    if (onMouseHover !== undefined && onMouseHover !== null) {
      onMouseHover()
    }
  }

  const notHoveringMenu = (): void => {
    if (onMouseExit !== undefined && onMouseExit !== null) {
      onMouseExit()
    }
  }

  const getFullName = (): string => {
    let fullName = userDetails.firstName

    if (
      userDetails.lastName !== undefined &&
      userDetails.lastName !== null &&
      userDetails.lastName !== ''
    ) {
      fullName += ' ' + userDetails.lastName
    }

    return fullName
  }

  return (
    <>
      <Space size='large' className='font-size-inherit top-menu-right-space'>
        <a className='' href='https://help.sinorbis.com/hc/en-us' target='_blank' rel='noreferrer'>
          <Button className='header-support-btn' type='primary'>
            <span className='mx-1'>Support</span>
          </Button>
        </a>

        <div className='header-user-separator' />

        <div
          className='top-right-user-menu'
          onMouseOver={hoveringMenu}
          onMouseLeave={notHoveringMenu}
        >
          <Dropdown
            overlayClassName='menu-dropdown'
            dropdownRender={(menu) => (
              <div className='custom-menu-wrapper header-user-menu-dropdown'>
                <div className='header-user-details' onClick={(e) => e.preventDefault()}>
                  <Space style={{ cursor: 'pointer' }}>
                    <span className='avtar' style={{ backgroundColor: 'transparent' }}>
                      <UserAvatar
                        name={getFullName()}
                        profileImage={userDetails.profileImageUrl}
                        isLoggedInUser={true}
                      />
                    </span>
                    <span title={getFullName()} className='logged-in-user-name text-ellipsis'>
                      {getFullName()}
                      <div className='header-user-email'>{userDetails.email}</div>
                    </span>
                  </Space>
                </div>

                {hasPolicyAccess(Authorities.SCP_SETTINGS_PROFILE_ALL) && (
                  <div className='header-profile-link'>
                    <a
                      href={`${authBaseUrl}auth/userprofile?selected=profile&accountId=${accountId}`}
                    >
                      Profile & Preferences
                    </a>
                  </div>
                )}

                <div className='header-account-select'>
                  <Space>
                    <AccountsSelector borderless={true} />
                  </Space>
                </div>

                <div className='header-nav-links'>
                  {hasPolicyAccess(Authorities.SCP_SETTINGS_BUSINESS_ALL) && (
                    <div className='global-link'>
                      <a
                        href={`${authBaseUrl}auth/userprofile?selected=business&accountId=${accountId}`}
                      >
                        Business Information
                      </a>
                    </div>
                  )}
                  {hasPolicyAccess(Authorities.SCP_SETTINGS_BILLING_ALL) && (
                    <div className='global-link'>
                      <a
                        href={`${authBaseUrl}auth/userprofile?selected=billing&accountId=${accountId}`}
                      >
                        Billing
                      </a>
                    </div>
                  )}
                  {hasPolicyAccess(Authorities.SCP_SETTINGS_USERS_ALL) && (
                    <div className='global-link'>
                      <a
                        href={`${authBaseUrl}auth/userprofile?selected=users&accountId=${accountId}`}
                      >
                        Users
                      </a>
                    </div>
                  )}
                  {hasPolicyAccess(Authorities.SCP_SETTINGS_PRIVACY_ALL) && (
                    <div className='global-link'>
                      <a
                        href={`${authBaseUrl}auth/userprofile?selected=privacy&accountId=${accountId}`}
                      >
                        Data & Privacy
                      </a>
                    </div>
                  )}
                  <div className='global-link'>
                    <a href={`${authBaseUrl}auth/whatsnew?accountId=${accountId}`}>
                      What<>&#39;</>s New?
                    </a>
                  </div>
                </div>

                <div className='logout-link-area'>
                  <Divider style={{ margin: 0 }} />

                  <div className='header-logout-link'>
                    <a onClick={logout}>Sign out</a>
                  </div>
                </div>
              </div>
            )}
          >
            <div
              onClick={(e) => e.preventDefault()}
              style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
            >
              <span className='user-info-details'>
                <span className='avtar'>
                  <UserAvatar
                    name={getFullName()}
                    profileImage={userDetails.profileImageUrl}
                    isLoggedInUser={true}
                  />
                </span>
                <span className='logged-in-user-name text-ellipsis'>
                  {getFullName()}
                </span>
              </span>
              <DownIcon />
            </div>
          </Dropdown>
        </div>
      </Space>
    </>
  )
}

export default RightMenu
