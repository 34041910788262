import axios from 'axios'
import {
  messageApiUrl,
  //  profileApiUrl
} from './api-variables'

export interface FilterTemplateProps {
  searchKey?: string
  category?: string
  language?: string
  clientAccountId: string
}

interface TemplateSendToProps {
  segments: string[] | []
  contacts: string[] | []
}
export interface SendTemplateMessageProps {
  clientAccountId: string
  messageId?: string
  version: number
  params: {
    channelId: string
    templateId?: string
    to?: TemplateSendToProps
    parameters?: string[]
    scheduledTime?: string | null
    zoneId?: string | null
  }
}

export interface CancelBroadCastMessageTypes {
  messageId: string
  clientAccountId: string
  version: number
}
export const fetchWhatsappTemplateLanguageList = async (clientAccountId: string): Promise<any> => {
  return await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/whatsapp/templates/languages`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const fetchWhatsappTemplateCategories = async (clientAccountId: string): Promise<any> => {
  return await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/whatsapp/templates/categories`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const fetchWhatsappTemplateDynamicFields = async (clientAccountId: string): Promise<any> => {
  return await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/whatsapp/templates/dynamic-field`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const fetchAndFilterTemplates = async ({
  searchKey,
  category,
  language,
  clientAccountId,
}: FilterTemplateProps): Promise<any> => {
  const params = { searchKey, category, language }
  return await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/whatsapp/templates`, { params })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const sendTemplateMessage = async ({
  params,
  clientAccountId,
}: SendTemplateMessageProps): Promise<any> => {
  return await axios
    .post(`${messageApiUrl}/api/v1/${clientAccountId}/broadcast-messages/send`, { ...params })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestToCancelBroadcastMessage = async ({
  messageId,
  clientAccountId,
  version,
}: CancelBroadCastMessageTypes): Promise<any> => {
  return await axios
    .delete(
      `${messageApiUrl}/api/v1/${clientAccountId}/broadcast-messages/${messageId}?version=${version}`,
    )
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const updateMessageTemplate = async ({
  params,
  clientAccountId,
  messageId,
  version,
}: SendTemplateMessageProps): Promise<any> => {
  return await axios
    .put(
      `${messageApiUrl}/api/v1/${clientAccountId}/broadcast-messages/${
        messageId as string
      }?version=${version}`,
      {
        ...params,
      },
    )
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}
