import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'

interface PopupStatusType {
  isOpen: boolean
  ruleId?: string | null
  version: number | null
}
const initialState: PopupStatusType = {
  isOpen: false,
  ruleId: null,
  version: null,
}

export const automationDeletePopupSlice = createSlice({
  name: 'automationDeletePopupShow',
  initialState,
  reducers: {
    setAutomationDeletePopupVisible: (state, action) => {
      state.ruleId = action.payload.ruleId ?? null
      state.version = action.payload.version ?? null
      state.isOpen = true
    },

    clearAutomationDeletePopupStatus: (state) => {
      state.isOpen = false
      state.ruleId = null
      state.version = null
    },
  },
})
export const { setAutomationDeletePopupVisible, clearAutomationDeletePopupStatus } =
  automationDeletePopupSlice.actions
export const automationDeletePopupValues = (state: RootState): PopupStatusType =>
  state.automationDeletePopup

export default automationDeletePopupSlice.reducer
