import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiStatus, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { updateAutomationRule } from '../../../api/automation'
import { UpdateRuleRequestBodyTypes } from './automation.types'

const initialState: ApiStatus = {
  status: 'idle',
}

export const updateRule = createAsyncThunk(
  'automation/updateRule',
  async (data: UpdateRuleRequestBodyTypes, { rejectWithValue }) => {
    let response: Response = {}

    response = await updateAutomationRule(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const createUpdateSlice = createSlice({
  name: 'automationUpdateRules',
  initialState,
  reducers: {
    clearAutomationRuleUpdateState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateRule.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateRule.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(updateRule.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearAutomationRuleUpdateState } = createUpdateSlice.actions
export const updateRuleApiStatus = (state: RootState): string => state.updateSelectedRule.status

export default createUpdateSlice.reducer
