import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestToApplySegment } from '../../../api/common'
import { ApiStatus, ErrorMessageState, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'

const initialState: ApiStatus = {
  status: 'idle',
}

export const applySegment = createAsyncThunk(
  'segment/apply',
  async (contactDetails: any, { rejectWithValue }) => {
    const { data, message, response }: Response = await requestToApplySegment(contactDetails)

    if (data === undefined && message !== null) {
      const errors: ResponseError = {
        errorMessage: response?.data?.errors ?? '',
        apiStatusMessage: response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return data
  },
)

export const applySegmentSlice = createSlice({
  name: 'applySegmentForContact',
  initialState,
  reducers: {
    clearApplySegmentState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applySegment.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(applySegment.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(applySegment.rejected, (state, action: any) => {
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        errorMessage.errorType = action?.payload?.apiStatusMessage

        state.status = 'failed'
      })
  },
})
export const { clearApplySegmentState } = applySegmentSlice.actions
export const applySegmentApiStatus = (state: RootState): string => state.applySegment.status

export default applySegmentSlice.reducer
