import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ToggleFavoriteState, ResponseError } from './message.types'
import { ToggleMessageFavoriteRequestBody } from '../../../components/extended-message-box/extended-message-box.types'
import { toggleMessageFavourite } from '../../../api/messages'

const initialState: ToggleFavoriteState = {
  status: 'idle',
  error: null,
}

export const requestMessageFavoriteToggle = createAsyncThunk(
  'toggleFavorite/contact',
  async (toggleData: ToggleMessageFavoriteRequestBody, { rejectWithValue }) => {
    const { data, message, response }: Response = await toggleMessageFavourite(toggleData)

    if (data === undefined && message !== null) {
      const errors: ResponseError = {
        errorMessage: response?.data?.errors ?? '',
        apiStatusMessage: response?.data?.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return data
  },
)

export const requestMessageFavoriteToggleSlice = createSlice({
  name: 'requestMessageFavoriteToggle',
  initialState,
  reducers: {
    clearMessageFavoriteDetails: (state) => {
      state.status = 'idle'
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestMessageFavoriteToggle.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(requestMessageFavoriteToggle.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(requestMessageFavoriteToggle.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage

        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        // Decode the Error Message returning from backend
        errorMessage.errorType = action?.payload?.apiStatusMessage

        if (errorFromPayload.email !== '') {
          errorMessage.message = errorFromPayload.email
        }
        if (
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== undefined &&
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== ''
        ) {
          errorMessage.message = errorFromPayload['phoneNumbers[0].phoneNumber']
        }

        state.status = 'failed'
        state.error = errorMessage
      })
  },
})
export const { clearMessageFavoriteDetails } = requestMessageFavoriteToggleSlice.actions
export const changeMessageFavoriteApiStatus = (state: RootState): string =>
  state.toggleMessageFavorite.status
export const changeMessageFavoriteError = (state: RootState): any =>
  state.toggleMessageFavorite.error

export default requestMessageFavoriteToggleSlice.reducer
