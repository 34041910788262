import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'

import { CustomAction, NotificationState } from './notification.types'

const initialState: NotificationState = {
  message: '',
  description: '',
  type: 'info',
  customAction: {},
}

interface NotificationAction {
  payload: NotificationState
  type: string
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state: NotificationState, action: NotificationAction) => {
      state.message = action.payload.message
      state.description = action.payload.description

      // Set notification type if it's available in payload
      state.type = action.payload.type ?? initialState.type

      // Set notification Custom Action Buttons if it's available in payload
      state.customAction = action.payload.customAction ?? initialState.customAction
    },
    clearNotification: (state: NotificationState) => {
      state.message = initialState.message
      state.type = initialState.type
      state.description = initialState.description
      state.customAction = initialState.customAction
    },
  },
})

export const { setNotification, clearNotification } = notificationSlice.actions

export const notificationMessage = (state: RootState): string => state.notification.message
export const notificationType = (state: RootState): string => state.notification.type as string
export const notificationDescription = (state: RootState): string => state.notification.description
export const notificationCustomAction = (state: RootState): CustomAction =>
  state.notification.customAction as CustomAction

export default notificationSlice.reducer
