import React, { FC } from 'react'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'
import { pubnubPublishKey, pubnubSubscribeKey } from '../../utils/keys'
import { userId } from '../../redux/slice/user/user.slice'
import { useAppSelector } from '../../redux/hooks'


interface PubNubWrapperProps {
  children: React.ReactNode
}
const PubNubWrapper: FC<PubNubWrapperProps> = ({ children }) => {
  const accountId = useAppSelector(userId)

  const pubnub = new PubNub({
    publishKey: pubnubPublishKey,
    subscribeKey: pubnubSubscribeKey,
    uuid: accountId??"sampleId",
  })

  return <PubNubProvider client={pubnub}>{children}</PubNubProvider>
}

export default PubNubWrapper
