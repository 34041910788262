import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiStatus, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { setAutomationRulesExecutionOrder } from '../../../api/automation'
import { AutomationRulesExecutionOrderBodyTypes } from './automation.types'

const initialState: ApiStatus = {
  status: 'idle',
}

export const setNewRuleOrder = createAsyncThunk(
  'automation/changeRuleExecutionOder',
  async (data: AutomationRulesExecutionOrderBodyTypes, { rejectWithValue }) => {
    let response: Response = {}

    response = await setAutomationRulesExecutionOrder(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const ChangeRuleExecutionOrderSlice = createSlice({
  name: 'automationChangeRuleExecutionOrder',
  initialState,
  reducers: {
    clearRulesExecutionOrderState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setNewRuleOrder.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(setNewRuleOrder.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(setNewRuleOrder.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearRulesExecutionOrderState } = ChangeRuleExecutionOrderSlice.actions
export const changeRuleExecutionOrderApiStatus = (state: RootState): string =>
  state.changeRulesOrder.status

export default ChangeRuleExecutionOrderSlice.reducer
