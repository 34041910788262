import { createSlice } from '@reduxjs/toolkit'
import { NoticeType } from 'antd/es/message/interface'
import { RootState } from '../../store'
import { PopupMessageAction, PopupMessageState } from './pop-message.types'

const initialState: PopupMessageState = {
  message: '',
  type: 'info',
}

export const popMessageSlice = createSlice({
  name: 'popMessage',
  initialState,
  reducers: {
    setPopMessage: (state, action: PopupMessageAction) => {
      state.message = action.payload.message
      state.type = action.payload.type ?? initialState.type
    },
    clearPopMessage: (state) => {
      state.type = 'info'
      state.message = ''
    },
  },
})

export const { setPopMessage, clearPopMessage } = popMessageSlice.actions

export const popMessage = (state: RootState): string => state.popupMessage.message
export const popMessageType = (state: RootState): NoticeType => state.popupMessage.type as NoticeType

export default popMessageSlice.reducer
