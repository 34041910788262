import React from 'react'
import { lazy } from '@loadable/component'

const Wechat = lazy(async () => await import('../modules/wechat/Wechat'))
const Contacts = lazy(async () => await import('../modules/contacts/Contacts'))
const ContactDetailsPage = lazy(
  async () => await import('../modules/contact-details-page/ContactDetailsPage'),
)
const MessageScreen = lazy(
  async () => await import('../modules/default-messages-screen/DefaultMessagesScreen'),
)
const Automations = lazy(async () => await import('../modules/automation-rules/AutomationRules'))

const Channels = lazy(async () => await import('../modules/channels/Channels'))

const ChatBot = lazy(async () => await import('../modules/wrapperModules'))

interface AppRoutesList {
  path: string
  Component: JSX.Element
}

export const appRouteList: AppRoutesList[] = [
  {
    path: 'engage',
    Component: <Contacts />,
  },
  {
    path: 'settings',
    Component: <Wechat />,
  },
  {
    path: 'wechat',
    Component: <Wechat />,
  },
  {
    path: 'contact-details/:id',
    Component: <ContactDetailsPage />,
  },
  {
    path: 'messages',
    Component: <MessageScreen />,
  },

  {
    path: 'testApp',
    Component: <ChatBot />,
  },
  {
    path: 'automations',
    Component: <Automations />,
  },
  {
    path: 'channels',
    Component: <Channels />,
  },
]
