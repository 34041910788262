import axios from 'axios'
import { WeChatAuthData } from '../../redux/slice/weChat-setup/weChat-setup.types'

export const fetchWeChatAuthDetails = async (userId: string): Promise<any> => {
  return await axios
    .get('/auth/wechat/auth/details', {
      params: {
        userId,
      },
    })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestForWeChatAccountSetup = async (authData: WeChatAuthData): Promise<any> => {
  return await axios
    .post('/api/v1/wechat/account', { ...authData })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}
