import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { fetchAutomationRuleList } from '../../../api/automation'
import {
  AutomationRuleList,
  AutomationRuleListParams,
  RuleListPayloadTypes,
} from './automation.types'

const initialState: AutomationRuleList = {
  status: 'idle',
  ruleList: {},
}

export const getAutomationRuleList = createAsyncThunk(
  'automation/ruleList',
  async (data: AutomationRuleListParams, { rejectWithValue }) => {
    let response: Response = {}

    response = await fetchAutomationRuleList(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const automationRuleListSlice = createSlice({
  name: 'automationRules',
  initialState,
  reducers: {
    clearAutomationCriteriaState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAutomationRuleList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAutomationRuleList.fulfilled, (state, action) => {
        state.status = 'success'
        state.ruleList = action?.payload as RuleListPayloadTypes
      })
      .addCase(getAutomationRuleList.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearAutomationCriteriaState } = automationRuleListSlice.actions
export const automationRuleListApi = (state: RootState): string => state.automationRules.status
export const automationRuleListContent = (state: RootState): RuleListPayloadTypes =>
  state.automationRules.ruleList as RuleListPayloadTypes

export default automationRuleListSlice.reducer
