import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { MenuList } from '../../../components/layout/menu-data'
import { ThemeSettingsState, ThemeTokens } from './theme-settings.types'

const initialState: ThemeSettingsState = {
  settings: {
    sidebar: false,
    menu: [...MenuList],
    token: {
      colorPrimary: '#61c6bf',
      colorTextBase: '#464646',
      colorLink: '#61c6bf',
      colorLinkActive: '#7fd2cd',
      colorLinkHover: '#50aca6',
      colorBorderSecondary: '#e3e3e3',
      fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
    },
  },
}

export const themeSettingsSlice = createSlice({
  name: 'themeSettings',
  initialState,
  reducers: {
    setThemeSettings: (state, action) => {
      state.settings = { ...action.payload }
    },
    clearThemeSettings: (state) => {
      state.settings = initialState.settings
    },
  },
})

export const { setThemeSettings, clearThemeSettings } = themeSettingsSlice.actions

export const themeSidebar = (state: RootState): boolean => state.themeSettings.settings.sidebar
export const menuSettings = (state: RootState): object => state.themeSettings.settings.menu
export const themeTokens = (state: RootState): ThemeTokens => state.themeSettings.settings.token

export default themeSettingsSlice.reducer
