import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestChatbotHistory } from '../../../api/messages'
import { ErrorMessageState, Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { ChatbotHistoryStateProps } from '../chatbot/chatbot.types'

const initialState: ChatbotHistoryStateProps = {
  status: 'idle',
  chatbotHistoryResult: {},
}

export const getChatbotHistory = createAsyncThunk(
  'alfred/query',
  async (data: { accountId: string | number }, { rejectWithValue }) => {
    let response: Response = {}

    response = await requestChatbotHistory(data)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const getChatbotHistorySlice = createSlice({
  name: 'getChatbotHistory',
  initialState,
  reducers: {
    clearChatbotHistoryState: (state) => {
      state.status = 'idle'
      state.chatbotHistoryResult = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatbotHistory.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getChatbotHistory.fulfilled, (state, action) => {
        state.status = 'success'
        state.chatbotHistoryResult = action.payload as any
      })
      .addCase(getChatbotHistory.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage
        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        if (errorFromPayload.contacts !== undefined) {
          errorMessage.errorType = errorFromPayload.contacts
        }

        state.status = 'failed'
      })
  },
})

export const { clearChatbotHistoryState } = getChatbotHistorySlice.actions
export const chatbotHistoryStatus = (state: RootState): string => state.chatbotHistoryResult.status
export const chatbotHistoryResult = (state: RootState): any =>
  state.chatbotHistoryResult.chatbotHistoryResult

export default getChatbotHistorySlice.reducer
