import React from 'react'
import ClientOAuth2 from 'client-oauth2'
import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow'
import { auth } from './auth.config'

const oauthClient = new ClientOAuth2(auth)

const RequestCode: React.FC = () => {

  return (
    <RequestAuthorizationCode
      oauthClient={oauthClient}
      render={({ url }: any) => {
        window.location.href = url
      }}
    />
  )
}

export default RequestCode
