import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Response, ResponseError } from '../../../types/common.types'
import { RootState } from '../../store'
import { fetchAutomationTriggerList } from '../../../api/automation'
import { AutomationTriggerList, AutomationTriggerListObjectProps } from './automation.types'

const initialState: AutomationTriggerList = {
  status: 'idle',
  triggerList: [],
}

export const getAutomationTriggerList = createAsyncThunk(
  'automation/triggerList',
  async (clientAccountId: string, { rejectWithValue }) => {
    let response: Response = {}

    response = await fetchAutomationTriggerList(clientAccountId)

    if (response.data === undefined && response.message !== null) {
      const errors: ResponseError = {
        errorMessage: response.response?.data?.errors ?? '',
        apiStatusMessage: response.message ?? '',
      }

      return rejectWithValue(errors)
    }

    return response.data
  },
)

export const automationTriggerListSlice = createSlice({
  name: 'automationTrigger',
  initialState,
  reducers: {
    clearAutomationCriteriaState: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAutomationTriggerList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAutomationTriggerList.fulfilled, (state, action) => {
        state.status = 'success'
        state.triggerList = action?.payload as AutomationTriggerListObjectProps[]
      })
      .addCase(getAutomationTriggerList.rejected, (state, action: any) => {
        state.status = 'failed'
      })
  },
})
export const { clearAutomationCriteriaState } = automationTriggerListSlice.actions
export const automationTriggerApi = (state: RootState): string => state.automationTriggers.status
export const automationTriggerList = (state: RootState): AutomationTriggerListObjectProps[] =>
  state.automationTriggers.triggerList

export default automationTriggerListSlice.reducer
