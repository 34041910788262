import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ToggleFavoriteRequestBody } from '../../../modules/contacts/contact.types'
import { toggleContactFavorite } from '../../../modules/contacts/contacts-api'
import { ErrorMessageState, Response } from '../../../types/common.types'
import { RootState } from '../../store'
import { ToggleFavoriteState, ResponseError } from './contacts.types'

const initialState: ToggleFavoriteState = {
  status: 'idle',
  error: null,
}

export const toggleFavorite = createAsyncThunk(
  'toggleFavorite/contact',
  async (toggleData: ToggleFavoriteRequestBody, { rejectWithValue }) => {
    const { data, message, response }: Response = await toggleContactFavorite(toggleData)

    if (data === undefined && message !== null) {
      const errors: ResponseError = {
        errorMessage: response?.data?.errors ?? '',
        apiStatusMessage: response?.data?.message ?? '',
      }
      return rejectWithValue(errors)
    }

    return data
  },
)

export const toggleFavoriteSlice = createSlice({
  name: 'toggleFavorite',
  initialState,
  reducers: {
    clearContactFavoriteDetails: (state) => {
      state.status = 'idle'
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleFavorite.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(toggleFavorite.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(toggleFavorite.rejected, (state, action: any) => {
        const errorFromPayload = action?.payload?.errorMessage

        const errorMessage: ErrorMessageState = {
          message: '',
          errorType: '',
        }

        // Decode the Error Message returning from backend
        errorMessage.errorType = action?.payload?.apiStatusMessage

        if (errorFromPayload.email !== '') {
          errorMessage.message = errorFromPayload.email
        }
        if (
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== undefined &&
          errorFromPayload['phoneNumbers[0].phoneNumber'] !== ''
        ) {
          errorMessage.message = errorFromPayload['phoneNumbers[0].phoneNumber']
        }

        state.status = 'failed'
        state.error = errorMessage
      })
  },
})
export const { clearContactFavoriteDetails } = toggleFavoriteSlice.actions
export const changeContactFavoriteApiStatus = (state: RootState): string =>
  state.toggleContactFavorite.status
export const changeContactFavoriteError = (state: RootState): any =>
  state.toggleContactFavorite.error

export default toggleFavoriteSlice.reducer
