import { toast } from 'react-toastify'

export const messageEmitter = (message: string, type?: string): void => {
  const options: any = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  }

  if (type === 'error') {
    toast.error(message, options)
  } else if (type === 'warning') {
    toast.warning(message, options)
  } else if (type === 'info') {
    toast.info(message, options)
  } else if (type === 'success') {
    toast.success(message, options)
  } else {
    toast(message, options)
  }
}
