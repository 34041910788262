import React, { ReactElement } from 'react'
import { useAppSelector } from './redux/hooks'
import { ConfigProvider } from 'antd'
import { ToastContainer, Zoom } from 'react-toastify'

import { themeTokens } from './redux/slice/theme-settings/theme-settings.slice'

import Notification from './components/notification/Notification'
import PopupMessage from './components/pop-message/PopupMessage'
import AppRoutes from './routes/AppRoutes'

const App = (): ReactElement => {
  const themeSettings = useAppSelector(themeTokens)

  return (
    <>
      <ConfigProvider
        theme={{
          token: { ...themeSettings },
        }}
      >
        <Notification />
        <PopupMessage />
        <AppRoutes />
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
          transition={Zoom}
          limit={3}
        />
      </ConfigProvider>
    </>
  )
}

export default App
