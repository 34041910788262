import React from 'react'
import { Avatar } from 'antd'

import { generateAvatarFromName } from '../../utils/common-function'

import headshot from '../../assets/images/headshot.jpg'
import { AvatarSize } from 'antd/es/avatar/SizeContext'
import { pickRandomColor } from '../../utils/random-color-generator'
import { originalUserId } from '../../redux/slice/user/user.slice'
import { useAppSelector } from '../../redux/hooks'

interface UserAvatarPropsTypes {
  profileImage?: string | React.ReactNode
  name: string
  size?: AvatarSize
  id?: number
  isLoggedInUser?: boolean
}

const UserAvatar: React.FC<UserAvatarPropsTypes> = ({
  profileImage,
  name,
  size,
  id = null,
  isLoggedInUser = false,
}) => {
  const originalUsersId = useAppSelector(originalUserId)
  const getCurrentUserBgColor = (): string => {
    const userId = originalUsersId as any as string
    const lastChar = userId.substring(userId.length - 1)

    return pickRandomColor(lastChar as any as number)
  }
  const avatarBackgroundColor = isLoggedInUser ? getCurrentUserBgColor() : pickRandomColor(id)

  return (
    <>
      {profileImage !== undefined && profileImage !== null && profileImage !== '' ? (
        <Avatar
          style={{ verticalAlign: 'middle' }}
          size={size ?? 'large'}
          src={profileImage ?? headshot}
          alt='Profile'
        />
      ) : (
        <Avatar
          style={{ backgroundColor: avatarBackgroundColor, verticalAlign: 'middle' }}
          className='uppercase'
          size={size ?? 'large'}
        >
          {generateAvatarFromName(name)}
        </Avatar>
      )}
    </>
  )
}

export default React.memo(UserAvatar)
