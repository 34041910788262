import axios from 'axios'
import {
  DeleteSegmentFromContactType,
  DeleteSegmentsType,
} from '../redux/slice/segments/segments.types'
import { ISinorbisConfigurationPayload, Response, SimpleTemplateProps } from '../types/common.types'
import { messageApiUrl, profileApiUrl, cmsUrl } from './api-variables'

const profileServiceBaseURL = process.env.REACT_APP_API_PROFILE_SERVICE_URL ?? ''

export interface AutoTranslateRequestProps {
  clientAccountId: string
  translationLangId: string
}

export interface EmailRelaySettingsObjectTypes {
  clientId: string
  languageId: string
  channelId: string
}
export interface EmailRelayStetting {
  allApply: boolean
  emailRelaySettings: EmailRelaySettingsObjectTypes[]
}
export interface EmailRelaySettingsPropsType {
  setting: EmailRelayStetting
  clientAccountId: string
}

export interface SavedEmailRelaySettings {
  allApply?: boolean
  emailRelaySettings: EmailRelaySettingsObjectTypes[] | []
}
export interface SingleMessageTranslateTypes {
  clientAccountId: string
  messageId: string
}

interface ProfilesSegmentsParameters {
  clientAccountId: string
  channelId: string
  keyword: string
}

interface ContactProfileListParameters {
  clientAccountId: string
  contactId: string
}

interface ContactProfileSaveParameters {
  clientAccountId: string
  contactId: string
  contactProfileId: string
}

export interface ChannelListByTypeRequestProps {
  clientAccountId: string
  type: 'RULES' | 'CONVERSATION' | 'CONTACT' | 'ALL'
}
export const requestAccountSwitch = async (accountId: string, userId: string): Promise<any> => {
  return await axios
    .get(`${cmsUrl}api/user/switchAccount?accountId=${accountId}&userId=${userId}&bifSwitch=1`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestSourceList = async (clientAccountId: string, filter: boolean): Promise<any> =>
  await axios
    .get(
      `${profileServiceBaseURL}/api/v1/${clientAccountId}/filters/sources?filter=${
        filter as unknown as string
      }`,
    )
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestChannelList = async (clientAccountId: string): Promise<any> =>
  await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/channel`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestContactChannelList = async (clientAccountId: string): Promise<any> =>
  await axios
    .get(`${profileServiceBaseURL}/api/v1/${clientAccountId}/channel/CONTACT`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestChannelListByType = async ({
  clientAccountId,
  type,
}: ChannelListByTypeRequestProps): Promise<any> =>
  await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/channel/${type}`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestCountriesList = async (
  clientAccountId: string,
  filter: boolean,
): Promise<any> =>
  await axios
    .get(
      `${profileServiceBaseURL}/api/v1/${clientAccountId}/filters/countries?filter=${
        filter as unknown as string
      }`,
    )
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestSegmentsList = async (clientAccountId: string): Promise<any> =>
  await axios
    .get(`${profileServiceBaseURL}/api/v1/${clientAccountId}/filters/segments`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestActivityList = async (
  contactId: string,
  clientAccountId: string,
  page?: number,
  size?: number,
): Promise<any> => {
  const params = {
    page: page ?? 0,
    size: size ?? 20,
  }

  return await axios
    .get(`${profileServiceBaseURL}/api/v1/${clientAccountId}/activities/contacts/${contactId}`, {
      params,
    })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const saveSegment = async (data: {
  clientAccountId: string
  name: string
  channels: string[]
  version: number
}): Promise<any> => {
  const segmentData = {
    segmentName: data.name,
    channels: data.channels,
  }
  return await axios
    .post(`${profileServiceBaseURL}/api/v1/${data.clientAccountId}/segments`, {
      ...segmentData,
    })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestDeleteSegments = async ({
  clientAccountId,
  id,
  version,
}: DeleteSegmentsType): Promise<any> =>
  await axios
    .delete(`${profileApiUrl}/api/v1/${clientAccountId}/segments/${id}?version=${version}`)
    .then((response: Response) => response)
    .catch((error: Error) => error)

export const requestToApplySegment = async (data: {
  clientAccountId: string
  segmentName: string
  contactProfileIds: string[]
}): Promise<any> => {
  const segmentData = {
    segmentName: data.segmentName,
    contactProfileIds: data.contactProfileIds,
  }

  return await axios
    .post(`${profileApiUrl}/api/v1/${data.clientAccountId}/segments`, {
      ...segmentData,
    })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestDeleteSegmentsFromContact = async ({
  clientAccountId,
  id,
  contactId,
  version,
}: DeleteSegmentFromContactType): Promise<any> =>
  await axios
    .delete(
      `${profileApiUrl}/api/v1/${clientAccountId}/segments/${id}/contact?contactId=${contactId}`,
    )
    .then((response: Response) => response)
    .catch((error: Error) => error)

export const requestLoggedInUserInfo = async (userId: string): Promise<any> =>
  await axios
    .get(`${profileServiceBaseURL}/api/v1/user/${userId}`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestUserAccounts = async (userId: string): Promise<any> =>
  await axios
    .get(`${profileServiceBaseURL}/api/v1/user/${userId}/accounts`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestLanguageList = async (clientAccountId: string): Promise<any> =>
  await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/languages`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestMetaLanguageList = async (clientAccountId: string): Promise<any> =>
  await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/languages/meta-available`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const saveMessageAutoTranslateLanguage = async ({
  clientAccountId,
  translationLangId,
}: AutoTranslateRequestProps): Promise<any> => {
  const requestBody = {
    translationLangId,
  }
  return await axios
    .post(`${messageApiUrl}/api/v1/${clientAccountId}/translate`, { ...requestBody })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const translateMessageById = async ({
  clientAccountId,
  messageId,
}: SingleMessageTranslateTypes): Promise<any> => {
  const requestBody = {
    messageId,
  }
  return await axios
    .post(`${messageApiUrl}/api/v1/${clientAccountId}/messages/translate`, { ...requestBody })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const getTextTranslateMessageById = async ({
  clientAccountId,
  messageId,
}: SingleMessageTranslateTypes): Promise<any> =>
  await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/translate/message/${messageId}`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const emailRelayConfigurationSetup = async ({
  clientAccountId,
  setting,
}: EmailRelaySettingsPropsType): Promise<any> => {
  return await axios
    .post(`${messageApiUrl}/api/v1/${clientAccountId}/email-relay`, { ...setting })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestEmailRelaySettings = async (clientAccountId: string): Promise<any> => {
  return await axios
    .get(`${messageApiUrl}/api/v1/${clientAccountId}/email-relay`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestClientsAccountsList = async (clientAccountId: string): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/accounts/${clientAccountId}/users`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestPrimaryChannelList = async (clientAccountId: string): Promise<any> =>
  await axios
    .get(`${profileApiUrl}/api/v1/accounts/${clientAccountId}/primary-channels`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))

export const requestContactsAndSegmentsList = async ({
  clientAccountId,
  channelId,
  keyword,
}: ProfilesSegmentsParameters): Promise<any> => {
  const params = { keyword }
  return await axios
    .get(
      `${messageApiUrl}/api/v1/${clientAccountId}/broadcast-messages/channels/${channelId}/profiles-segments`,
      { params },
    )
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const uploadContacts = async (data: {
  clientAccountId: string
  file: any
}): Promise<any> => {
  const formData = new FormData()
  formData.append('file', data.file)
  return await axios
    .post(
      `${profileServiceBaseURL}/api/v1/${data.clientAccountId}/contacts/bulk-upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestContactProfileList = async ({
  clientAccountId,
  contactId,
}: ContactProfileListParameters): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/contacts/${contactId}/profiles`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestToSaveDefaultProfile = async ({
  clientAccountId,
  contactId,
  contactProfileId,
}: ContactProfileSaveParameters): Promise<any> => {
  return await axios
    .post(
      `${profileApiUrl}/api/v1/${clientAccountId}/contacts/${contactId}/default-profile/${contactProfileId}`,
    )
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestToSaveEmbeddedSingUpCode = async ({
  clientAccountId,
  code,
}: {
  clientAccountId: string
  code: string
}): Promise<any> => {
  const data = {
    userCode: code,
  }
  return await axios
    .post(`${profileApiUrl}/api/v1/${clientAccountId}/whatsapp/embedded-signup`, { ...data })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestToInitiateWhatsApp = async ({
  clientAccountId,
  data,
}: {
  clientAccountId: string
  data: ISinorbisConfigurationPayload
}): Promise<any> => {
  return await axios
    .post(`${profileApiUrl}/api/v1/${clientAccountId}/whatsapp/initiate`, { ...data })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestWhatsAppAccountSetupStatus = async ({
  clientAccountId,
}: {
  clientAccountId: string
}): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/whatsapp/account-setup-stats`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestWhatsAppAccountNumberDetails = async ({
  clientAccountId,
}: {
  clientAccountId: string
}): Promise<any> => {
  return await axios
    .get(`${profileApiUrl}/api/v1/${clientAccountId}/whatsapp/account-number-details`)
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}

export const requestToSubmitSimpleTemplate = async ({
  clientAccountId,
  data,
}: {
  clientAccountId: string
  data: SimpleTemplateProps
}): Promise<any> => {
  return await axios
    .post(`${profileApiUrl}/api/v1/${clientAccountId}/whatsapp/simple-template`, { ...data })
    .then((response: any) => response)
    .catch((error: Error) => Error(error.message))
}
