interface AuthVariables {
  clientId: string
  clientSecret: string
  accessTokenUri: string
  authorizationUri: string
  redirectUri: string
  scopes: string[]
}
export const auth: AuthVariables = {
  clientId: process.env.REACT_APP_CLIENT_ID ?? '',
  clientSecret: process.env.REACT_APP_CLIENT_SECRET ?? '',
  accessTokenUri: process.env.REACT_APP_ACCESS_TOKEN_URI ?? '',
  authorizationUri: process.env.REACT_APP_AUTHORIZATION_URI ?? '',
  redirectUri: process.env.REACT_APP_REDIRECT_UI ?? '',
  scopes: (process.env.REACT_APP_SCOPES ?? '').split(' '),
}
