export interface Config {
  pageHeaderTitle?: string
  pageHeader?: boolean
  breadcrumb?: boolean
  back?: boolean
}
interface Settings {
  [key: string]: Config
}
const settings: Settings = {
  engage: {
    pageHeaderTitle: 'Contacts',
    pageHeader: true,
    breadcrumb: false,
    back: false,
  },
  'contact-details': {
    pageHeaderTitle: 'Contacts',
    pageHeader: true,
    breadcrumb: false,
    back: true,
  },
  settings:{
    pageHeader: false,
    breadcrumb: false,
  }
}

export const getPageConfig = (pageKey: string): Config => {
  const pageSettings = settings[pageKey]
  return pageSettings
}
